import { useTranslation } from 'react-i18next';
import { useState, useRef, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import DatePicker from 'react-datepicker';

import { CalendarContainer } from 'react-datepicker';
import {useClickOutside} from '../../../utils/useClickOutside.jsx'

import { ReactComponent as CalendarIcon } from '../assets/calendar.svg';
import { ReactComponent as CloseIcon} from '../assets/close.svg';

import styles from './Calendar.module.scss';

export const Calendar = ({ onChange, initStartDate, initEndDate, overrideStartDate = null, overrideEndDate = null, }) => {
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);
  const [isOpen, setIsOpen] = useState(false);
  const clickRef = useRef();
  const { t } = useTranslation();

  useClickOutside(clickRef, () => {
    if(isOpen && (!startDate && !endDate)) {
      setIsOpen(false)
    } else if (isOpen && startDate && endDate) {
      setIsOpen(false)
    } 
  })

  useEffect(() => {
    setStartDate(overrideStartDate)
  }, [overrideStartDate])

  useEffect(() => {
    setEndDate(overrideEndDate)
  }, [overrideEndDate])

  useEffect(() => {
    if (startDate && endDate) {
      onChange(startDate, endDate)
      setIsOpen(false)
    }
  }, [startDate, endDate])

  const container = ({ children }) => {
    return (
      <div className='react-datepicker custom-datepicker'>
        <CalendarContainer className={styles.container} >
          <div style={{ position: "relative" }}>{children}</div>
          {/* <div className={styles.controls}>
            <button
              className={styles.confirm}
              onClick={() => {
                onChange(startDate, endDate)
                setIsOpen(false)
              }}
            >
              {t("Apply")}
            </button>
            <button
              className={styles.dismiss}
              onClick={() => {
                setStartDate(null);
                setEndDate(null);
                onChange(null, null);
                setIsOpen(false);
              }}>
              {t("Reset")}
            </button>
          </div> */}
        </CalendarContainer>
      </div>
    )
  }

  const handleChange = (e) => {
    setStartDate(e[0]);
    setEndDate(e[1]);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  return (
    <div  ref={clickRef}>
      {startDate ? (
        <button className={`${styles.button} ${styles.date}`} onClick={handleClick}>
          <span>
            {format(startDate, "dd/MM/yyyy")} - {endDate ? format(endDate, "dd/MM/yyyy") : ''}
          </span>
          <CloseIcon 
            onClick={() => {
              setStartDate(null);
              setEndDate(null);
              onChange(null, null);
              setIsOpen(false);
            }} 
          />
        </button>
      ) : (
        <button className={`${styles.button} ${styles.date}`} onClick={handleClick}>
          {t('Select date')}
          <CalendarIcon />
        </button>
      )}
      {isOpen && (
        <DatePicker
          dateFormat="yyyy-MM-dd"
          selectsRange={true}
          selected={startDate}
          onChange={handleChange}
          startDate={startDate}
          endDate={endDate}
          calendarContainer={container}
          showDisabledMonthNavigation
          // onClickOutside={() => setIsOpen(false)}
          
          inline />
      )}
    </div>
  );
};