import cn from 'classnames';
import { useState, useEffect, useCallback } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Modal from '../../components/Modal';
import { Loader } from '../../components/Loader/Loader';
import { API } from '../../API';
import { Paginator } from '../../components/Paginator/Paginator';
import { Search } from '../../components/Search/Search';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ReactComponent as ArrowIcon } from '../NarrativesPage/assets/arrow.svg';
import styles from './TopicReportsPage.module.scss';

const sortingFieldNames = {
  NAME: 'name',
};

export function TopicReportsPage() {
  const { t } = useTranslation();
  const [reports, setReports] = useState(null);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState({
    isActive: false,
    id: null,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ?? 1;
  const searchQuery = searchParams.get('q');
  const [sorting, setSorting] = useState(null);

  const sortingQuery = sorting
    ? `${sorting.isAscending ? '' : '-'}${sorting.fieldName}`
    : null;

  const fetchReports = useCallback(() => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', '100');
    urlParams.set('page', page);

    if (searchQuery) {
      urlParams.set('q', searchQuery);
    }

    if (sortingQuery) {
      urlParams.set('sorting', sortingQuery);
    }

    API.fetch('GET', `/API/v1/topic_reports?${urlParams.toString()}`).then((data) => {
      setReports(data);
    });
  }, [page, searchQuery, sortingQuery]);

  const handleDelete = useCallback(
    (id) => {
      setDeleteConfirmModal(true);
      API.fetch('DELETE', `/API/v1/topic_reports/${id}`).then(() => {
        fetchReports();
      });
    },
    [fetchReports],
  );

  useEffect(fetchReports, [fetchReports]);

  if (!reports) {
    return <Loader />;
  }

  const handleSortingClick = (fieldName) => {
    const isAscending =
      sorting && sorting.fieldName === fieldName ? !sorting.isAscending : true;

    setSorting({
      isAscending,
      fieldName: fieldName,
    });
  };

  return (
    <div className="list-content">
      <div className="page-header">
        <div className="breadcrumb">
          <div className="breadcrumb">
            <span>{t('Topic discovery')}</span>
          </div>
        </div>
        <div className="controls">
          <Link to="/topic_reports/new" className="button new-button">
            <PlusIcon />
            {t('Create report')}
          </Link>
        </div>
      </div>
      <div className="next-card-header">
        <div className='search-wrapper'>
          <Search 
            onChange={value => {
              setSearchParams({q: value, page: 1})
            }}
          />
        </div>
        
      </div>
      <div className="card">
        <div className="card-body">
          <table className="messages">
            <thead>
              <tr>
                <td>#</td>
                <td
                  className={styles.sortable}
                  onClick={() => {
                    handleSortingClick(sortingFieldNames.NAME);
                  }}
                >
                  <div className={styles.wrapper}>
                    <span>{t('Name')}</span>
                    <ArrowIcon
                      className={cn(styles.icon, {
                        [styles.visible]:
                          sorting?.fieldName === sortingFieldNames.NAME,
                        [styles.reversed]: !sorting?.isAscending,
                      })}
                    />
                  </div>
                </td>
                <td />
                <td />
              </tr>
            </thead>
            <tbody>
              {reports.objects.map((report) => (
                <tr key={report.id}>
                  <td>{report.id}</td>
                  <td className="message">
                    <Link to={`/topic_reports/${report.id}`}>{report.name}</Link>
                  </td>
                  <td className="link">
                    <Link to={`/topic_reports/${report.id}/edit`} className="button">
                      <i className="lar la-edit"></i>
                    </Link>
                  </td>
                  <td className="link">
                    <button
                      className="btn-danger"
                      onClick={() =>
                        setDeleteConfirmModal({
                          isActive: true,
                          id: report.id,
                        })
                      }
                    >
                      <i className="las la-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Paginator
        size={reports.size}
        page={reports.page}
        total={reports.total}
      />

      <Modal
        isVisible={deleteConfirmModal.isActive}
        title={t('Delete')}
        content={<p>{t('Are you sure you want to delete a report?')}</p>}
        footer={
          <>
            <button
              className="btn-danger"
              onClick={() => handleDelete(deleteConfirmModal.id)}
            >
              {t('Delete')}
            </button>
            <button
              className="btn-reset"
              onClick={() =>
                setDeleteConfirmModal({ isActive: false, id: null })
              }
            >
              {t('Cancel')}
            </button>
          </>
        }
        onClose={() => setDeleteConfirmModal({ isActive: false, id: null })}
      />
    </div>
  );
}
