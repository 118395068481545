import { useMemo, useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../components/Loader/Loader';
import { MessagesContext } from '../../contexts/MessagesContext';
import styles from '../../NarrativePage.module.scss';
import { CircleChartStories } from '../../../../components/CircleChartStories/CircleChartStories';
import { API, ShareAPI } from '../../../../API';
import { useCurrentUser } from '../../../../contexts/CurrentUser';
import { palette } from '../../../../utils/colors';
import { ampli } from '../../../../ampli';

export const BubbleChartStories = ({ narrative, isShare, mode, id }) => {
  const {
    messages,
    originalSources,
    deduplication,
    narrativeShares,
    narrativeStats,
    mostMentionedEntities,
    getMessages,
    fetchNarrativeStats,
    narrativeStatsStories,
    fetchNarrativeShares,
    fetchMostMentionedEntities,
    fetchOriginalSources,
    dispatch,
    translateMessage,
  } = useContext(MessagesContext);
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();

  useEffect(() => {
    if (!narrativeStatsStories) {
      fetchNarrativeStats(narrative);
    } else {
      return;
    }
  }, []);

  const toggleTranslateChartMessage = (messageId, isTranslation, isStories) => {
    if (isStories === true) {
      dispatch({
        type: 'TOGGLE_TRANSLATE_STORIES_CHART_MESSAGES',
        payload: { messageId: messageId, isTranslation: isTranslation },
      });
    } else {
      dispatch({
        type: 'TOGGLE_TRANSLATE_CHART_MESSAGES',
        payload: { messageId: messageId, isTranslation: isTranslation },
      });
    }
  };

  const handleChartMessageTranslation = (
    messageId,
    text,
    isTranslation,
    isStories,
  ) => {
    let target;
    if (isStories === true) {
      target = narrativeStatsStories?.objects
        ?.map((obj) => obj.stories)
        .flat()
        .find((message) => message.id === messageId);
    } else {
      target = narrativeStats?.objects[0]?.messages?.find(
        (message) => message.id === messageId,
      );
    }

    if (target.translated) {
      toggleTranslateChartMessage(messageId, isTranslation, isStories);
    } else {
      const nativeLanguage = narrative.parameters?.translate_keywords_query
        ? narrative.parameters?.keywords_query_origin_language
        : window.clientInformation.language.split('-', 1)[0];
      toggleTranslateChartMessage(messageId, true, isStories);
      translateMessage(messageId, text, nativeLanguage)
        .then((data) => {
          if (isStories === true) {
            dispatch({
              type: 'TRANSLATE_STORIES_CHART_MESSAGE',
              payload: { data: data, messageId: messageId },
            });
          } else {
            dispatch({
              type: 'TRANSLATE_CHART_MESSAGE',
              payload: { data: data, messageId: messageId },
            });
          }
        })
        .catch((e) => {
          if (isStories === true) {
            dispatch({
              type: 'TRANSLATION_ERROR_STORIES_CHART_MESSAGE',
              payload: {
                data: t('We were not able to translate this text.'),
                messageId: messageId,
              },
            });
          } else {
            dispatch({
              type: 'TRANSLATION_ERROR_CHART_MESSAGE',
              payload: {
                data: t('We were not able to translate this text.'),
                messageId: messageId,
              },
            });
          }
        });

      ampli.track({
        event_type: 'Translate circle chart message',
        event_properties: {
          user_id: currentUser?.id,
          workspace_id: currentUser?.workspace_id,
          narrative_id: narrative.id,
        },
      });
    }
  };

  return (
    <div className="list-content">
      <div className="report-section">
        <h3>{t('Messages distribution: Stories')}</h3>
        <div className="card card-circlechartwrapper">
          <div className="card-header" />
          <div className="card-body">
            {narrativeStatsStories?.objects?.length > 0 ? (
              <CircleChartStories
                isShare={isShare}
                currentUser={currentUser}
                data={narrativeStatsStories}
                narrativeColor={{ [narrative.id]: palette[0 % palette.length] }}
                narrative={narrative}
                onTranslationClick={(messageId, text, bool) =>
                  handleChartMessageTranslation(messageId, text, bool, true)
                }
                onCircleClick={(messageID) => {
                  ampli.track({
                    event_type: 'Open bubble in stories bubble-chart',
                    event_properties: {
                      user_id: currentUser?.id,
                      workspace_id: currentUser?.workspace_id,
                      narrative_id: narrative.id,
                    },
                  });
                }}
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
