import { ReactComponent as HeartRegularIcon } from '../../assets/heart-regular.svg';

import { API } from '../../API';
// import Tooltip from '../../components/Tooltip/Tooltip';
import { useCurrentUser } from "../../contexts/CurrentUser";
import {ReactComponent as ThumbUpIcon} from '../../assets/thumbUp.svg';
import {ReactComponent as ThumbDownIcon} from '../../assets/thumbDown.svg';
import {ReactComponent as ApproveIcon} from '../../pages/NarrativePage/assets/approve.svg';
import { useTranslation } from 'react-i18next';
import styles from './Feedback.module.scss';
import { useState } from 'react';
import { Tooltip } from 'react-tooltip';


export const feedbackContexts = {
  STORIES_MESSAGE: "stories_message",
  AI_POWERED_SEARCH_MESSAGE: "ai_powered_search_message",
  SIMILARITY_MESSAGE: "similarity_message",
}

export const feedbackContextPositiveLabels = {
  [feedbackContexts.STORIES_MESSAGE]: ["MESSAGE_RELEVANT",],
  [feedbackContexts.AI_POWERED_SEARCH_MESSAGE]: ["MESSAGE_RELEVANT",],
  [feedbackContexts.SIMILARITY_MESSAGE]: ["MESSAGE_RELEVANT",],
}

export const feedbackContextNegativeLabels = {
  [feedbackContexts.STORIES_MESSAGE]: ["MESSAGE_IRRELEVANT",],
  [feedbackContexts.AI_POWERED_SEARCH_MESSAGE]: ["MESSAGE_IRRELEVANT",],
  [feedbackContexts.SIMILARITY_MESSAGE]: ["MESSAGE_IRRELEVANT",],
}

export const feedbackContextLabels = Object.fromEntries(
  Object
  .values(feedbackContexts)
  .map(val => [val, (feedbackContextPositiveLabels[val] ?? []).concat(feedbackContextNegativeLabels[val] ?? [])])
)

export const Feedback = ({
  context,
  successCallback = (_) => {},
  errorCallback = (_) => {},
  feedbackParams = {},
}) => {
  const [currentUser] = useCurrentUser();

  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const showFeedbackIcon = featureFlags?.includes('show_feedback');
  const [isDone, setIsDone] = useState(false);
  const { t } = useTranslation();

  if (!showFeedbackIcon) {
    return null;
  }

  const availableLabels = feedbackContextLabels[context];

  // Later it will be modal, popup or something else with user input, for now values are hardcoded
  const feedbackDescription = "";

  const sendFeedback = async (feedbackContextLabel) => {
    try {
      const result = await API
        .fetch('POST', '/API/v1/feedback/track', null, {
          context_type: context,
          feedback: feedbackContextPositiveLabels[context].includes(feedbackContextLabel),
          feedback_label: feedbackContextLabel,
          feedback_description: feedbackDescription,
          ...feedbackParams,
        });
      setIsDone(true)
      return successCallback(result);
    } catch (error) {
      return errorCallback(error);
    }
  };

  return (
    <>
      {!isDone ? <div className={styles.feedbackContainer}>
        <div 
          className={`${styles.feedbackIconWrapper} ${styles.feedbackRelevant}`}  
          data-tooltip-id="message-relevant"
          data-tooltip-content={t("Message relevant")}>
            <ThumbUpIcon onClick={ () => sendFeedback('MESSAGE_RELEVANT') } />
        </div>
        <Tooltip id="message-relevant" place="bottom"/>

        <div 
          className={`${styles.feedbackIconWrapper} ${styles.feedbackIrrelevant}`}  
          data-tooltip-id="message-irrelevant"
          data-tooltip-content={t("Message irrelevant")}>
            <ThumbDownIcon onClick={ () => sendFeedback('MESSAGE_IRRELEVANT') } />
        </div>
        <Tooltip id="message-irrelevant" place="bottom"/>
          
      </div> 
      : <div className={styles.feedbackContainer}>
        <div className={`${styles.feedbackIconWrapper} ${styles.feedbackIsDone}`}>
          <ApproveIcon/>
        </div>
        </div>}
    </>
    
  );
};
