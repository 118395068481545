import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { API, ShareAPI } from '../../../API';
import { useEffect, useState, useContext, useRef } from 'react';
import { MessagesContext } from '../contexts/MessagesContext';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../components/Loader/Loader';
import styles from '../NarrativePage.module.scss';
import settingsStyles from '../Filter/Filter.module.scss';
import { DistributionTabChooser } from './PDFSettings/DistributionTabChooser';
import { ActorsTabChooser } from './PDFSettings/ActorsTabChooser';
import { Collapsible } from '../Filter/Collapsible';
import { Summary } from './Components/Summary';
import { Description } from './Components/Description';
import { OriginalSource } from './Components/OriginalSource';
import { BubbleChartIcons } from './Components/BubbleChartIcons';
import { BubbleChartStories } from './Components/BubbleChartStories';
import { EvolutionChart } from './Components/EvolutionChart';
import { SentimentBar } from './Components/SentimentBar';
import { Stories } from './Components/Stories';
import { Messages } from './Components/Messages';
import { StoriesTabChooser } from './PDFSettings/StoriesTabChooser';
import { MessagesTabChooser } from './PDFSettings/MessagesTabChooser';
import { Platforms } from '../Breakdowns/Platforms';
import { Countries } from '../Breakdowns/Countries';
import { Verification } from '../Breakdowns/Verification';
import { Audience } from '../Breakdowns/Audience';
import { Compromised } from '../Breakdowns/Compromised';
import { Bots } from '../Breakdowns/Bots';
import { StateAffiliated } from '../Breakdowns/StateAffiliated';
import { TopActorsByPosts } from '../Breakdowns/TopActorsByPosts';
import { TopTagsByPosts } from '../Breakdowns/TopTagsByPosts';
import {ReactComponent as OsavulLogo} from '../../../assets/osavulLogo.svg';

export const ExportPDFPage = ({ isShare = false }) => {
  const {
    narrative,
    messages,
    selectedMessages,
    deleteMessages,
    patchMessages,
    deduplication,
    toggleDeduplication,
    clearSelectedMessages,
    toggleTab,
    clearAllSelectedMessages,
    applySearch,
    getMessages,
    dispatch,
    fetchNarrative,
    error,
  } = useContext(MessagesContext);
  const api = isShare ? ShareAPI : API;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { narrativeId, threatId } = useParams();
  const [isMessagesTabOpen, setIsMessagesTabOpen] = useState(true);
  const [isStoriesTabOpen, setIsStoriesTabOpen] = useState(true);
  const [isDistributionTabOpen, setIsDistributionTabOpen] = useState(true);
  const [isActorsTabOpen, setIsActorsTabOpen] = useState(true);
  const [messagesTab, setMessagesTab] = useState(['messages']);
  const [storiesTab, setStoriesTab] = useState(['stories']);
  const [distributionTab, setDistributionTab] = useState([
    'description',
    'summary',
    'originalSource',
    'bubbleChartStories',
    'bubbleChartCountries',
    'bubbleChartPlatforms',
    'evolutionMessages',
    'evolutionViews',
    'evolutionReactions',
    'evolutionManipulations',
    'sentimentMessages',
    'sentimentViews',
    'sentimentReactions',
  ]);

  const [actorsTab, setActorsTab] = useState([
    'platforms',
    'countries',
    'verification',
    'audience',
    'topActors',
    'topTags',
    'stateAffiliated',
    'compromised',
    'bots',
  ]);

  const messagesTabOptions = [{ value: 'messages', label: t('Messages') }];

  const storiesTabOptions = [{ value: 'stories', label: t('Stories') }];

  const generalDistributionTab = [
    'description',
    'summary',
    'originalSource',
    'bubbleChartCountries',
    'bubbleChartPlatforms',
    'evolutionMessages',
    'evolutionViews',
    'evolutionReactions',
    'evolutionManipulations',
    'sentimentMessages',
    'sentimentViews',
    'sentimentReactions',
  ];

  if (narrative?.parameters?.create_stories === true) {
    generalDistributionTab.push('bubbleChartStories');
  }

  const impactAssessmentDistributionTab = [
    'description',
    'summary',
    'bubbleChartStories',
    'sentimentMessages',
    'sentimentViews',
    'sentimentReactions',
  ];

  useEffect(() => {
    if (narrative?.type === 'IMPACT_ASSESSMENT') {
      setDistributionTab(impactAssessmentDistributionTab);
    } else {
      setDistributionTab(generalDistributionTab);
    }
  }, [narrative]);

  useEffect(() => {
    let id = narrativeId || threatId;
    fetchNarrative(id);
  }, [narrativeId, threatId]);

  if (!narrative) {
    return <Loader />;
  }

  let isNarrativeActive = narrative?.is_active === true ? 'active' : 'inactive';

  let caseType = null;

  if (narrative?.type === 'NARRATIVE' && narrative?.is_manual === true) {
    caseType = t('Manual');
  } else if (narrative?.type === 'NARRATIVE') {
    caseType = t('Narrative');
  } else if (narrative?.type === 'INCIDENT') {
    caseType = t('Incident');
  } else if (narrative?.type === 'IMPACT_ASSESSMENT') {
    caseType = t('Impact assessment');
  }

  const mapStatusClasses = {
    active: styles.active,
    inactive: styles.inactive,
  };

  return (
    <div className={styles.exportContainer}>
      <div className={styles.pdfPageContainer}>
        {narrative ? (
          <div className="report-section">
            <div className={styles.exportHeaderCard}>
              <div className={styles.logoContainer}>
                <OsavulLogo />
                <h2 className={styles.logoTitle}>OSAVUL</h2>
              </div>
              <h2 className={styles.headerInfoTitle}>{narrative?.name}</h2>
            </div>
          </div>
        ) : (
          ''
        )}
        {new Set(distributionTab).has('description') ? (
          <Description narrative={narrative} isShare={isShare} />
        ) : (
          ''
        )}
        {new Set(distributionTab).has('summary') ? (
          <Summary narrative={narrative} isShare={isShare} />
        ) : (
          ''
        )}
        {new Set(distributionTab).has('originalSource') ? (
          <OriginalSource narrative={narrative} isShare={isShare} />
        ) : (
          ''
        )}
        
         {new Set(distributionTab).has('bubbleChartStories') ? (
          <BubbleChartStories narrative={narrative} isShare={isShare} />
        ) : (
          ''
        )}

        {new Set(distributionTab).has('bubbleChartCountries') ? (
          <BubbleChartIcons
            narrative={narrative}
            isShare={isShare}
            mode="countries"
            id="contries"
          />
        ) : (
          ''
        )}
        {new Set(distributionTab).has('bubbleChartPlatforms') ? (
          <BubbleChartIcons
            narrative={narrative}
            isShare={isShare}
            mode="platforms"
            id="platforms"
          />
        ) : (
          ''
        )}
       
        {new Set(distributionTab).has('evolutionMessages') ? (
          <EvolutionChart
            narrative={narrative}
            isShare={isShare}
            mode="publications"
          />
        ) : (
          ''
        )}
        {new Set(distributionTab).has('evolutionViews') ? (
          <EvolutionChart
            narrative={narrative}
            isShare={isShare}
            mode="impressions"
          />
        ) : (
          ''
        )}
        {new Set(distributionTab).has('evolutionReactions') ? (
          <EvolutionChart
            narrative={narrative}
            isShare={isShare}
            mode="engagement"
          />
        ) : (
          ''
        )}
        {new Set(distributionTab).has('evolutionManipulations') ? (
          <EvolutionChart
            narrative={narrative}
            isShare={isShare}
            mode="manipulation_index"
          />
        ) : (
          ''
        )}
        {new Set(distributionTab).has('sentimentMessages') ? (
          <SentimentBar
            narrative={narrative}
            isShare={isShare}
            mode="messages"
          />
        ) : (
          ''
        )}
        {new Set(distributionTab).has('sentimentViews') ? (
          <SentimentBar narrative={narrative} isShare={isShare} mode="views" />
        ) : (
          ''
        )}
        {new Set(distributionTab).has('sentimentReactions') ? (
          <SentimentBar narrative={narrative} isShare={isShare} mode="likes" />
        ) : (
          ''
        )}

        <div className={styles.breakdownsContainer}>
          {new Set(actorsTab).has('platforms') ||
          new Set(actorsTab).has('countries') ||
          new Set(actorsTab).has('verification') ||
          new Set(actorsTab).has('audience') ? (
            <h2>{t('Actors breakdowns')}</h2>
          ) : (
            ''
          )}

          <div className={styles.breakdownsRow}>
            {new Set(actorsTab).has('platforms') ? (
              <Platforms narrative={narrative} isShare={isShare} isClickable={false} />
            ) : (
              ''
            )}
            {new Set(actorsTab).has('countries') ? (
              <Countries narrative={narrative} isShare={isShare} isClickable={false} />
            ) : (
              ''
            )}
          </div>
          <div className={styles.breakdownsRow}>
            {new Set(actorsTab).has('verification') ? (
              <Verification narrative={narrative} isShare={isShare} isClickable={false} />
            ) : (
              ''
            )}
          </div>
          <div className={styles.breakdownsRow}>
            {new Set(actorsTab).has('audience') ? (
              <Audience narrative={narrative} isShare={isShare} isClickable={false} />
            ) : (
              ''
            )}
          </div>

          {new Set(actorsTab).has('topActors') ||
          new Set(actorsTab).has('topTags') ? (
            <h2>{t('Actors tops')}</h2>
          ) : (
            ''
          )}

          <div className={styles.breakdownsRow}>
            {new Set(actorsTab).has('topActors') ? (
              <TopActorsByPosts narrative={narrative} isShare={isShare} isClickable={false} />
            ) : (
              ''
            )}
          </div>
          <div className={styles.breakdownsRow}>
            {new Set(actorsTab).has('topTags') ? (
              <TopTagsByPosts narrative={narrative} isShare={isShare} isClickable={false} />
            ) : (
              ''
            )}
          </div>

          {new Set(actorsTab).has('stateAffiliated') ||
          new Set(actorsTab).has('compromised') ||
          new Set(actorsTab).has('bots') ? (
            <h2>{t('Actors specific groups')}</h2>
          ) : (
            ''
          )}

          <div className={styles.breakdownsRow}>
            {new Set(actorsTab).has('stateAffiliated') ? (
              <StateAffiliated narrative={narrative} isShare={isShare} details={false} isClickable={false} />
            ) : (
              ''
            )}
          </div>
          <div className={styles.breakdownsRow}>
            {new Set(actorsTab).has('compromised') ? (
              <Compromised narrative={narrative} isShare={isShare} details={false} isClickable={false} />
            ) : (
              ''
            )}
          </div>
          <div className={styles.breakdownsRow}>
            {new Set(actorsTab).has('bots') ? (
              <Bots narrative={narrative} isShare={isShare} details={false} isClickable={false} />
            ) : (
              ''
            )}
          </div>
        </div>

        {new Set(storiesTab).has('stories') ? (
          <Stories narrative={narrative} isShare={isShare} />
        ) : (
          ''
        )}
        {new Set(messagesTab).has('messages') ? (
          <Messages narrative={narrative} isShare={isShare} />
        ) : (
          ''
        )}
      </div>
      <div className={styles.pdfSettingsContainer}>
        <div className={settingsStyles.root}>
          <div
            className={`${settingsStyles.container} ${settingsStyles.show}`}
            style={{ top: '0px', right: '0px' }}
          >
            <div className={settingsStyles.header}>
              <span>{t('PDF settings')}</span>

              <span
                onClick={() => {
                  if (
                    !isMessagesTabOpen ||
                    !isStoriesTabOpen ||
                    !isDistributionTabOpen ||
                    !isActorsTabOpen
                  ) {
                    setIsMessagesTabOpen(true);
                    setIsStoriesTabOpen(true);
                    setIsDistributionTabOpen(true);
                    setIsActorsTabOpen(true);
                  } else {
                    setIsMessagesTabOpen(false);
                    setIsStoriesTabOpen(false);
                    setIsDistributionTabOpen(false);
                    setIsActorsTabOpen(false);
                  }
                }}
              >
                {isMessagesTabOpen &&
                isStoriesTabOpen &&
                isActorsTabOpen &&
                isDistributionTabOpen
                  ? `${t('Minimize all')}`
                  : `${t('Expand all')}`}
              </span>
            </div>

            <div className={settingsStyles.collapsiblesWrapper}>
              <Collapsible
                name="Overview"
                isOpen={isDistributionTabOpen}
                summ={distributionTab.length}
                onChange={(open) => setIsDistributionTabOpen(open)}
              >
                <DistributionTabChooser
                  narrative={narrative}
                  selected={distributionTab}
                  onChange={(checklist) => setDistributionTab(checklist)}
                />
              </Collapsible>

              <Collapsible
                name="Actors"
                isOpen={isActorsTabOpen}
                summ={actorsTab.length}
                onChange={(open) => setIsActorsTabOpen(open)}
              >
                <ActorsTabChooser
                  selected={actorsTab}
                  onChange={(checklist) => setActorsTab(checklist)}
                />
              </Collapsible>

              <Collapsible
                name="Stories"
                isOpen={isStoriesTabOpen}
                summ={storiesTab.length}
                onChange={(open) => setIsStoriesTabOpen(open)}
              >
                <StoriesTabChooser
                  narrative={narrative}
                  selected={storiesTab}
                  onChange={(checklist) => setStoriesTab(checklist)}
                />
              </Collapsible>

              <Collapsible
                name="Messages"
                isOpen={isMessagesTabOpen}
                summ={messagesTab.length}
                onChange={(open) => setIsMessagesTabOpen(open)}
              >
                <MessagesTabChooser
                  narrative={narrative}
                  selected={messagesTab}
                  onChange={(checklist) => setMessagesTab(checklist)}
                />
              </Collapsible>

            </div>

            <div className={settingsStyles.controls}>
              <button
                className={`${settingsStyles.button} ${settingsStyles.apply}`}
                style={{ fontSize: '14px' }}
                onClick={() => {
                  window.print();
                }}
              >
                {t('Download PDF')}
              </button>

              <button
                className={`${settingsStyles.button} ${settingsStyles.reset}`}
                style={{ fontSize: '14px' }}
                onClick={() => {
                  setDistributionTab(
                    narrative?.type === 'IMPACT_ASSESSMENT'
                      ? impactAssessmentDistributionTab
                      : generalDistributionTab,
                  );
                  setActorsTab([
                    'platforms',
                    'countries',
                    'verification',
                    'audience',
                    'topActors',
                    'topTags',
                    'stateAffiliated',
                    'compromised',
                    'bots',
                  ]);
                  setMessagesTab(['messages']);
                  setStoriesTab(['stories']);
                }}
              >
                {t('Reset all')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
