import * as d3 from 'd3';
import { useNavigate, Link } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MessagesContext } from '../../contexts/MessagesContext';
import { useCurrentUser } from '../../../../contexts/CurrentUser';
import { ampli } from '../../../../ampli';
import { Loader } from '../../../../components/Loader/Loader';
import { LoaderSmall } from '../../../../components/LoaderSmall/LoaderSmall';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { format, parseISO, formatISO } from 'date-fns';
import { ReactComponent as CalendarIcon } from '../../../../assets/calendar.svg';
import { ReactComponent as ViewsIcon } from '../../../../assets/views.svg';
import { ReactComponent as EngagementIcon } from '../../../../assets/engagement.svg';
import { ReactComponent as ManipulationIcon } from '../../../../assets/manipulation.svg';
import { ReactComponent as TranslationIcon } from '../../../../assets/translation.svg';
import { ReactComponent as OriginalTextIcon } from '../../../../assets/originalText.svg';
import { ReactComponent as ExpandIcon } from '../../../../assets/expand.svg';
import { ReactComponent as ActorIcon } from '../../../SearchPage/assets/link.svg';
import { ReactComponent as SourceIcon } from '../../../SearchPage/assets/person.svg';
import { ReactComponent as FollowersIcon } from '../../../../assets/followers.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/link.svg';
import { ReactComponent as SimilarityIcon } from '../../../../assets/similarity.svg';
import { Sentiment } from '../../../../components/Sentiment/Sentiment';
import { ExpandableText } from '../../../../components/CollapsibleText/ExpandableText';
import {
  platformIconsMapping,
  platformNamesMapping,
} from '../../../../utils/platforms';
import {
  contentTypesIconsMapping,
  contentTypesMapping,
} from '../../../../utils/contentTypes';
import styles from '../../../../components/MessagesTable/ExtendedMessageView.module.scss';
// import messageStyles from '../../../../components/MessagesTable/ExtendedMessageView.module.scss';
import { decodeSourceName } from '../../../../utils/decodeURI';
import messageStyles from '../../NarrativePage.module.scss';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');

export const Messages = ({
  narrative,
  searchQuery,
  start_date,
  end_date,
  statuses,
  sorting = 'engagement',
  page = 1,
  size = 10,
  isShare,
}) => {
  const {
    messages,
    originalSources,
    deduplication,
    narrativeShares,
    narrativeStats,
    mostMentionedEntities,
    getMessages,
    getStories,
    fetchNarrativeStats,
    narrativeStatsStories,
    fetchNarrativeShares,
    fetchMostMentionedEntities,
    fetchOriginalSources,
    toggleTranslate,
    dispatch,
    translateMessage,
    error,
  } = useContext(MessagesContext);
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();

  const featureFlags = currentUser?.workspace?.config.featureflags;
  const showNer = featureFlags?.includes('ner_widget');
  const showMessageManipulationIndex = featureFlags?.includes(
    'show_manipulation_index',
  );

  useEffect(() => {
    getMessages(
      narrative,
      searchQuery,
      sorting,
      statuses,
      false,
      page,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      size,
    );
  }, [
    narrative,
    searchQuery,
    start_date,
    end_date,
    statuses,
    sorting,
    page,
    size,
  ]);

  const nerTypesList = [{ value: 'KEYWORDS', label: t('Matched keywords') }];

  if (showNer) {
    nerTypesList.push(
      { value: 'PERSON', label: t('Persons') },
      { value: 'ORGANIZATION', label: t('Organizations') },
      { value: 'LOCATION', label: t('Locations') },
    );
  }

  const [highlights, setHighlights] = useState(['KEYWORDS']);
  const [modalHighlights, setModalHighlights] = useState([]);
  const [highlightsMenu, setHighlightsMenu] = useState(false);

  const statusMapping = {
    NEW: { class: styles.new, name: 'New' },
    READ: { class: styles.approved, name: 'Approved' },
    DELETED: { class: styles.deleted, name: 'Deleted' },
  };

  const handleTranslation = (messageId, text, isTranslation) => {
    const target = messages.objects.find((message) => message.id === messageId);
    if (target.translated) {
      toggleTranslate(messageId, isTranslation);
    } else {
      const nativeLanguage = narrative.parameters?.translate_keywords_query
        ? narrative.parameters.keywords_query_origin_language
        : window.clientInformation.language.split('-', 1)[0];
      toggleTranslate(messageId, true);
      translateMessage(messageId, text, nativeLanguage)
        .then((data) => {
          dispatch({
            type: 'TRANSLATE_MESSAGE',
            payload: { data: data, messageId: messageId },
          });
        })
        .catch((e) => {
          dispatch({
            type: 'TRANSLATION_ERROR',
            payload: {
              data: t('We were not able to translate this text.'),
              messageId: messageId,
            },
          });
        });

      ampli.track({
        event_type: 'Translate message',
        event_properties: {
          user_id: currentUser?.id,
          workspace_id: currentUser?.workspace_id,
          narrative_id: narrative.id,
        },
      });
    }
  };

  if (!narrative) {
    return <Loader />;
  }

  return (
    <>
      <h3>{t('Top messages')}</h3>
      {error ? (
        <div className={styles.noData}>{t(error)}</div>
      ) : !messages ? (
        <Loader />
      ) : messages?.objects?.length > 0 ? (
        <table
          className={`${styles.extendedMessages} ${messageStyles.extendedMessages} ${styles.pdfMode}`}
        >
          <tbody>
            {messages.objects.map((message) => {
              let followers_cnt = message?.source?.audience;

              let textClass = messageStyles.extendedMessage;

              if (message.error && message.isTranslation) {
                textClass = `${messageStyles.extendedMessage} ${styles.error}`;
              } else if (message.error && !message.isTranslation) {
                textClass = messageStyles.extendedMessage;
              }

              const narrartiveMessage = {
                narrative: narrative,
                page: page,
                searchQuery: searchQuery,
                sorting: sorting,
                statuses: statuses,
              };

              return (
                <tr key={message.id}>
                  <td>
                    <div className={styles.messageInfoWrapper}>
                      <div className={styles.messageInfo}>
                        {/* <div className={`${styles.status} ${statusMapping[message.status].class}`}>
                      {t(statusMapping[message.status].name)}
                    </div> */}
                        {message.content_type ? (
                          <div className={styles.contentType}>
                            <Tooltip
                              content={t(
                                contentTypesMapping[message.content_type],
                              )}
                              position="bottom"
                            >
                              {contentTypesIconsMapping[message.content_type]}
                            </Tooltip>
                          </div>
                        ) : (
                          ''
                        )}
                        <div className={styles.date}>
                          <span className={styles.anchor}>
                            <Tooltip
                              content={t('Publication date')}
                              position="bottom"
                            >
                              <CalendarIcon />
                            </Tooltip>
                          </span>
                          {format(
                            parseISO(message.date_publicated + 'Z'),
                            'dd LLL yyyy HH:mm',
                          )}
                        </div>
                        <div className={styles.platform}>
                          <span className={styles.anchor}>
                            <Tooltip
                              content={
                                platformNamesMapping[message.source.source_type]
                              }
                              position="bottom"
                            >
                              {platformIconsMapping[message.source.source_type]}
                            </Tooltip>
                          </span>
                          {followers_cnt ? (
                            <span className={styles.anchor}>
                              <Tooltip
                                content={t('Followers')}
                                position="bottom"
                              >
                                <FollowersIcon />
                              </Tooltip>
                              <span>
                                {formatNumberSignificant(followers_cnt)}
                              </span>
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className={styles.sourceActorWrapper}>
                          <span className={styles.anchor}>
                            <Tooltip
                              content={decodeSourceName(message.source.name)}
                              position="bottom"
                            >
                              <ActorIcon />
                              <span className={styles.cutText}>
                                {isShare ? (
                                  decodeSourceName(message.source.name)
                                ) : message.source ? (
                                  <Link
                                    to={`/sources/${message.source?.id}`}
                                    onClick={() => {
                                      ampli.track({
                                        event_type: 'Open source profile',
                                        event_properties: {
                                          user_id: currentUser?.id,
                                          workspace_id:
                                            currentUser?.workspace_id,
                                          source_id: message.source.id,
                                        },
                                      });
                                    }}
                                  >
                                    {decodeSourceName(message.source.name)}
                                  </Link>
                                ) : (
                                  t('N/A')
                                )}
                              </span>
                            </Tooltip>
                            {message.actor &&
                            message.actor.id !== message.source?.id ? (
                              <Tooltip
                                content={decodeSourceName(message.actor?.name)}
                                position="bottom"
                              >
                                <SourceIcon />
                                <span className={styles.cutText}>
                                  {
                                    <Link to={`/sources/${message.actor?.id}`}>
                                      {decodeSourceName(message.actor?.name)}
                                    </Link>
                                  }
                                </span>
                              </Tooltip>
                            ) : (
                              ''
                            )}
                            {/* <a 
                          href={message.url}
                          className={styles.externalLink}
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => {
                            ampli.track({
                              event_type: 'Open external message link',
                              event_properties: {
                                user_id: currentUser?.id,
                                workspace_id: currentUser?.workspace_id,
                              }
                            });
                          }}
                        >
                          <LinkIcon/>
                        </a> */}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className={styles.messageContainer}>
                      <div className={styles.messageMetricsWrapper}>
                        <div className={styles.messageMetrics}>
                          {message.sentiment_score !== null ? (
                            <Sentiment
                              sentimentScore={message.sentiment_score}
                              messageId={message.id}
                              narrative={narrative}
                              isShare={isShare}
                            />
                          ) : (
                            ''
                          )}
                          <span className={styles.anchor}>
                            <Tooltip content={t('Views')} position="bottom">
                              <ViewsIcon />
                            </Tooltip>
                            {formatNumberSignificant(message.impressions)}
                          </span>
                          <span className={styles.anchor}>
                            <Tooltip content={t('Reactions')} position="bottom">
                              <EngagementIcon />
                            </Tooltip>
                            {formatNumberSignificant(message.engagement)}
                          </span>
                          {showMessageManipulationIndex ? (
                            <span className={styles.anchor}>
                              <Tooltip
                                content={t('Manipulation')}
                                position="bottom"
                              >
                                <ManipulationIcon />
                              </Tooltip>

                              {message.manipulation_index
                                ? message.manipulation_index.toFixed(2)
                                : '0'}
                            </span>
                          ) : (
                            ''
                          )}
                          {message.similarity_score ? (
                            <span className={styles.anchor}>
                              <Tooltip
                                content={t('Similarity1')}
                                position="bottom"
                              >
                                <SimilarityIcon />
                              </Tooltip>
                              {(message.similarity_score + '').slice(0, 4)}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>

                      <div className={styles.textBlock}>
                        {message.isTranslation === true &&
                        !message.translated ? (
                          <div className={styles.loaderContainer}>
                            <LoaderSmall />
                          </div>
                        ) : (
                          <ExpandableText
                            length={100}
                            highlights={highlights}
                            showFullText={true}
                            text={
                              message.isTranslation
                                ? message.translated
                                : message.highlighted_text || message.text
                            }
                            textClassName={textClass}
                            onExpand={(expanded) => {
                              ampli.track({
                                event_type: expanded
                                  ? 'Expand message in-place'
                                  : 'Collapse message in-place',
                                event_properties: {
                                  user_id: currentUser?.id,
                                  workspace_id: currentUser?.workspace_id,
                                  narrative_id: narrative.id,
                                },
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="message-controlls">
                    <div className={styles.translationWrapper}>
                      <span
                        className={message?.isTranslation ? '' : styles.active}
                        onClick={() =>
                          handleTranslation(message.id, message.text, false)
                        }
                      >
                        <OriginalTextIcon />
                      </span>
                      <span
                        className={message?.isTranslation ? styles.active : ''}
                        onClick={() =>
                          handleTranslation(message.id, message.text, true)
                        }
                      >
                        <TranslationIcon />
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className={styles.noData}>{t('There is no data to display')}</div>
      )}
    </>
  );
};
