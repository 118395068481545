import { useTranslation } from 'react-i18next';
import { useState, useContext } from 'react';
import { ReactComponent as CloseIcon } from '../SearchPage/assets/close.svg';
import { API } from '../../API';
import { UserSettingsContext } from './contexts/UserSettingsContext';

import styles from './UserSettingsPage.module.scss';

export const ChangePasswordSettings = () => {
  const {
    user,
    error
  } = useContext(UserSettingsContext);

  const { t } = useTranslation();
  const [password, setPassword] = useState(null);
  const [errors, setErrors] = useState({});

  const handlePasswordSubmit = (e) => {
    e.preventDefault();

    if(!password || password?.length === 0) {
      setErrors(errors => ({...errors, password: t('Please, fill in this field')}))
      return
    }

    let body = {
      password: password
    }

    API.fetch('PATCH', `/API/v1/users/${user.id}`, null, body).then(data => {
      setPassword(null);

    }).catch(error => setErrors(errors => ({...errors, general: error.message})))
  }

  return (
    <>
      <h3>{t('Change password')}</h3>
      <div className="form-wrapper">
        <form method="post">
          <div className="form-element">
            <label className={styles.label} htmlFor="name">
              {t('New password')}
            </label>
            <input
              className=""
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div className="error-message">{errors.password}</div>
          </div>
          <div className="form-element">
            <div className="submit-wrapper">
              <button 
                type="button"
                className="new-button"
                onClick={(e) => handlePasswordSubmit(e)}
              >
                {t('Change password')}
              </button>
            </div>
            {errors.general ? <div className={styles.errorMessage}>{t(errors.general)} <span
              onClick={() => setErrors({...errors, general: null})}> <CloseIcon/> </span></div> : ''}
          </div>
        </form>
      </div>
    </>
  );
};
