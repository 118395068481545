import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/Auth';
import { Code } from '../../components/Code/Code';
import { ReactComponent as CloseIcon } from '../SearchPage/assets/close.svg';

export function LoginPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [otp2faEnabled, setOtp2faEnabled] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState(['']);
  const [code, setCode] = useState(['']);
  const [errors, setErrors] = useState({});
  const auth = useAuth();

  return (
    <div className="center">
      <div className="login-form-wrapper">
        <h1>{t('Login')}</h1>

        <form
          method="post"
          onSubmit={async (event) => {
            event.preventDefault();

            let tokenData;
            try {
              tokenData = await auth.signIn({
                email,
                password,
                one_time_password: code.join(''),
              });
            } catch (error) {
              setErrors((errors) => ({ ...errors, general: error }));
              return;
            }
      
            if (tokenData?.detail === "One time password is required") {
              setOtp2faEnabled(true);
              return;
            } else if (tokenData?.detail) {
              setErrors((errors) => ({ ...errors, general: tokenData.detail }));
              return;
            }

            if (tokenData?.workspace?.config?.is_reporter_only_user) {
              navigate('/threats/new', { replace: true });
            } else {
              // Send them back to the page they tried to visit when they were
              // redirected to the login page. Use { replace: true } so we don't create
              // another entry in the history stack for the login page.  This means that
              // when they get to the protected page and click the back button, they
              // won't end up back on the login page, which is also really nice for the
              // user experience.

              const from = location.state?.from?.pathname ?? '/';
              navigate(from, { replace: true });
            }
          }}
        >
          {otp2faEnabled ? (
            <div className="codeForm">
              <label>{'One time password'}</label>
              <Code onChange={(c) => setCode(c)} />
            </div>
          ) : (
            <>
              <div className="txt_field">
                <input
                  className="input_filed"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="off"
                  placeholder=" "
                  required
                />
                <span></span>
                <label>{t('Email')}</label>
              </div>
              <div className="txt_field">
                <input
                  className="input_filed"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="off"
                  placeholder=" "
                  required
                />
                <span></span>
                <label>{'Password'}</label>
              </div>
            </>
          )}
          <input type="submit" value={t('Login')} />

          {errors.general ? (
            <div className='errorMessage'>
              {t(errors.general)}
              <span onClick={() => setErrors({ ...errors, general: null })}>
                {' '}
                <CloseIcon />{' '}
              </span>
            </div>
          ) : (
            ''
          )}
        </form>
      </div>
      
    </div>
  );
}
