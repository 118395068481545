import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { format, parseISO } from 'date-fns';
import { ExpandableText } from '../../../components/CollapsibleText/ExpandableText';
import { ReactComponent as ArrowIcon } from '../../NarrativesPage/assets/arrow.svg';
import { Loader } from '../../../components/Loader/Loader';

import styles from './ThreatsModal.module.scss';

const sortingFieldNames = {
  DATE: 'date_publicated',
  SOURCE: 'source',
  MESSAGE: 'text',
  VIEWS: 'impressions',
  REACTIONS: 'engagement',
  MANIPULATION_INDEX: 'manipulation_index',

  AGE: 'user_age',
  LOCATION: 'user_location',
  GROUPS: 'user_groups',
};

export const MessagesTable = ({
  messages,
  sorting,
  handleMessagesSortingClick,
}) => {
  const { t } = useTranslation();

  if (!messages) {
    return <Loader />;
  }

  return (
    <>
      {messages?.objects.length > 0 ? (
        <div className="list-content">
          <div>
            <div className="card-body">
              <table className={styles.messages}>
                <thead>
                  <tr>
                    <td
                      className={styles.sortable}
                      onClick={() => {
                        handleMessagesSortingClick(sortingFieldNames.DATE);
                      }}
                    >
                      <div className={styles.wrapper}>
                        <span>{t('Date')}</span>
                        <ArrowIcon
                          className={cn(styles.icon, {
                            [styles.visible]:
                              sorting?.fieldName === sortingFieldNames.DATE,
                            [styles.reversed]: !sorting?.isAscending,
                          })}
                        />
                      </div>
                    </td>
                    <td>
                      <div className={styles.wrapper}>
                        <span>{t('Actor')}</span>
                      </div>
                    </td>
                    <td>
                      <div className={styles.wrapper}>
                        <span>{t('Title')}</span>
                      </div>
                    </td>
                    <td
                      className={styles.sortable}
                      onClick={() => {
                        handleMessagesSortingClick(sortingFieldNames.VIEWS);
                      }}
                    >
                      <div className={styles.wrapper}>
                        <span>{t('Views')}</span>
                        <ArrowIcon
                          className={cn(styles.icon, {
                            [styles.visible]:
                              sorting?.fieldName === sortingFieldNames.VIEWS,
                            [styles.reversed]: !sorting?.isAscending,
                          })}
                        />
                      </div>
                    </td>
                    <td
                      className={styles.sortable}
                      onClick={() => {
                        handleMessagesSortingClick(sortingFieldNames.REACTIONS);
                      }}
                    >
                      <div className={styles.wrapper}>
                        <span>{t('Engagement')}</span>
                        <ArrowIcon
                          className={cn(styles.icon, {
                            [styles.visible]:
                              sorting?.fieldName ===
                              sortingFieldNames.REACTIONS,
                            [styles.reversed]: !sorting?.isAscending,
                          })}
                        />
                      </div>
                    </td>
                    <td
                      className={styles.sortable}
                      onClick={() => {
                        handleMessagesSortingClick(
                          sortingFieldNames.MANIPULATION_INDEX,
                        );
                      }}
                    >
                      <div className={styles.wrapper}>
                        <span>{t('Manipulation')}</span>
                        <ArrowIcon
                          className={cn(styles.icon, {
                            [styles.visible]:
                              sorting?.fieldName ===
                              sortingFieldNames.MANIPULATION_INDEX,
                            [styles.reversed]: !sorting?.isAscending,
                          })}
                        />
                      </div>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {messages?.objects?.map((message) => {
                    return (
                      <tr key={message.id}>
                        <td className="text-nowrap">
                          {format(
                            parseISO(message.date_publicated),
                            'dd LLL yyyy HH:mm',
                          )}
                        </td>
                        <td>
                         {message.source ? <Link to={`/sources/${message.source.id}`}>{message.source?.name}</Link> : t('N/A')}
                        </td>
                        <td className="message">
                          <ExpandableText length={50} text={message?.text} />
                        </td>
                        <td>{message.impressions}</td>
                        {/* engagement */}
                        {/* <td>{message.forwards + message.reactions + message.replies}</td> */}
                        <td>{message?.engagement}</td>
                        <td>{message?.manipulation_index?.toFixed(2)}</td>
                        <td className="link">
                          <a
                            href={message?.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="las la-external-link-alt"></i>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.noData}>{t('There is no data to display')}</div>
      )}
    </>
  );
};
