import { useEffect, useState } from 'react';
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';

import { useCurrentUser } from '../../contexts/CurrentUser';
import { API } from '../../API';
import { useAuth } from '../../contexts/Auth';
import { Loader } from '../../components/Loader/Loader';
import { ReactComponent as ReportsIcon } from './assets/report.svg';
import { ReactComponent as NarrativesIcon } from './assets/narratives.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import { ReactComponent as SourcesIcon } from './assets/sources.svg';
import { ReactComponent as LogoutIcon } from './assets/logout.svg';
import { ReactComponent as GroupsIcon } from './assets/database.svg';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { ReactComponent as UserStatsIcon } from '../../assets/userStats.svg';
import { ReactComponent as WorkspacesIcon } from '../../assets/workspaces.svg';
import { ReactComponent as UserIcon } from './assets/user.svg';
import { ReactComponent as DashboardsIcon} from '../../assets/dashboards.svg';
import { ReactComponent as ExportIcon} from '../../assets/export.svg';

import websocketConnection from '../../websocket';
import { ThreatsLink } from '../ThreatsPage/ThreatsLink';
import { NarrativesModerationLink } from '../NarrativesModerationPage/NarrativesModerationLink';
import Tooltip from '../../components/Tooltip/Tooltip';
import menuIcon from './assets/menu.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import styles from './Layout.module.scss';

export function Layout() {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useCurrentUser();
  const [websiteConfig, setWebsiteConfig] = useState(null);
  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const [maintenanceMessage, setMaintenanceMessage] = useState(null);
  const [isNavToogleChecked, setisNavToogleChecked] = useState(true);
  const auth = useAuth();
  const navigate = useNavigate();

  const logoutHandler = async () => {
    await auth.signOut();
    setCurrentUser({});
    navigate('/');
  };

  const sudoGetOutHandle = () => {
    localStorage.removeItem('workspace_id');
    localStorage.removeItem('workspace_name');
    navigate('/workspaces');
  };

  const getInitials = () => {
    const firstLatter = currentUser.first_name ? currentUser.first_name[0] : '';
    const lastLatter = currentUser.last_name ? currentUser.last_name[0] : '';

    return `${firstLatter}${lastLatter}`;
  };

  useEffect(() => {
    API.fetch('GET', '/API/v1/users/me').then((data) => {
      setCurrentUser(data);
        if(data.language === null) {
          return
        }

        changeLanguage(data.language.toLowerCase())
    });

    // should be fetched only on init
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem('i18nextLng') === 'ua-UK' ||
      localStorage.getItem('i18nextLng') === 'ru-RU' ||
      localStorage.getItem('i18nextLng') === 'ru' ||
      localStorage.getItem('i18nextLng') === 'uk'
    ) {
      setMaintenanceMessage(websiteConfig?.maintenance_message_uk);
    } else {
      setMaintenanceMessage(websiteConfig?.maintenance_message_en);
    }
  }, [websiteConfig]);

  useEffect(() => {
    API.fetch('GET', '/API/v1/config/website_config').then((websiteConfig) => {
      setWebsiteConfig(websiteConfig);
    });
  }, []);

  useEffect(() => {
    const notifyExportProgress = (notification) => {
      if (notification?.object_type !== 'ASYNC_EXPORT') {
        return;
      }

      let data = notification?.data

      const Msg = () => (
        <div>
          {t('An export of') + ` ${t('export_type_of_' + data.type)} id=${data.object_id} `}
          <Link to={`/exports?object_id=${data.object_id}&types=${data.type}`}>
            {t(`export_status_${data.status}`).toLowerCase()}
          </Link>
        </div>
      );

      const displayMsg = () => {
        toast.success(<Msg />);
      };
      displayMsg();
    };

    websocketConnection.addHandler('ASYNC_EXPORT', notifyExportProgress);
  }, []);

  const workspaceName = localStorage.getItem('workspace_name');
  const workspaceRoles = currentUser?.workspace?.config?.roles;

  if (!currentUser) {
    return <Loader />;
  }
  let menuItems = [
    {
      show: workspaceRoles?.includes('menu:search'),
      content: (
        <li key={'menu:search'}>
          <NavLink reloadDocument to="/search">
            <Tooltip content={t('Search')} position="right">
              <SearchIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Search')}</span>
          </NavLink>
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:narratives'),
      content: (
        <li key={'menu:narratives'}>
          <NavLink reloadDocument to="/narratives">
            <Tooltip content={t('Cases')} position="right">
              <NarrativesIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Cases')}</span>
          </NavLink>
        </li>
      ),
    },
    {
      show:
        workspaceRoles?.includes('menu:narratives') &&
        currentUser.type === 'MODERATOR',
      content: (
        <li key={'menu:narratives_moderation'}>
          <NarrativesModerationLink
            itemTextClassname={styles.itemText}
            threatCounterClassname={styles.threatCounter}
          />
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:reports'),
      content: (
        <li key={'menu:reports'}>
          <NavLink reloadDocument to="/reports">
            <Tooltip content={t('Reports')} position="right">
              <ReportsIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Reports')}</span>
          </NavLink>
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:topic_reports'),
      content: (
        <li key={'menu:topic_reports'}>
          <NavLink reloadDocument to="/topic_reports">
            <Tooltip content={t('Topic discovery')} position="right">
              <ReportsIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Topic discovery')}</span>
          </NavLink>
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:sources'),
      content: (
        <li key={'menu:sources'}>
          <NavLink reloadDocument to="/sources">
            <Tooltip content={t('Actors')} position="right">
              <SourcesIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Actors')}</span>
          </NavLink>
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:source_groups'),
      content: (
        <li key={'menu:source_groups'}>
          <NavLink reloadDocument to="/groups">
            <Tooltip content={t('Actor groups')} position="right">
              <GroupsIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Actor groups')}</span>
          </NavLink>
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:threats'),
      content: (
        <li key={'menu:threats'}>
          <ThreatsLink
            itemTextClassname={styles.itemText}
            threatCounterClassname={styles.threatCounter}
          />
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:dashboards'),
      content: (
        <li key={'menu:dashboards'}>
          <NavLink reloadDocument to="/dashboards">
            <Tooltip content={t('Dashboards')} position="right">
              <DashboardsIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Dashboards')}</span>
          </NavLink>
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:user_stats'),
      content: (
        <li key={'menu:user_stats'}>
          <NavLink reloadDocument to="/user_stats">
            <Tooltip content={t('User stats')} position="right">
              <UserStatsIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('User stats')}</span>
          </NavLink>
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:workspaces'),
      content: (
        <li key={'menu:workspaces'}>
          <NavLink reloadDocument to="/workspaces">
            <Tooltip content={t('Workspaces')} position="right">
              <WorkspacesIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Workspaces')}</span>
          </NavLink>
        </li>
      ),
    },
    {
      show: workspaceRoles?.includes('menu:async_export'),
      content: (
        <li key={'menu:async_export'}>
          <NavLink reloadDocument to="/exports">
            <Tooltip content={t('Exports')} position="right">
              <ExportIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Exports')}</span>
          </NavLink>
        </li>
      ),
    },
    {
      show: currentUser.is_admin,
      content: (
        <li key={'menu:users'}>
          <NavLink reloadDocument to="/users">
            <Tooltip content={t('Users')} position="right">
              <UserIcon />
            </Tooltip>
            <span className={styles.itemText}>{t('Users')}</span>
          </NavLink>
        </li>
      ),
    },
  ];

  if (currentUser?.workspace?.config?.is_reporter_only_user) {
    menuItems = [
      {
        show: workspaceRoles?.includes('menu:threats'),
        content: (
          <li key={'menu:threats'}>
            <ThreatsLink
              itemTextClassname={styles.itemText}
              threatCounterClassname={styles.threatCounter}
            />
          </li>
        ),
      },
    ];
  }

  return (
    <div>
      <input
        type="checkbox"
        id="nav-toggle"
        checked={isNavToogleChecked}
        onChange={() => setisNavToogleChecked(!isNavToogleChecked)}
      />

      <div className="sidebar">
        <div className="sidebar-brand">
          <h1>
            <span>
              <img className="full" src="/static/logo-full.svg" alt="" />
              <img className="short" src="/static/logo.svg" alt="" />
            </span>
          </h1>
        </div>

        <div className="sidebar-menu">
          <ul>
            {menuItems
              .filter((menuItem) => menuItem.show)
              .map((menuItem) => menuItem.content)}
          </ul>
          <div className={styles.userSettings}>
            <button
              type="button"
              className={styles.logoutBtn}
              onClick={logoutHandler}
            >
              <Tooltip content={t('Logout')} position="right">
                <LogoutIcon />
              </Tooltip>
              <span className={styles.itemText}>{t('Logout')}</span>
            </button>
          </div>
        </div>
      </div>
      <div className="main-content">
        <header>
          {workspaceName !== null && (
            <div className={styles.sudoWrapper}>
              <div>{workspaceName}</div>
              <div className="button">
                <button onClick={() => sudoGetOutHandle()}>
                  {t('Get out')}
                </button>
              </div>
            </div>
          )}
          <div className="page-header">
            <h2>
              <label htmlFor="nav-toggle" className={styles.menuLabel}>
                <img src={menuIcon} alt="" />
              </label>
            </h2>

            <div className={styles.userWrapper}>
              <div className={styles.userAvatar}>
                {/* <img  src="/static/me_64.jpg" alt="" /> */}
                <span>{getInitials()}</span>
              </div>
              <div>
                <h4>
                  <Link to='/users/settings'>
                    {currentUser.first_name} {currentUser.last_name}
                  </Link>
                </h4>
                <small>
                  {currentUser.is_admin
                    ? t('Administrator')
                    : t(currentUser.type)}
                </small>
              </div>
            </div>
          </div>
        </header>
      </div>

      <main className={workspaceName !== null ? 'sudo' : ''}>
        {maintenanceMessage ? (
          <div className={styles.maintenanceMessage}>
            <div
              className={styles.maintenanceMessageClose}
              onClick={() => setMaintenanceMessage(null)}
            >
              <CloseIcon />
            </div>
            <div>{maintenanceMessage}</div>
          </div>
        ) : (
          ''
        )}

        <Outlet />
        <ToastContainer />
      </main>
    </div>
  );
}
