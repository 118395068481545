import * as d3 from 'd3';
import { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { decodeSourceName } from '../../utils/decodeURI';
import { sentimentPalette, sentimentPaletteMap } from '../../utils/colors';
import { getSentimentIconBubbleChart, getSentimentName } from '../../utils/sentiment';
import { NarrativeStoriesModal } from '../../pages/NarrativePage/NarrativeStories/NarrativeStoriesModal';
import { AddToNarrativeModal } from '../AddToNarrativeModal/AddToNarrativeModal';
import { ThreatEditModal } from '../../pages/ThreatsPage/ThreatEditModal';
import { API } from '../../API';
import './CircleChartStories.scss';
import messageStyles from '../MessagesTable/ExtendedMessageView.module.scss';
import {ReactComponent as OriginalTextIcon} from '../../assets/originalText.svg';
import {ReactComponent as TranslationIcon} from '../../assets/translation.svg';
import { Sentiment } from '../Sentiment/Sentiment';
import { MessagesContext } from '../../pages/NarrativePage/contexts/MessagesContext';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { LoaderSmall } from '../LoaderSmall/LoaderSmall';
import { ExpandableText } from '../CollapsibleText/ExpandableText';

let formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s',);

const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
}

let formatPercentage = d3.format('.1f');



export function CircleChartStories({ data, narrative, onCircleClick = () => {}, onTranslationClick, narrativeColor,  currentUser, isShare}) {
  const { t } = useTranslation();

  const { 
    editBubbleChartSentiment,
} = useContext(MessagesContext);

  const svgWrapper = useRef(null);
  const popup = useRef(null);
  const parentWrapper = useRef(null);

  const [width, setWidth] = useState(900);
  const [height] = useState(600);
  const [currentNarrative, setCurrentNarrative] = useState('');
  const [popupOpen, togglePopup] = useState(false);
  const [isExpanded, toggleExpanded] = useState(false);
  const [shouldShowReset, setShouldShowReset] = useState(false);
  const [reference, updateReference] = useState({});
  const [infoModal, setInfoModal] = useState({isActive: false, id: null, title: null});
  const [addToNarrativeModal, setAddToNarrativeModal] = useState({isActive: false, storyId: null});
  const [addToThreatModal, setAddToThreatModal] = useState({isActive: false, storyId: null} );
  const deduplication = true;

  const featureFlags = currentUser?.workspace?.config.featureflags;
  const showMessageManipulationIndex = featureFlags?.includes('show_manipulation_index');


  let legend = [
    {
      title: t('Positive opinion'),
    },
    {
      title: t('Neutral opinion'),
    },
    {
      title: t('Negative opinion'),
    },
  ];

  const realData = useMemo(() => {
    const realdata = [];

    data.objects.forEach((narrative, i) => {
      narrative.stories.forEach((item) => {
        item.narrative = i;
        item.narrative_id = narrative.id;

        realdata.push(item);
      });
    });

    return realdata;
  }, [data.objects]);


  const handleResetClick = useCallback(() => {
    setShouldShowReset(false);

    updateReference({});

    void realData;
    void width;
    void height;
  }, [realData, width, height]);

  const dateScale = useMemo(() => {
    let dateRange = d3.extent(realData, function (d) {
      return new Date(d.date_created);
    });

    return d3
      .scaleTime()
      .domain([new Date(dateRange[0]), new Date(dateRange[1])])
      .range([0.1, 1]);
  }, [realData]);

  useEffect(() => {
    if (
      !parentWrapper.current ||
      typeof window.ResizeObserver === 'undefined'
    ) {
      return;
    }

    const resizeObserver = new window.ResizeObserver((entries) => {
      const rootEntry = entries.find((e) => e.target === parentWrapper.current);

      if (!rootEntry) {
        return;
      }

      setWidth(rootEntry.contentRect.width);
      // setHeight(rootEntry.contentRect.height);
    });

    resizeObserver.observe(parentWrapper.current);

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    const leftOffset = parentWrapper.current.getBoundingClientRect().left + 490;
    const topOffset = parentWrapper.current.getBoundingClientRect().top + 20;

    d3.select(svgWrapper.current).selectAll('*').remove();

    const svg = d3
      .select(svgWrapper.current)
      .attr('width', width)
      .attr('height', height)
      .style('width', '100%')
      .style('height', 'auto')
      .attr('font-size', 10)
      .attr('font-family', 'sans-serif')
      .attr('text-anchor', 'middle');

    svg
      .selectAll('circle.legend')
      .data(legend)
      .enter()
      .append('g')
      .attr('transform', (d, i) => `translate(40,${i * 30 + 20})`)
      .append('rect')
      .attr('rx', 5)
      .attr('y', -12)
      .attr('ry', 5)
      .attr('width', 36)
      .attr('height', 20)
      // .attr('fill', sentimentPalette[0])
      .attr('fill', (d, i) => (sentimentPalette[i]))
      // .style('opacity', (d, i) => (legend.length - i) / (legend.length + 1))

      .select(function () {
        return this.parentNode;
      })
      .append('text')
      .text((d) => d.title)
      .style('fill', '#2C2E30')
      .attr('text-anchor', 'start')
      .style('font-family', 'sans-serif')
      .style('font-size', '14px')
      .attr('x', 44)
      .attr('y', 3);

    let realdata = [...realData];

    // let scores = d3.extent(realdata, function (d) {
    //   return d.score;
    // });
    // let views = d3.extent(realdata, function (d) {
    //   return d.views;
    // });
    let story_share = d3.extent(realdata, function (d) {
      return d.story_share;
    });

    // let dateScore = d3.scalePow().exponent(3).domain(scores).range([20, 50]);
    // let dateScore = d3.scaleLinear().domain(views).range([20, 50]);
    let dateScore = d3.scaleLinear().domain(story_share).range([15, 50]);

    realdata = realdata.map((item) => {
      let offset = item.narrative - 0.5;
      let freshness = 1 - dateScale(new Date(item.date_created));
      item.cx = item.x = 140 * offset * freshness;
      item.cy = item.y = 0;
      return item;
    });

    var node = svg
      .append('g')
      .selectAll('circle')
      .data(realdata)
      .enter()
      .append('circle')
      .attr('r', (d) => dateScore(d.story_share))
      // .attr("cx", 500 + width / 2)
      // .attr("cy", height / 2)
      .attr('cx', (d) => d.cx)
      .attr('cy', (d) => d.cy)

      .attr('fill', (d) => {
        d3.color('steelblue');
        // let color = d.narrative ? "#1101ef" : "#ff9903";
        // return d.narrative ? "#1101ef" : "#ff9903";
        // return narrativeColor[d.narrative_id];
        return sentimentPaletteMap[d.sentiment]
        // return 'rgba(.5,0,0,1.)';
      })
      // .attr('fill-opacity', (d) => dateScale(new Date(d.date_publicated)))
      .call(
        d3
          .drag() // call specific function when circle is dragged
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended),
      );
    // .x(300)
    // .y(300)

    // Features of the forces applied to the nodes:
    var simulation = d3
      .forceSimulation(realdata)
      // .force("position", d3.forcePosition().position([width / 2, height / 2]))
      .force(
        'center',
        d3
          .forceCenter()
          .x(width / 2)
          .y(height / 2),
      )
      .force('charge', d3.forceManyBody().strength(5.5))
      .force(
        'collide',
        d3
          .forceCollide()
          .strength(0.04)
          .radius(function (d) {
            return dateScore(d.story_share) + 2;
          })
          .iterations(3),
      ); // Force that avoids circle overlapping

    simulation.nodes(realdata).on('tick', function (d) {
      node
        .attr('cx', function (d) {
          return d.x;
        })
        .attr('cy', function (d) {
          return d.y;
        });
    });

    // DOM EVENTS
    // ============================
    // ============================
    // ============================
    // ============================

    // let format = d3.format(',d');
    let current_circle = undefined;

    function selectMessage(event, d) {
      // popup.current.style.setProperty('--stroke', `var(--color${d.narrative})`);
      popup.current.style.setProperty('--stroke', `var(--clr-gray350)`);
      popup.current.style.setProperty(
        '--background',
        `none`,
      );

      popup.current.style.transform =
        'translate(' +
        Math.max(event.layerX, leftOffset) +
        'px,' +
        Math.max(event.layerY, 520) +
        'px)';

      if (current_circle !== undefined) {
        // current_circle.attr("fill", d => "#bbccff");
        current_circle.style('stroke-width', 0);
        svg.selectAll('#details-popup').remove();
      }


      // select the circle
      current_circle = d3.select(this);
      // // current_circle.attr("fill","#b2e1f9");
      current_circle.attr('stroke', '#000').style('stroke-width', 1);
      onCircleClick(d.id);
      setCurrentNarrative(d);
      togglePopup(true);
      toggleExpanded(false);
    }

    node.on('click', selectMessage);

    // What happens when a circle is dragged?
    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.03).restart();
      d.fx = d.x;
      d.fy = d.y;
    }
    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }
    function dragended(event, d) {
      if (!event.active) {
        simulation.alphaTarget(0.03);
      }

      d.fx = null;
      d.fy = null;

      setShouldShowReset(true);
    }
  }, [width, height, realData, dateScale, reference]);

  const saveToThreat = (narrativeId, threatName, threatDescription, caseType, incidentType, topics, geoScope, narratives, campaigns, counterEntities, reviewStatus, declineReason, organizations, isCreatedByUcbiReporter, threatPublicNameEn, threatPublicNameUk, threatPublicDescriptionEn, threatPublicDescriptionUk, threatIsPublic, messages) => {

    const messagesList = Object.keys(messages);
    
    let data = {
      name: threatName,
      description: threatDescription,
      is_threat: true,
      is_public: threatIsPublic,
      public_name_en: threatPublicNameEn,
      public_name_uk: threatPublicNameUk,
      public_description_en: threatPublicDescriptionEn,
      public_description_uk: threatPublicDescriptionUk,
    };

    const headers = { 'Content-Type': 'application/json' };
    
    if(caseType) {
      data.type = caseType.value;
    }

    if(incidentType) {
      data.incident_type = incidentType.value;
    }

    if(topics) {
      data.topic = topics.map(v => v.value);
    }

    if(geoScope) {
      data.geo_scope = geoScope.map(v => v.value);
    }

    if(narratives) {
      data.narrative_ids = narratives.map(v => v.value);
    }

    if(campaigns) {
      data.campaign_ids = campaigns.map(v => v.value);
    }

    if(counterEntities) {
      data.counter_entity_ids = counterEntities.map(v => v.value);
    }

    if(reviewStatus) {
      data.review_status = reviewStatus;
    }

    if(declineReason) {
      data.decline_reason = declineReason;
    }

    if(organizations) {
      data.workspace_ids = organizations.map( v => v.value);
    }

    data.is_created_by_ucbi_reporter = isCreatedByUcbiReporter

    let body = {
      new_narrative: data,
      existing_narrative_id: null,
      story_ids: messagesList,
    }

    API.fetch('POST', `/API/v1/narratives/${narrativeId}/copy_from_stories`, null, body).then()
  }


  return (
    <div className="circlechart" ref={parentWrapper}>
      {shouldShowReset && (
        <button className="button reset" onClick={handleResetClick}>
          Restart view
        </button>
      )}

      <div className="popup-position" ref={popup}>
        <div
          className="popup"
          style={{ display: popupOpen ? 'block' : 'none' }}
        >
          <div className="popup__close" onClick={() => togglePopup((o) => !o)}>
            <svg viewBox="0 0 32 32">
              <line
                x1="7"
                x2="25"
                y1="7"
                y2="25"
                stroke="#2c2e30"
                strokeWidth={2}
              />
              <line
                x1="7"
                x2="25"
                y1="25"
                y2="7"
                stroke="#2c2e30"
                strokeWidth={2}
              />
            </svg>
          </div>
          <div className="popup__data popup__date">
            <div className="popup__icon">
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 8.2H1M12.5556 1V4.6M5.44444 1V4.6M5.26667 19H12.7333C14.2268 19 14.9735 19 15.544 18.7057C16.0457 18.4469 16.4537 18.0338 16.7094 17.5258C17 16.9482 17 16.1921 17 14.68V7.12C17 5.60786 17 4.85179 16.7094 4.27423C16.4537 3.76619 16.0457 3.35314 15.544 3.09428C14.9735 2.8 14.2268 2.8 12.7333 2.8H5.26667C3.77319 2.8 3.02646 2.8 2.45603 3.09428C1.95426 3.35314 1.54631 3.76619 1.29065 4.27423C1 4.85179 1 5.60786 1 7.12V14.68C1 16.1921 1 16.9482 1.29065 17.5258C1.54631 18.0338 1.95426 18.4469 2.45603 18.7057C3.02646 19 3.77319 19 5.26667 19Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
            </svg>
            </div>
            <div className="popup__value">
              {currentNarrative?.first_date ? format(parseISO(currentNarrative?.first_date + 'Z'), 'dd LLL yyyy, HH:mm',) : ''}
            </div>
          </div>
          
          {currentNarrative.isTranslation === true && !currentNarrative.translated ? <LoaderSmall/> : <p className="popup__title">
            {/* {isExpanded
              ? currentNarrative.isTranslation ? currentNarrative.translated : currentNarrative.summary
              : currentNarrative.isTranslation ? currentNarrative.translated?.substring(0, 150) : currentNarrative.summary?.substring(0, 150)}
            {currentNarrative.summary?.length > 150 && (
              <span>
                ...
                <span
                  className="popup__more"
                  onClick={() => toggleExpanded((o) => !o)}
                >
                  {isExpanded ? t('Show less') : t('Show more')}
                </span>
              </span>
            )} */}
            {currentNarrative?.summary && popupOpen ? <ExpandableText
              length={200}
              // highlights={highlights}
              text={currentNarrative?.isTranslation ? currentNarrative.translated : currentNarrative.summary}
              textClassName={messageStyles.extendedMessage}
              isLabel={true}
            /> : ''}
          </p>}
          <div className={messageStyles.translationContainer}>
            <div className={messageStyles.translationWrapper}>
              <span
                className={
                  currentNarrative?.isTranslation ? '' : messageStyles.active
                }
                onClick={() =>
                  onTranslationClick(currentNarrative.id, currentNarrative.summary, false)
                }
              >
                <OriginalTextIcon />
              </span>
              <span
                className={
                  currentNarrative?.isTranslation ? messageStyles.active : ''
                }
                onClick={() =>
                  onTranslationClick(currentNarrative.id, currentNarrative.summary, true)
                }
              >
                <TranslationIcon />
              </span>
            </div>
          </div>
          
          <div className="popup__data popup__total">
            <h3 className='h3_stories'>{t('Total messages')}:</h3>
            <div className="popup__value">
              {formatNumber(currentNarrative.messages_count)}
            </div>
            <div
              className="popup__icon popup__stories-modal"
              onClick={() => {
                setInfoModal({
                    isActive: true,
                    id: currentNarrative.id,
                    title: currentNarrative.summary.substring(-1, 50),
                  })
              }}>
              <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 10.7778V12.7333C17 14.2268 17 14.9735 16.7094 15.544C16.4537 16.0457 16.0457 16.4537 15.544 16.7094C14.9735 17 14.2268 17 12.7333 17H10.7778M7.22222 1H5.26667C3.77319 1 3.02646 1 2.45603 1.29065C1.95426 1.54631 1.54631 1.95426 1.29065 2.45603C1 3.02646 1 3.77319 1 5.26667V7.22222M11.6667 6.33333L17 1M17 1H11.6667M17 1V6.33333M6.33333 11.6667L1 17M1 17H6.33333M1 17L1 11.6667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
          </div>
          
          <div className="popup__resume">
            <div className="popup__data">
              <div className="popup__icon">
                {currentNarrative?.sentiment_score !== null 
                  ? <span className='sentiment_icon'>
                      <Sentiment
                        sentimentScore={currentNarrative?.sentiment_score}
                        messageId={currentNarrative?.id} narrative={narrative}
                        isShare={isShare}
                        isChart={true}
                        isStories={true}
                        onChange={(sentiment, messageId) => editBubbleChartSentiment(sentiment, messageId, narrative)}
                      />
                    </span> : ''}
                {/* {currentNarrative?.sentiment_score !== null ? <span className='sentiment_icon'>{getSentimentIconBubbleChart(currentNarrative?.sentiment_score)}</span> : ''} */}
              </div>
            </div>
            <div className="popup__data">
              <div className="popup__icon">
                <svg width="22" height="16" viewBox="0 0 22 16" fill="none">
                  <path
                    d="M1.26805 8.71318C1.12971 8.49754 1.06054 8.38972 1.02181 8.22342C0.992729 8.0985 0.992729 7.9015 1.02181 7.77658C1.06054 7.61028 1.12971 7.50246 1.26805 7.28682C2.41127 5.50484 5.81418 1 11 1C16.1858 1 19.5887 5.50484 20.7319 7.28682C20.8703 7.50246 20.9395 7.61028 20.9782 7.77658C21.0073 7.9015 21.0073 8.0985 20.9782 8.22342C20.9395 8.38972 20.8703 8.49754 20.7319 8.71318C19.5887 10.4952 16.1858 15 11 15C5.81418 15 2.41127 10.4952 1.26805 8.71318Z"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M11 11C12.6831 11 14.0475 9.65685 14.0475 8C14.0475 6.34315 12.6831 5 11 5C9.31692 5 7.95251 6.34315 7.95251 8C7.95251 9.65685 9.31692 11 11 11Z"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="popup__value">
                {formatWithCustomGigaBillion(currentNarrative.impressions_sum)}
              </div>
            </div>
            <div className="popup__data">
              <div className="popup__icon">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M5.10176 17V8.2M1 9.8V15.4C1 16.2837 1.73457 17 2.6407 17H13.655C14.8697 17 15.9027 16.1357 16.0874 14.9649L16.9709 9.36494C17.2002 7.91112 16.0468 6.6 14.5384 6.6H11.6646C11.2115 6.6 10.8442 6.24183 10.8442 5.8V2.97267C10.8442 1.8832 9.93857 1 8.82137 1C8.5549 1 8.31343 1.15304 8.2052 1.3905L5.31829 7.72491C5.18662 8.01381 4.89283 8.2 4.56864 8.2H2.6407C1.73457 8.2 1 8.91634 1 9.8Z"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="popup__value">
                {formatWithCustomGigaBillion(currentNarrative?.engagement_sum)}
              </div>
            </div>
            {showMessageManipulationIndex ? <div className="popup__data">
              <div className="popup__icon">
                <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 1H4.2C3.0799 1 2.51984 1 2.09202 1.21799C1.71569 1.40973 1.40973 1.71569 1.21799 2.09202C1 2.51984 1 3.0799 1 4.2V6M6 19H4.2C3.0799 19 2.51984 19 2.09202 18.782C1.71569 18.5903 1.40973 18.2843 1.21799 17.908C1 17.4802 1 16.9201 1 15.8V14M19 6V4.2C19 3.0799 19 2.51984 18.782 2.09202C18.5903 1.71569 18.2843 1.40973 17.908 1.21799C17.4802 1 16.9201 1 15.8 1H14M19 14V15.8C19 16.9201 19 17.4802 18.782 17.908C18.5903 18.2843 18.2843 18.5903 17.908 18.782C17.4802 19 16.9201 19 15.8 19H14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
                  <path d="M4 7.14286L12 7.14286M12 7.14286C12 8.32633 12.8954 9.28571 14 9.28571C15.1046 9.28571 16 8.32632 16 7.14286C16 5.95939 15.1046 5 14 5C12.8954 5 12 5.95939 12 7.14286ZM8 12.8571L16 12.8571M8 12.8571C8 14.0406 7.10457 15 6 15C4.89543 15 4 14.0406 4 12.8571C4 11.6737 4.89543 10.7143 6 10.7143C7.10457 10.7143 8 11.6737 8 12.8571Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>
              </div>
              <div className="popup__value">
                {currentNarrative?.manipulation_index_avg 
                  ? currentNarrative?.manipulation_index_avg.toFixed(2)
                  : '0'}
              </div>
            </div> : ''}
          </div>
        </div>
      </div>
      <svg width={width} height={height} ref={svgWrapper}></svg>
      {infoModal.isActive ? <NarrativeStoriesModal
        infoModal={infoModal}
        narrative={narrative}
        isShare={isShare}
        onChange={(bool) => {
          setInfoModal({isActive: bool, id: null, title: null});
        }}
        onAddToCase={(storyId) => {
          setInfoModal({isActive: false, id: null, title: null});
          setAddToNarrativeModal({isActive: true, storyId: storyId})

        }}
        onAddToThreat={(storyId) => {
          setInfoModal({isActive: false, id: null, title: null});
          setAddToThreatModal({isActive: true, storyId: storyId})

        }}
      />
      : ''}
      {addToNarrativeModal.isActive ? (
        <AddToNarrativeModal
          isOpen={addToNarrativeModal}
          messages={addToNarrativeModal.storyId}
          deduplication={deduplication}
          caseId={narrative.id}
          onChange={(bool) => {
            setAddToNarrativeModal(bool);
          }}
        />
      ) : (
        ''
      )}

      {addToThreatModal.isActive ? (
        <ThreatEditModal
          isOpen={addToThreatModal}
          title={t('Add to a threat')}
          threat={narrative}
          isAdmin={currentUser?.is_super_admin}
          onChange={(bool) =>
            setAddToThreatModal({ isActive: bool, storyId: null })
          }
          handleThreat={({
            threat,
            threatName,
            threatDescription,
            caseType,
            incidentType,
            topics,
            geoScope,
            narratives,
            campaigns,
            counterEntities,
            reviewStatus,
            declineReason,
            organizations,
            isCreatedByUcbiReporter,
            threatPublicNameEn,
            threatPublicNameUk,
            threatPublicDescriptionEn,
            threatPublicDescriptionUk,
            threatIsPublic,
          }) =>
            saveToThreat(
              threat.id,
              threatName,
              threatDescription,
              caseType,
              incidentType,
              topics,
              geoScope,
              narratives,
              campaigns,
              counterEntities,
              reviewStatus,
              declineReason,
              organizations,
              isCreatedByUcbiReporter,
              threatPublicNameEn,
              threatPublicNameUk,
              threatPublicDescriptionEn,
              threatPublicDescriptionUk,
              threatIsPublic,
              addToThreatModal.storyId,
            )
          }
        />
      ) : (
        ''
      )}
    </div>
  );
}
