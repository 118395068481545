import styles from '../NarrativePage.module.scss';
import cn from 'classnames';
import * as d3 from 'd3';
import { Link } from 'react-router-dom';
import { Loader } from '../../../components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { platformNamesMapping } from '../../../utils/platforms';
import { ReactComponent as ArrowIcon } from '../../NarrativesPage/assets/arrow.svg';
import { platformIconsMapping } from '../../../utils/platforms';
import { decodeSourceName } from '../../../utils/decodeURI';
import { cutString } from '../../../utils/cutString';
import { ReactComponent as VerifiedTwitter } from '../../SourcePage/assets/verifiedTwitter.svg';
import Tooltip from '../../../components/Tooltip/Tooltip';


const entityTypes = {
  STATE_AGENCY: 'State agency',
  GOVERNMENT_OFFICIAL: 'Government official',
  STATE_OWNED_MEDIA: 'State owned media',
  REPORTEDLY_STATE_AFFILIATED: 'Reportedly state affiliated',
};

const mapBotEntityTypes = {
  'suspended_by_platform': 'Suspended by platform',
  'inauthentic_behavior': 'Inauthentic behavior',
  'immature_account':'Immature accounts',
}

const sortingFieldNames = {
  FOLLOWERS: 'audience',
};

const formatNumber = d3.format(",d");
const formatNumberSignificant = d3.format('.3~s',);

export const NarrativeSourcesTable = ({
  sources,
  type,
  sorting,
  handleSourceSortingClick,
  isLoading,
  isShare = false,
}) => {
  const { t } = useTranslation();

  let columnsSet = [
    'Actor',
    'Audience',
    'Origin country',
    'Affiliation',
    'Country of affiliation',
    'Proof of affiliation',
    'Indicators of Compromise (IoC)',
    'Proof of IoC',
    'Inauthentic account type',
  ];

  if (type === 'is_bot') {
    columnsSet = ['Actor', 'Audience', 'Origin country', 'Inauthentic account type'];
  }

  if (type === 'state_affiliated') {
    columnsSet = [
      'Actor',
      'Audience',
      'Origin country',
      'Affiliation',
      'Country of affiliation',
      'Proof of affiliation',
    ];
  }

  if (type === 'discreditated') {
    columnsSet = [
      'Actor',
      'Audience',
      'Origin country',
      'Indicators of Compromise (IoC)',
      'Proof of IoC',
    ];
  }

  const actorInfo = (source) => {
    return (
      <td>
        <div className={styles.sourceNameWrapper}>
          <div className={styles.platformWrapper}>
            {platformIconsMapping[source.source_type]}
          </div>
          <div className={styles.sourceNameContainer}>
            <span>
              {isShare ? (
                cutString(decodeSourceName(source.name), 30)
              ) : (
                <Link to={`/sources/${source.id}`}>
                  {cutString(decodeSourceName(source.name), 30)}
                </Link>
              )}
              {source?.is_verified ? (
                <span className={styles.verified}>
                  <VerifiedTwitter />
                </span>
              ) : (
                ''
              )}
            </span>
            <a href={source?.url} target="_blank" rel="noreferrer">
              {cutString(decodeSourceName(source?.url), 30)}
            </a>
          </div>
        </div>
      </td>
    );
  };

  const audience = (source) => {
    let followers_cnt = source?.audience;
    return <td>{formatNumberSignificant(followers_cnt)}</td>;
  };

  const originCountry = (source) => {
    return <td>{source?.origin_country?.name}</td>;
  };

  const affiliation = (source) => {
    return (
      <td>
        {source.affiliations?.length > 0 ? (
          source?.affiliations[0].entity_type ==
          'REPORTEDLY_STATE_AFFILIATED' ? (
            <Tooltip
              content={t(
                'The source is reported to be state affiliated by an authoritative organization (government or non-government)',
              )}
              position="bottom"
            >
              {t(entityTypes[source?.affiliations[0].entity_type])}
            </Tooltip>
          ) : (
            t(entityTypes[source?.affiliations[0].entity_type])
          )
        ) : (
          ''
        )}
      </td>
    );
  }; 

  const affiliationCountry = (source) => {
    return(
      <td>
        {source.affiliations?.length > 0
          ? source.affiliations[0]?.country?.name
          : ''}
      </td>
    )
  }

  const affiliationProof = (source) => {
    let affilliationProofName = null;

    if (
      localStorage.getItem('i18nextLng') === 'ua-UK' ||
      localStorage.getItem('i18nextLng') === 'ru-RU' ||
      localStorage.getItem('i18nextLng') === 'ru' ||
      localStorage.getItem('i18nextLng') === 'uk'
    ) {
      affilliationProofName = source?.affiliations[0]?.proof_name_uk;
    } else {
      affilliationProofName = source?.affiliations[0]?.proof_name_en;
    }
    return (
      <td>
        {source?.affiliations[0]?.proof_link &&
        (source?.affiliations[0]?.proof_name_en ||
          source?.affiliations[0]?.proof_name_uk) ? (
          source?.affiliations[0]?.proof_link === 'Twitter labeling' ? (
            <a
              href="https://help.twitter.com/en/rules-and-policies/government-media-labels#:~:text=State%2Daffiliated%20media%20is%20defined,control%20over%20production%20and%20distribution"
              target="_blank"
              rel="noreferrer"
            >
              {source.affiliations?.length > 0 ? affilliationProofName : ''}
            </a>
          ) : (
            <a
              href={source.affiliations[0]?.proof_link}
              target="_blank"
              rel="noreferrer"
            >
              {source.affiliations?.length > 0 ? affilliationProofName : ''}
            </a>
          )
        ) : !source?.affiliations[0]?.proof_link &&
          (source?.affiliations[0]?.proof_name_en ||
            source?.affiliations[0]?.proof_name_uk) ? (
          affilliationProofName
        ) : (
          ''
        )}
      </td>
    );
  };

  const discreditation = (source) => {
    return (
      <td>
        <Tooltip
          content={
            mapCompromisedEntityTypes[source.discreditations[0]?.entity_type]
              ?.tooltip
              ? mapCompromisedEntityTypes[
                  source.discreditations[0]?.entity_type
                ]?.tooltip
              : ''
          }
          position="bottom"
        >
          {
            mapCompromisedEntityTypes[source.discreditations[0]?.entity_type]
              ?.title
          }
        </Tooltip>
      </td>
    );
  };

  const discreditationProof = (source) => {
    let discreditationProofName = null;

    if (
      localStorage.getItem('i18nextLng') === 'ua-UK' ||
      localStorage.getItem('i18nextLng') === 'ru-RU' ||
      localStorage.getItem('i18nextLng') === 'ru' ||
      localStorage.getItem('i18nextLng') === 'uk'
    ) {
      discreditationProofName = source?.discreditations[0]?.proof_name_uk;
    } else {
      discreditationProofName = source?.discreditations[0]?.proof_name_en;
    }
    return (
      <td>
        {source?.discreditations[0]?.proof_link &&
        (source?.discreditations[0]?.proof_name_en ||
          source?.discreditations[0]?.proof_name_uk) ? (
          <a
            href={source.discreditations[0]?.proof_link}
            target="_blank"
            rel="noreferrer"
          >
            {source.discreditations?.length > 0 ? discreditationProofName : ''}
          </a>
        ) : !source?.discreditations[0]?.proof_link &&
          (source?.discreditations[0]?.proof_name_en ||
            source?.discreditations[0]?.proof_name_uk) ? (
          discreditationProofName
        ) : (
          ''
        )}
      </td>
    );
  };

  const botType = (source) => {
    let entityType = t('N/A');

    if (source?.suspended_by_platform === true) {
      entityType = mapBotEntityTypes['suspended_by_platform'];
    } else if (
      source?.inauthentic_behavior === true ||
      source?.is_bot === true
    ) {
      entityType = mapBotEntityTypes['inauthentic_behavior'];
    } else if (source?.immature_account === true) {
      entityType = mapBotEntityTypes['immature_account'];
    }
    return <td>{t(entityType)}</td>;
  };

  let columnsSetMap = {
    'Actor': actorInfo,
    'Audience': audience,
    'Origin country': originCountry,
    'Affiliation': affiliation,
    'Country of affiliation': affiliationCountry,
    'Proof of affiliation': affiliationProof, 
    'Indicators of Compromise (IoC)': discreditation, 
    'Proof of IoC': discreditationProof, 
    'Inauthentic account type': botType,
  }

  const mapCompromisedEntityTypes = {
    INVOLVED_IN_INFLUENCE_OPERATIONS: {title: t('Influence operations'), tooltip: t('Account has been involved in influence operations')},
    HACKED_OR_STOLEN: {title: t('Hacked / Stolen'), tooltip: t('Account has been hacked or stolen')},
    SANCTIONS: {title: t('Sanctions'), tooltip: t('Sources are listed in official sanctions list of EU (and member countries), US, UK, and other countries')},
    DISINFORMATION: {title: t('Disinformation'), tooltip: t('Sources are frequently sharing disinformative content, which was autodetected by Osavul AI')}
  }

  return (
    <>
      {isLoading || sources === null ? (
        <Loader />
      ) : sources?.objects?.length > 0 ? (
        <div className={styles.tableWrapper}>
          <div>
            <table className={styles.sources}>
              <thead>
                <tr className={styles.headerInfo}>
                  <td>
                    {t('Total actors')}: {formatNumber(sources.total)}
                  </td>
                  <td colSpan="11"></td>
                </tr>
                <tr>
                  {columnsSet.map((name) => {
                    return (
                      <td>
                        <div className={styles.wrapper}>
                          <span>{t(name)}</span>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              </thead>

              <tbody>
                {sources?.objects?.map((source) => {
                  return (
                    <tr key={source.id}>
                      {columnsSet.map((column) => {
                        return columnsSetMap[column](source);
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className={styles.noData}>{t('There is no data to display')}</div>
      )}
    </>
  );
};
