import { useEffect, useState, useContext } from "react";
import { Chart } from "react-google-charts";
import { API } from "../../../API";
import { ShareAPI } from "../../../API";
import { useTranslation } from 'react-i18next';
import { BreakdownsContext } from "./contexts/BreakdownsContext";
import { BreakdownsContainer } from "../../../components/BreakdownsContainer/BreakdownsContainer";
import { LoaderSmall } from "../../../components/LoaderSmall/LoaderSmall";
import styles from '../NarrativePage.module.scss';
import { is } from "date-fns/locale";

export const Verification = ({narrative, isShare, isClickable=true}) => {
  const { t } = useTranslation();
  const [verification, setVerification] = useState(null);

  const {openModal, setTypesFilter} = useContext(BreakdownsContext);

  const api = isShare ? ShareAPI : API;

  const getNarrativeVerifications = () => {
    api.fetch('GET', `/API/v1/narratives/${narrative.id}/verifications`).then(
      data => setVerification(data)
    )
  }

  useEffect(() => {
    getNarrativeVerifications()
  }, [narrative])

  const getNotVerifiedCount = (verification) => {
    let notVerified = verification?.objects.filter(item => item.name !== 'verified');
    let count = notVerified.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0);
    return count;
  }

  const getVerifiedCount = (verification) => {
    let verified = verification?.objects.filter(item => item.name === 'verified');
    let count = verified[0]?.count;
    return count;
  }

  const getChartData = (verification) => {
    let data = [
      ['Actor', 'Count']
    ]
    let verCount = getVerifiedCount(verification);
    let notVerCount = getNotVerifiedCount(verification);

    data.push([t('Verified'), verCount])
    data.push([t('Not verified'), notVerCount])
    return data;
  }

  const mapIndexToValue = {
    0: 'true',
    1: 'false'
  }

  const options = {
    colors: ['#186ADE', '#B5C9F6'], 
    tooltip: { showColorCode: true }, 
    legend: {
      position: 'labeled', 
      textStyle: { fontSize: 16, },
    },
    chartArea: { left: '5%', top: '20%', width:'95%', height:'75%' },
    pieSliceText: 'value',
    slices: { 0: {offset: 0.05} },
  }

  return(
    <BreakdownsContainer title='Verification' onClick={() => openModal()} flexGrow='2' details={false}>
      <div className={styles.chartWrapper}>
        {verification ? (verification.objects?.length > 0 
        ? <Chart
          chartType="PieChart"
          data={getChartData(verification)}
          width={"95%"}
          height={"350px"}
          className='pieChart'
          options={options}
          chartEvents={
            isClickable
            ? [
              {
                eventName: "select",
                callback({ chartWrapper }) {
                  let selected = chartWrapper.getChart().getSelection();
                  let index = selected[0]?.row;
                  let option = {value: 'is_verified', label: 'Verified', bool: mapIndexToValue[index]}
                  setTypesFilter(option)
                  openModal()
                }
              }
            ]
            : []
          }
          
          style={{margin: '0px', padding: '0px'}}
          
        /> 
        : <div className={styles.noData}>{t('There is no data to display')}</div>
        ) : <div className={styles.widdgetLoader}><LoaderSmall /></div>}
      </div>
      
    </BreakdownsContainer>
  )
}

