import * as d3 from 'd3';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Select from 'react-select';
import { format, parseISO, formatISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import ExportReportPopup from '../../components/ExportReportPopup';
import { API, ShareAPI } from '../../API';
import { TrendChart } from '../../components/TrendChart/TrendChart';
import { TreeChart } from '../../components/TreeChart/TreeChart';
import { convertSourcesDataToTree } from '../../components/TreeChart/convertSourcesDataToTree';
import { Loader } from '../../components/Loader/Loader';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as ShareIcon } from './assets/share.svg';
import { ReactComponent as FakeNewsIcon } from './assets/fake_news.svg';
import { ReactComponent as DocumentLeakIcon } from './assets/document_leak.svg';
import { ReactComponent as CoordinatedActivityIcon } from './assets/coordinated_activity.svg';
import { ReactComponent as ProtestsCoordinationIcon } from './assets/protests_coordination.svg';
import { ThreatsModal } from './ThreatsModal/ThreatsModal';
import { palette } from '../../utils/colors';
import './TopicReportPage.scss';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s',);

export function TopicReportPage({ isShare = false }) {
  const [exportModal, setExportModal] = useState({ isActive: false, id: null });
  const { t } = useTranslation();
  const [report, setReport] = useState(null);
  const [threatsModal, setThreatsModal] = useState({ isActive: false, id: null, title: null });
  
  const [statsAggregationOptions] = useState(() => [
    { value: 'DAY', label: t('Day') },
    { value: 'WEEK', label: t('Week') },
    { value: 'MONTH', label: t('Month') },
  ]);

  const STORY_SUB_TYPE_MAP = {
    FAKE_NEWS: {
      icon: <FakeNewsIcon />,
      title: t('Fake news'),
    },
    DOCUMENT_LEAK: {
      icon: <DocumentLeakIcon />,
      title: t('Document leak'),
    },
    PROTESTS_COORDINATION: {
      icon: <ProtestsCoordinationIcon />,
      title: t('Protests coordination'),
    },
    COORDINATED_ACTIVITY: {
      icon: <CoordinatedActivityIcon />,
      title: t('Coordinated activity'),
    },
    DEBUNK: {
      icon: <CoordinatedActivityIcon />,
      title: t('Debunk'),
    },
    POSITIVE_NARRATIVE: {
      icon: <CoordinatedActivityIcon />,
      title: t('Positive narrative'),
    },
    GOVERNMENT_COMMUNICATION: {
      icon: <CoordinatedActivityIcon />,
      title: t('Government communication'),
    },
    FAKE_DISINFORMATION: {
      icon: <CoordinatedActivityIcon />,
      title: t('Fake/Disinformation'),
    },
    MANIPULATION_MISINFORMATION: {
      icon: <CoordinatedActivityIcon />,
      title: t('Manipulation/Misinformation'),
    },
    DISPROOF_DEBUNK: {
      icon: <CoordinatedActivityIcon />,
      title: t('Disproof/Debunk'),
    },
    STRATEGIC_NARRATIVE_MESSAGE: {
      icon: <CoordinatedActivityIcon />,
      title: t('Strategic narrative/message (reporting)'),
    },
    UNCLASSIFIED_THREAT: {
      icon: <CoordinatedActivityIcon />,
      title: t('Unclassified threat'),
    },
    OTHER: {
      icon: <CoordinatedActivityIcon />,
      title: t('Other_'),
    },
  };

  const [statsAggregation, setStatsAggregation] = useState(
    () => statsAggregationOptions[0],
  );
  const [topicShares, setTopicShares] = useState(null);
  const [topicTopStories, setTopicTopStories] = useState(null);
  const [topicTopThreats, setTopicTopThreats] = useState(null);
  const [topicSourcesStats, setTopicSourcesStats] = useState(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { topicReportId } = useParams();
  const itemsRef = useRef([]);

  const [totalEvolutionChart, setTotalEvolutionChart] = useState('topic_share');

  const sameHeightHeaders = () => {
    let maxHeight = 0;

    if (itemsRef.current.length > 0) {
      itemsRef.current.forEach((item) => {
        if (item.clientHeight > maxHeight) {
          maxHeight = item.clientHeight;
        }
      });
      itemsRef.current.forEach((item) => {
        item.style.height = `${maxHeight}px`;
      });
    }
  };

  const calculateStoryIndex = (score) => {
    if (score <= 33.3) {
      return <span className="story-index low">{t('Low')}</span>;
    } else if (score > 33.3 && score < 66.6) {
      return <span className="story-index medium">{t('Medium')}</span>;
    } else {
      return <span className="story-index high">{t('High')}</span>;
    }
  };

  const onRequestError = useCallback(
    (resp) => {
      if (isShare && (resp.status === 401 || resp.status === 403)) {
        const hash = searchParams.get('h');
        navigate(`/topic_reports/${topicReportId}/share/login?h=${hash}`);
      }
    },
    [navigate, topicReportId, searchParams, isShare],
  );

  const fetchReport = useCallback(() => {
    const api = isShare ? ShareAPI : API;

    api
      .fetch(
        'GET',
        `/API/v1/topic_reports/${topicReportId}`,
        null,
        null,
        null,
        onRequestError,
      )
      .then((data) => {
        setReport(data);

        const urlParams = new URLSearchParams();
        data.parameters.topic_ids.forEach((topic_id) =>
          urlParams.append('topic_id', topic_id),
        );
        if (data.parameters.start_date !== null) {
          urlParams.set(
            'start',
            data.parameters.start_date.split('T')[0] + 'T00:00:00'
          );
        }
        if (data.parameters.end_date !== null) {
          urlParams.set(
            'end',
            data.parameters.end_date.split('T')[0] + 'T23:59:59'
          );
        }
      });
  }, [isShare, topicReportId, onRequestError]);

  const fetchTopicsShare = useCallback(() => {
    const api = isShare ? ShareAPI : API;

    if (!report) return;

    const urlParams = new URLSearchParams();
    report.parameters.topic_ids.forEach((topic_id) =>
      urlParams.append('topic_id', topic_id),
    );
    if (report.parameters.start_date !== null) {
      urlParams.set(
        'start',
        report.parameters.start_date.split('T')[0] + 'T00:00:00'
      );
    }
    if (report.parameters.end_date !== null) {
      urlParams.set(
        'end',
        report.parameters.end_date.split('T')[0] + 'T23:59:59'
      );
    }

    urlParams.set('aggregation', statsAggregation.value);

    api
      .fetch(
        'GET',
        `/API/v1/topics/stories/stats?${urlParams.toString()}`,
        null,
        null,
        null,
        onRequestError,
      )
      .then(setTopicShares);
  }, [isShare, onRequestError, report, statsAggregation]);

  const fetchTopicTopStories = useCallback(() => {
    const api = isShare ? ShareAPI : API;

    if (!report) return;

    const urlParams = new URLSearchParams();

    urlParams.set('story_type', 'GENERAL');

    report.parameters.topic_ids.forEach((topic_id) =>
      urlParams.append('topic_id', topic_id),
    );
    if (report.parameters.start_date !== null) {
      urlParams.set(
        'start',
        report.parameters.start_date.split('T')[0] + 'T00:00:00'
      );
    }
    if (report.parameters.end_date !== null) {
      urlParams.set(
        'end',
        report.parameters.end_date.split('T')[0] + 'T23:59:59'
      );
    }

    api
      .fetch(
        'GET',
        `/API/v1/topics/stories?${urlParams.toString()}`,
        null,
        null,
        null,
        onRequestError,
      )
      .then(setTopicTopStories);
  }, [isShare, onRequestError, report]);

  const fetchTopicTopThreats = useCallback(() => {
    const api = isShare ? ShareAPI : API;

    if (!report) return;

    const urlParams = new URLSearchParams();

    urlParams.set('story_type', 'THREAT');

    report.parameters.topic_ids.forEach((topic_id) =>
      urlParams.append('topic_id', topic_id),
    );
    if (report.parameters.start_date !== null) {
      urlParams.set(
        'start',
        report.parameters.start_date.split('T')[0] + 'T00:00:00'
      );
    }
    if (report.parameters.end_date !== null) {
      urlParams.set(
        'end',
        report.parameters.end_date.split('T')[0] + 'T23:59:59'
      );
    }

    api
      .fetch(
        'GET',
        `/API/v1/topics/stories?${urlParams.toString()}`,
        null,
        null,
        null,
        onRequestError,
      )
      .then(setTopicTopThreats);
  }, [isShare, onRequestError, report]);

  const fetchTopicSourcesStats = useCallback(() => {
    const api = isShare ? ShareAPI : API;

    if (!report) return;

    const urlParams = new URLSearchParams();
    report.parameters.topic_ids.forEach((topic_id) =>
      urlParams.append('ids', topic_id),
    );
    if (report.parameters.start_date !== null) {
      urlParams.set(
        'start',
        report.parameters.start_date.split('T')[0] + 'T00:00:00'
      );
    }
    if (report.parameters.end_date !== null) {
      urlParams.set(
        'end',
        report.parameters.end_date.split('T')[0] + 'T23:59:59'
      );
    }

    api
      .fetch(
        'GET',
        `/API/v1/sources/stats/TOPIC?${urlParams.toString()}`,
        null,
        null,
        null,
        onRequestError,
      )
      .then((data) => setTopicSourcesStats(data));
  }, [isShare, report, onRequestError]);

  useEffect(() => {
    sameHeightHeaders();
    setTimeout(sameHeightHeaders, 500);
  }, [topicShares]);

  useEffect(fetchReport, [fetchReport]);
  useEffect(fetchTopicSourcesStats, [fetchTopicSourcesStats]);

  useEffect(fetchTopicsShare, [fetchTopicsShare]);
  useEffect(fetchTopicTopStories, [fetchTopicTopStories]);
  useEffect(fetchTopicTopThreats, [fetchTopicTopThreats]);

  const select = (
    <Select
      className="chart-select"
      onChange={setStatsAggregation}
      value={statsAggregation}
      options={statsAggregationOptions}
    />
  );

  if (!report || !topicShares) {
    return <Loader />;
  }

  let treeData = convertSourcesDataToTree(
    topicSourcesStats,
    setStatsAggregation.value,
    t,
  );

  const narrativeColor = Object.fromEntries(
    topicShares.objects.map((narrative, i) => [narrative.id, palette[i % palette.length]])
  );

  return (
    <div className="list-content">
      <div className="page-header">
        <div className="breadcrumb">
          <span>
            <Link to="/topic_reports">{t('Topic discovery')}</Link>
          </span>
          <span>{report.name}</span>
        </div>
        <div className="controls">
          {/* {isShare ? (
            ''
          ) : (
            <>
              <Link
                to={`/reports/${topicReportId}/edit`}
                className="button new-button"
              >
                <EditIcon /> {t('Edit report')}
              </Link>
              <button
                type="button"
                className="button new-button"
                onClick={() =>
                  setExportModal({ isActive: true, id: report.id })
                }
              >
                <ShareIcon /> {t('Export report')}
              </button>
            </>
          )} */}
        </div>
      </div>

      <div className="report-section">
        <h3>{t('Overview')}</h3>
        <div className="overview-topics">
          <div className="card-wrapper color0">
            <span className="card-content__icon">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path
                  d="M11.7076 17.3639L10.2933 18.7781C8.34072 20.7308 5.1749 20.7308 3.22228 18.7781C1.26966 16.8255 1.26966 13.6597 3.22228 11.7071L4.63649 10.2929M17.3644 11.7071L18.7786 10.2929C20.7312 8.34024 20.7312 5.17441 18.7786 3.22179C16.826 1.26917 13.6602 1.26917 11.7076 3.22179L10.2933 4.636M7.50045 14.4999L14.5005 7.49994"
                  stroke="#2563EB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </span>
            <div className="card-content">
              <div className="header">{t('Actors')}</div>
              <div className="body">{formatNumber(topicShares.sources)}</div>
            </div>
          </div>
          <div className="card-wrapper color1">
            <span className="card-content__icon">
              <svg width="15" height="18" viewBox="0 0 15 18" fill="none">
                <path
                  d="M9.125 1.21563V4.52006C9.125 4.9681 9.125 5.19212 9.21356 5.36325C9.29145 5.51378 9.41575 5.63616 9.56863 5.71286C9.74244 5.80006 9.96996 5.80006 10.425 5.80006H13.7811M10.75 9.8H4.25M10.75 13H4.25M5.875 6.6H4.25M4.9 17H10.1C11.4651 17 12.1477 17 12.6691 16.7384C13.1277 16.5083 13.5006 16.1412 13.7343 15.6896C14 15.1762 14 14.5041 14 13.16V6.63752C14 6.10172 13.785 5.58831 13.4032 5.21239L9.70885 1.57487C9.33471 1.20648 8.8307 1 8.30564 1H4.9C3.53487 1 2.85231 1 2.3309 1.26158C1.87225 1.49168 1.49936 1.85883 1.26567 2.31042C1 2.82381 1 3.49587 1 4.84V13.16C1 14.5041 1 15.1762 1.26567 15.6896C1.49936 16.1412 1.87225 16.5083 2.3309 16.7384C2.85231 17 3.53487 17 4.9 17Z"
                  stroke="#2563EB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </span>
            <div className="card-content">
              <div className="header">{t('Publications')}</div>
              <div className="body">
                {formatNumber(topicShares.publications)}
              </div>
            </div>
          </div>
          <div className="card-wrapper color3">
            <span className="card-content__icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
                  stroke="#8825EB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <div className="card-content">
              <div className="header">{t('Time period')}</div>
              <div className="body">
                {format(
                  parseISO(report.parameters.start_date + 'Z'),
                  'MMM yyyy',
                )}{' '}
                -{' '}
                {format(parseISO(report.parameters.end_date + 'Z'), 'MMM yyyy')}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="report-section">
        <h3>{t('Narratives')}</h3>
        <div className="topics">
          {topicShares?.objects?.map((topic, i) => {
            return (
              <div key={i} className={`topic color${i}`}>
                <div
                  className="header"
                  ref={(el) => (itemsRef.current[i] = el)}
                >
                  <div className="header__number">#{i + 1}</div>{' '}
                  <div className="header__title">{topic.name} </div>
                </div>
                <div className="body">
                  <h4 className="body-header">{t('Statistics')}</h4>

                  <div className="metrics">
                    <div className="card-content">
                      <div className={`metric impact-score color${i}`}>
                        <div className="metric-header">{t('Impact score')}</div>
                        <div className={`metric-body color${i}`}>
                          {formatNumber(topic.topic_share)}
                        </div>
                      </div>
                    </div>
                    <div className="card-content">
                      <div className={`metric color${i}`}>
                        <div className="metric-header">
                          {t('Total media stories')}
                        </div>
                        <div className={`metric-body color${i}`}>
                          {formatNumber(
                            topic.per_source?.WEB?.publications || 0,
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card-content">
                      <div className={`metric color${i}`}>
                        <div className="metric-header">{t('Tweets')}</div>
                        <div className={`metric-body color${i}`}>
                          {formatNumber(
                            topic.per_source?.TWITTER?.publications || 0,
                          )}
                          {/* {formatNumber(topic.impressions)} */}
                        </div>
                      </div>
                    </div>
                    <div className="card-content">
                      <div className={`metric color${i}`}>
                        <div className="metric-header">
                          {t('Average twitter engagement')}
                        </div>
                        <div className={`metric-body color${i}`}>
                          {formatNumberSignificant(topic.engagement)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="report-section">
        <h3 className="trend-chart-controlls">
          {t('Evolution')}
          <button
            type="button"
            className={totalEvolutionChart === 'topic_share' ? 'active' : ''}
            onClick={() => setTotalEvolutionChart('topic_share')}
          >
            <span>{t('Impact')}</span>
          </button>
          <button
            type="button"
            className={totalEvolutionChart === 'publications' ? 'active' : ''}
            onClick={() => setTotalEvolutionChart('publications')}
          >
            <span>{t('Publications')}</span>
          </button>
        </h3>

        <div className="card">
          <div className="card-header">{select}</div>
          <div className="card-body">
            <TrendChart
              rawdata={topicShares?.objects}
              metric={totalEvolutionChart}
              narrativeColor={narrativeColor}
            />
          </div>
        </div>
      </div>

      {topicTopStories?.objects?.length > 0 ? <div className="report-section">
        <h3>{t('Top stories (beta)')}</h3>
        <div className="topics">
          {topicTopStories?.objects?.map((topic, i) => {
            return (
              <div key={i} className={`topic color${i}`}>
                <div
                  className="header"
                  ref={(el) => (itemsRef.current[i] = el)}
                >
                  <div className="header__number">#{i + 1}</div>{' '}
                  <div className="header__title">{topic.name} </div>
                </div>
                <div className="body">
                  <table className="messages">
                    <thead>
                      <tr>
                        <td>{t('Message')}</td>
                        <td>{t('Index')}</td>
                      </tr>
                    </thead>
                    <tbody>
                      {topic.stories?.sort((a, b) => b.score - a.score).map((story) => {
                        return (
                          <tr key={story.id}>
                            <td className="message">{story.name}</td>
                            <td>{calculateStoryIndex(story.score)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
        </div>
      </div> : ''}

      {topicTopThreats?.objects?.length > 0 ? <div className="report-section">
        <h3>{t('Top threats (beta)')}</h3>
        <div className="topics">
          {topicTopThreats?.objects.map((topic, i) => {
            return (
              <div key={i} className={`topic color${i}`}>
                <div
                  className="header"
                  ref={(el) => (itemsRef.current[i] = el)}
                >
                  <div className="header__number">#{i + 1}</div>{' '}
                  <div className="header__title">{topic.name} </div>
                </div>
                <div className="body">
                  <table className="threats">
                    <thead>
                      <tr>
                        <td>{t('Message')}</td>
                        <td>{t('Index')}</td>
                        <td>{t('Type')}</td>
                      </tr>
                    </thead>
                    <tbody>
                      {topic.stories
                        ?.sort((a, b) => b.score - a.score)
                        .map((story) => {
                          return (
                            <tr key={story.id}>
                              <td
                                className="message"
                                onClick={() => setThreatsModal({ isActive: true, id: story.id, title: story.name })}
                              >
                                {story.name}
                              </td>
                              <td>{calculateStoryIndex(story.score)}</td>
                              <td>
                                {STORY_SUB_TYPE_MAP[story.story_sub_type].icon}
                                <span className="story-index title">
                                  {
                                    STORY_SUB_TYPE_MAP[story.story_sub_type]
                                      .title
                                  }
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
        </div>
      </div> : ''}

      {/* <div className="report-section">
        <h3>{t('Actors')}</h3>

        <div className="card">
          <div className="card-body">
            {topicSourcesStats ? <TreeChart treeData={treeData} /> : ''}
          </div>
        </div>
      </div> */}

      <ThreatsModal
        threatsModal={threatsModal}
        onChange={(bool) => setThreatsModal({ isActive: false, id: null, title: null })}
      />

      {/* <ExportReportPopup
        isVisible={exportModal.isActive}
        onClose={() => setExportModal({ isActive: false, id: null })}
        reportID={topicReportId}
      /> */}
    </div>
  );
}
