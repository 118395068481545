import { useTranslation } from 'react-i18next';

import '../../Filter/Filter.scss';


export const MessagesTabChooser = ({onChange, selected = []}) => {
  const { t } = useTranslation();

  const messagesTabOptions = [
    {value: 'messages', label: t('Messages')},
  ];

  const handleSelect = () => {
    if (messagesTabOptions.length !== selected.length) {
      onChange(messagesTabOptions.map(item => item.value))
    } else {
      onChange([])
    }
  }

  return(
    <div className='chooser'>
      <div className='control' onClick={() => handleSelect()}>{messagesTabOptions.length === selected.length ? t('Deselect all') : t('Select all')}</div>
      <ul>
        {messagesTabOptions.map(item => {
          return(
            <li key={item.value}>
              <input 
                id={`messagesTab_${item.value}`}
                type='checkbox'
                checked={selected.includes(item.value)}
                onChange={() => {
                  if (selected.includes(item.value)) {
                    onChange(selected.filter(v => v !== item.value)) 
                  } else {
                    onChange([...selected, item.value]) 
                  }
                }}
              />
              <label htmlFor={`messsagesTab_${item.value}`}>{item.label}</label>
            </li>
          )
        })}
        
      </ul>
    </div>
  )
}
