import { useEffect, useState, useContext } from "react";
import * as d3 from 'd3';
import { API } from "../../../API";
import { ShareAPI } from "../../../API";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BreakdownsContext } from "./contexts/BreakdownsContext";
import { getFlagEmoji } from "../../../utils/countries";
import {platformIconsMapping, platformNamesMapping} from '../../../utils/platforms';
import { BreakdownsContainer } from "../../../components/BreakdownsContainer/BreakdownsContainer";
import { LoaderSmall } from "../../../components/LoaderSmall/LoaderSmall";
import { decodeSourceName } from '../../../utils/decodeURI';
import { ReactComponent as VerifiedTwitter } from '../../SourcePage/assets/verifiedTwitter.svg';
import Tooltip from "../../../components/Tooltip/Tooltip";
import { cutString } from "../../../utils/cutString";
import styles from '../NarrativePage.module.scss';

const formatNumber = d3.format(",d");
const formatNumberSignificant = d3.format('.3~s',);
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
}

export const StateAffiliated = ({isShare, narrative, details, isClickable=true}) => {
  const { t } = useTranslation();
  const [stateAffiliated, setStateAffiliated] = useState(null);
  const [topAffiliated, setTopAffiliated] = useState(null);
  const {openModal, setTypesFilter, setSubtypesFilter, sourcesSubtypesFilter, sourcesTypesFilter, setAffiliationCountryId} = useContext(BreakdownsContext);

  const api = isShare ? ShareAPI : API;

  const entityTypes = {
    STATE_AGENCY: t('State agency'),
    GOVERNMENT_OFFICIAL: t('Government official'),
    STATE_OWNED_MEDIA: t('State owned media'),
    REPORTEDLY_STATE_AFFILIATED: t('Reportedly state affiliated'),
  };

  const getNarrativeAffiliated = () => {
    api.fetch('GET', `/API/v1/narratives/${narrative.id}/countries?state_affiliated=true`).then(
      data => setStateAffiliated(data)
    )
  }

  const getAffiliatedTop = () => {
    const urlParams = new URLSearchParams();
      urlParams.set('size', '5');
      urlParams.set('page', 1);
      urlParams.set('narrative_id', narrative.id);

      urlParams.set('sorting', '-audience');
      
      urlParams.set('state_affiliated', true);

      api.fetch('GET', `/API/v1/sources?${urlParams.toString()}`).then(
        (data) => {
          setTopAffiliated(data);
        },
      );
  }

  useEffect(() => {
    getNarrativeAffiliated()
    getAffiliatedTop()
  }, [narrative])

  const totalSources = stateAffiliated?.objects?.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0);
  const totalAudience = stateAffiliated?.objects?.reduce((accumulator, currentValue) => accumulator + currentValue.audience, 0);
  

  return(
    <BreakdownsContainer
      title='State-affiliated actors'
      details={details}
      onClick={() => {
        setTypesFilter({value: 'state_affiliated', label: 'State-affiliated', bool: true})
        openModal()
      }}
    >
     {stateAffiliated ? (stateAffiliated.objects?.length > 0
      ? <div className={styles.widgetRow}>
        <div className={styles.widgetSummary}>
          <h5>{t('Total actors')}</h5>
          <div> 
            <h2>{formatNumber(totalSources)}</h2>
            <p>{t('Total audience')}:</p>
            <p>{formatWithCustomGigaBillion(totalAudience)}</p>
          </div>
        </div>
        <div className={styles.widgetStats}>
          <h5>{t('Split by affiliation country')}</h5>
          <div className={styles.breakdownsListMain}>
            {stateAffiliated.objects.map((country, index) => {
              return(
                <div key={`affiliation_country_${index}`} className={styles.listItem}>
                  <div className={styles.info}>
                    {/* <span>{getFlagEmoji(country.code)}</span> */}
                    <span 
                      className={styles.title}
                      onClick={() => {
                       if (isClickable) {
                        setTypesFilter({value: 'state_affiliated', label: 'State-affiliated', bool: true})
                        setAffiliationCountryId(country.id)
                        openModal()
                       }
                      }}
                      >
                        {country.name}
                      </span>
                  </div>
                  <div className={styles.count}>{formatNumber(country.count)}</div>
                </div>
              )
            })}
          </div>
        </div>


        <div className={styles.widgetTop}>
          <h5>{t('Top state-affiliated actors')}</h5>
          {topAffiliated ? (
            <div className={styles.affiliationsWrapper}> 
                <div className={styles.breakdownsListMain}>
                  {topAffiliated.objects.map((source, index) => {
                    return(
                      <div key={`source_${index}`} className={styles.listItem}>
                        <div className={styles.info}>
                          <span>{platformIconsMapping[source.source_type]}</span>
                          <span className={styles.title}>
                          {isShare || !isClickable ? <Tooltip content={source.name} position='bottom'>{cutString(decodeSourceName(source.name), 30)}</Tooltip> 
                                    : <Link to={`/sources/${source.id}`}>
                                        <Tooltip content={source.name} position='bottom'>{cutString(decodeSourceName(source.name), 30)}</Tooltip>
                                        {source?.is_verified ? (
                                          <span className={styles.verified}>
                                              <VerifiedTwitter/>
                                          </span>
                                              ) : (
                                                ''
                                          )}
                                      </Link>}
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>

                <div className={styles.breakdownsListMain}>
                  {topAffiliated.objects.map((source, index) => {
                    return (
                      <div className={styles.details}>{entityTypes[source?.affiliations[0]?.entity_type]}</div>
                    )
                  })}
                </div>

                <div className={styles.breakdownsListMain}>
                  {topAffiliated.objects.map((source, index) => {
                    return (
                      <div className={styles.affilationCountry}>
                        {/* <span>{getFlagEmoji(source.affiliations[0]?.country.code)}</span> */}
                        <span>{source.affiliations[0]?.country.name}</span>
                      </div>
                    )
                  })}
                </div>

            </div>
          ) : (
            <div className={styles.widdgetLoader}> <LoaderSmall /> </div>
          )}
        </div>  
        
      </div> 
      : <div className={styles.noData}>{t('No affiliated actors identified')}</div>
    ) : <div className={styles.widdgetLoader}> <LoaderSmall /> </div> }

    </BreakdownsContainer>
  )
}

