import { useEffect, useMemo, useState } from 'react';
import DOMPurify from 'dompurify';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './ExpandableText.module.scss';

export function ExpandableText({ text, highlights, length = 100, textClassName = '', onExpand = () => {}, isLabel = false, showFullText = false}) {
  const [isExpanded, setIsExpanded] = useState(showFullText);
  const { t } = useTranslation();

  const newText = text.replace(/\n+(\s*\n+)?/g, '\n').split('\n');
  const sanitizedText = newText.map(item => DOMPurify.sanitize(item, { ALLOWED_TAGS: ['mark', 'span'],  ALLOWED_ATTR: ['class']}));

  const nerTypesList = [
    {value: 'KEYWORDS', label: 'Matched keywords'},
    {value: 'PERSON', label: 'Persons'},
    {value: 'ORGANIZATION', label: 'Organizations'},
    {value: 'LOCATION', label: 'Locations'}
  ]

  useEffect(() => {
    if(!highlights) {
      return
    }

    const keywords = document.querySelectorAll('.keywords-highlight');
    const ner = document.querySelectorAll('.ner-highlight');
    const person = document.querySelectorAll('.ner-highlight[data-ner-type="Person"]');
    const location = document.querySelectorAll('.ner-highlight[data-ner-type="Location"]');
    const organization = document.querySelectorAll('.ner-highlight[data-ner-type="Organization"]');
  

    if (highlights.includes('KEYWORDS')) {
      keywords.forEach(item => {
        item.classList.add('active')
      })
    } else {
      keywords.forEach(item => {
        item.classList.remove('active')
      })
    }

    if (highlights.includes('PERSON')) {
      person.forEach(item => {
        item.classList.add('active')
      })
    } else {
      person.forEach(item => {
        item.classList.remove('active')
      })
    }

    if (highlights.includes('LOCATION')) {
      location.forEach(item => {
        item.classList.add('active')
      })
    } else {
      location.forEach(item => {
        item.classList.remove('active')
      })
    }

    if (highlights.includes('ORGANIZATION')) {
      organization.forEach(item => {
        item.classList.add('active')
      })
    } else {
      organization.forEach(item => {
        item.classList.remove('active')
      })
    }

   }, [isExpanded, highlights, text])

    const cutString = (text, maxLength) => {
      let count = 0;
      let result = '';
  
      for (let i = 0; i < text.length; i++) {
        if (text[i] === '<') {
          while (text[i] !== '>') {
            result += text[i];
            i++;
          }
          result += text[i];
          continue;
        }
  
        result += text[i];
        count++;
  
        if (count === maxLength) {
          break;
        }
      }
  
      return result;
    };


  const clean = useMemo(
    // () => DOMPurify.sanitize(newText, { ALLOWED_TAGS: ['mark'] }),
    () => {
      return sanitizedText;
    },
    [sanitizedText],
  );

  const shorten = useMemo(
    () => cutString(DOMPurify.sanitize(text), length) 
        // .replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '')
        // .substring(0, 100)
        .trim(),
    [text],
  );

  return (
    <button
      type="button"
      className={styles.root}
      onClick={(e) => {
        e.stopPropagation();
        onExpand(!isExpanded);
        setIsExpanded(!isExpanded);
      }}
    >
      <span className={cn(styles.text, textClassName)}>
        {isExpanded ? (
        clean.map(item => <p dangerouslySetInnerHTML={{ __html: item }}></p>)
          // <span dangerouslySetInnerHTML={{ __html: clean }} />
        ) : (
          <p dangerouslySetInnerHTML={{ __html: shorten }}></p>
        )}

        {!isExpanded && text.length > length && '...'}
        {isLabel && text.length > length && (<span className={styles.label}>{isExpanded ? t('Show less') : t('Show more')}</span>)}
      </span>
    </button>
  );
}
