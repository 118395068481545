import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { CalendarContainer } from 'react-datepicker';
import Async from 'react-select/async';
import 'react-datepicker/dist/react-datepicker.css';
import { components } from 'react-select';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import { ReactComponent as NarrativeIcon } from '../NarrativeAddPage/assets/narrative.svg';
import { ReactComponent as ManualIcon } from '../NarrativeAddPage/assets/manual.svg';
import { ReactComponent as IncidentIcon } from '../NarrativeAddPage/assets/incident.svg';
import { ReactComponent as AnchorIcon } from '../../assets/anchor.svg';
import { ReactComponent as CloseIcon } from '../SearchPage/assets/close.svg';
import { ReactComponent as ImpactIcon } from '../NarrativeAddPage/assets/impact.svg';
import {ReactComponent as AIIcon} from '../../assets/ai.svg';
import FacebookIcon from '../../assets/facebook.svg';
import TelegramIcon from '../../assets/telegram.svg';
import VkontakteIcon from '../../assets/vkontakte.svg';
import TwitterIcon from '../../assets/twitter.svg';
import WebIcon from '../../assets/web.svg';
import OkIcon from '../../assets/odnoklassniki.svg';
import YoutubeIcon from '../../assets/youtube.svg';
import TikTokIcon from '../../assets/tiktok.svg';

import * as d3 from 'd3';
import { mapThreatsStatus } from '../../utils/threats';
import { API } from '../../API';
import { ampli } from "../../ampli";
import { useCurrentUser } from '../../contexts/CurrentUser';
import { QueryInput } from '../NarrativeAddPage/QueryInput/QueryInput';
import Switch from '../../components/Switch';
import { platformOptions } from '../../utils/platforms';
import { languageOptions, languageNamesMapping, keywordsNativeLanguageOptions } from '../../utils/languages';
import {
  contentTypesOptions,
  contentTypesMapping,
} from '../../utils/contentTypes';
import { sentimentNameMapping } from '../../utils/sentiment';
import { LoaderSmall } from '../../components/LoaderSmall/LoaderSmall';
import { RangeSlider } from '../../components/RangeSlider/RangeSlider';
import styles from './NarrativeEditPage.module.scss';

const formatNumberSignificant = d3.format('.3~s',);

const platformIconsMapping = {
  TELEGRAM: TelegramIcon,
  VK: VkontakteIcon,
  TWITTER: TwitterIcon,
  WEB: WebIcon,
  FACEBOOK: FacebookIcon,
  ADMIXER: WebIcon,
  OK: OkIcon,
  YOUTUBE: YoutubeIcon,
  TIKTOK: TikTokIcon,
}


export default function NarrativeEditPage({ isThreat = false }) {
  const { t } = useTranslation();
  let { narrativeId } = useParams();
  const [currentUser] = useCurrentUser();
  const workspaceId = parseInt(localStorage.getItem('workspace_id'));

  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const isCaseModerationEnabled = featureFlags?.includes('case_moderation');
  const showStoriesParameters = featureFlags?.includes('show_stories_parameters');
  const isAdvancedSyntax = workspaceConfig?.keywords_parser_class === 'Workspace16ESKeywordsQuery';
  const availableCaseTypes = workspaceConfig?.available_case_types || [];
  const aspectBasedSentimentAvailable = featureFlags?.includes('aspect_based_sentiment');

  const mapIoCType = {
    'SANCTIONS': {label: t('Sanctions'), value: 'SANCTIONS'},
    'DISINFORMATION': {label: t('Disinformation'), value: 'DISINFORMATION'},
    'HACKED_OR_STOLEN': {label: t('Hacked / Stolen'), value: 'HACKED_OR_STOLEN'},
    'INVOLVED_IN_INFLUENCE_OPERATIONS': {label: t('Influence operations'), value: 'INVOLVED_IN_INFLUENCE_OPERATIONS'},
  }

  const aspectBasedSentimentOptions = [
    {label: t('Sentiment towards a topic or narrative'), value: 'SENTIMENT_STATEMENT'},
    {label: t('Sentiment towards person'), value: 'SENTIMENT_PERSON'},
    {label: t('Sentiment towards organization'), value: 'SENTIMENT_ORGANIZATION'},
    {label: t('Sentiment towards country'), value: 'SENTIMENT_COUNTRY'},
    {label: t('Custom sentiment option'), value: 'SENTIMENT_CUSTOM'},
  ]

  const narrativeTypes = [
    {
      type: 'NARRATIVE',
      title: t('Narrative'),
      icon: <NarrativeIcon />,
      description: t(
        'A case that automatically collects all data for a certain topic based on your search query. Can contain both boolean and AI search. Is updated every 30 minutes.',
      ),
    },
    {
      type: 'MANUAL',
      title: t('Manual'),
      icon: <ManualIcon />,
      description: t(
        'An empty case to which you can manually add messages from search and other cases. Changing the type to “Manual” case type will reset your search settings.',
      ),
    },
    {
        type: 'INCIDENT',
        title: t('Incident'),
        icon: <IncidentIcon/>,
        description: t('A case that automatically collects all data for a certain topic based on provided exampels and similarity threshold. Is updated every 30 minutes. Changing the type "Narrative" to “Incident” case type will reset your search settings.')
    },
    {
        type: 'IMPACT_ASSESSMENT',
        title: t('Impact assessment'),
        icon: <ImpactIcon/>,
        description: t('A case to analyze public reaction to a particular media product.')
      },
  ];

  const [currentNarrativeType, setCurrentNarrativeType] = useState(null);

  let navigate = useNavigate();

  const [narrative, setNarrative] = useState(null);
  const [values, setValues] = useState({
    name: '',
    description: '',
  });
  const [errors, setErros] = useState({});

  const [sourceTypes, setSourceTypes] = useState([]);
  const [sourceGroups, setSourceGroups] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [applySupervisedClassifier, setApplySupervisedClassifier] =
    useState(false);
  const [disableFilters, setDisableFilters] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [refreshHistory, setrefreshHistory] = useState(true);
  const [examples, setExamples] = useState(['']);
  const [languages, setLanguages] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const [sources, setSources] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesTop, setCountriesTop] = useState([]);
  const [showBotsOnly, setShowBotsOnly] = useState(null);
  const [discreditations, setDiscreditations] = useState([]);
  const [affiliationAll, setAffliationAll] = useState(false);
  const [affiliationCountries, setAffiliationCountries] = useState([]);
  const [similarity_threshold, setSimilarityThreshold] = useState(null);
  const [defaultSimilarityThreshold, setDefaultSimilarityThreshold] = useState(0.5);
  const [isMultilang, setIsMultilang] = useState(false);
  const [nativeLang, setNativeLang] = useState(null);
  const [sentiment, setSentiment] = useState([]);
  const [reviewStatus, setReviewStatus] = useState(null);
  const [isValidQuery, setIsValidQuery] = useState(true);
  const [keywords, setKeywords] = useState(null);
  const keywordsRef = useRef(null);
  const queryInputRef = useRef(null);
  const [createStories, setCreateStories] = useState(false);
  const [storiesCandidatesMinThreshold, setStoriesCandidatesMinThreshold] = useState(0.8);
  const [storiesGroupingMaxDistance, setStoriesGroupingMaxDistance] = useState(0.64);
  const [useStoriesGroupingMaxDistance, setUseStoriesGroupingMaxDistance] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [contentLink, setContentLink] = useState(null);
  const [impactSentiment, setImpactSentiment] = useState(null);
  const [sentimentSubject, setSentimentSubject] = useState('');
  const [isAspectBasedSentimentEnabled, setIsAspectBasedSentimentEnabled] = useState(false);
  const [aspectBasedSentimentObject, setAspectBasedSentimentObject] = useState(null);
  const [aspectBasedSentimentSubject, setAspectBasedSentimentSubject] = useState('');

  useEffect(() => {
    if(Object.keys(errors).length > 0) {
      setIsLoading(false)
    }
  }, [errors])

  useEffect(() => {
    API.fetch('GET', '/API/v1/config/website_config').then((websiteConfig) => {
      if (!narrative?.parameters?.stories_candidates_min_threshold && websiteConfig?.stories_candidates_min_threshold) {
        setStoriesCandidatesMinThreshold(parseFloat(websiteConfig.stories_candidates_min_threshold));
      }

      if (!narrative?.parameters?.stories_grouping_max_distance && websiteConfig?.stories_grouping_max_distance) {
        setStoriesGroupingMaxDistance(parseFloat(websiteConfig.stories_grouping_max_distance));
      }

      if (workspaceConfig?.narratives_similarity_threshold) {
        setDefaultSimilarityThreshold(parseFloat(workspaceConfig?.narratives_similarity_threshold));
      } else if (websiteConfig?.narratives_similarity_threshold) {
        setDefaultSimilarityThreshold(parseFloat(websiteConfig.narratives_similarity_threshold));
      }
    });
  }, [narrative]);

  const reviewStatusOptions = [
    {
      value: 'PENDING',
      label: t('Pending'),
    },
    {
      value: 'ACCEPTED',
      label: t('Accepted'),
    },
    {
      value: 'DECLINED',
      label: t('Declined'),
    },
  ]

  useEffect(() => {
    if (examples.filter((e) => e.length > 0).length === 0 && similarity_threshold !== null) {
      setSimilarityThreshold(null)
    }
    if (examples.filter((e) => e.length > 0).length > 0 && similarity_threshold === null) {
      setSimilarityThreshold(defaultSimilarityThreshold)
    }
  }, [examples]);

  useEffect(() => {
    if (
      currentNarrativeType &&
      ['MANUAL', 'IMPACT_ASSESSMENT'].includes(narrativeTypes[currentNarrativeType].type)
    ) {
      setrefreshHistory(false);
    } else {
      setrefreshHistory(true);
    }
  }, [currentNarrativeType]);

  const sentimentOptions = [
    { value: 'POSITIVE', label: t('Positive') },
    { value: 'NEUTRAL', label: t('Neutral') },
    { value: 'NEGATIVE', label: t('Negative') },
  ];

  useEffect(() => {
    if (!isMultilang) {
      setNativeLang(null);
    }
  }, [isMultilang]);

  const onKeywordsChange = useCallback((value, isValid) => {
    keywordsRef.current = value;
    setKeywords(value)
    setIsValidQuery(isValid);
  }, []);

  const deleteExample = (e, index) => {
    e.preventDefault();
    setExamples((old) => {
      return [...old.filter((v, i) => i !== index)];
    });
  };
  const handleChange = (e) => {
    setValues((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      API.fetch('GET', `/API/v1/groups?q=${inputValue}`).then((data) => {
        resolve(
          data.objects
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((group) => {
              return { value: group.id, label: group.name };
            }),
        );
      });
    });

  const getSources = (inputValue, callback) => {
    return API.fetch('GET', `/API/v1/sources?q=${inputValue}&sorting=-audience`).then((data) => {
      callback(
        data.objects
          .map((source) => {
            let followers_cnt = source.audience;

            return {
              value: source.id,
              label: source.name,
              icon: platformIconsMapping[source.source_type] || WebIcon,
              followers_count: followers_cnt || 0,
            };
          }),
      );
    });
  };

  const { Option } = components;
  const IconOption = props => {
    return <Option {...props}>
      <img
          src={props.data.icon}
          style={{width: 15, position: "relative", top: 2, marginRight: 2}}
          alt={props.data.label}
      /> {props.data.label} <b>({formatNumberSignificant(props.data.followers_count)})</b>
    </Option>
  };

  const getAllCountries = useCallback(() => {
    API.fetch('GET', `/API/v1/countries?page=1&size=100`).then((data) => {
      setCountriesTop(
        data.objects.slice(0, 10).map((country) => {
          return { value: country.id, label: country.name };
        }),
      );
    });
  }, []);

  useEffect(getAllCountries, [getAllCountries]);

  const getCountries = (inputValue, callback) => {
    return API.fetch('GET', `/API/v1/countries?q=${inputValue}`).then(
      (data) => {
        callback(
          data.objects
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((country) => {
              return { value: country.id, label: country.name };
            }),
        );
      },
    );
  };

  let sourcesPromiseTimeout;
  const sourcesPromiseOptions = function (inputValue) {
    if (sourcesPromiseTimeout !== null) {
      clearTimeout(sourcesPromiseTimeout);
    }

    if (!inputValue) {
      return [];
    }

    return new Promise((resolve) => {
      sourcesPromiseTimeout = setTimeout(() => {
        getSources(inputValue, resolve);
      }, 1000);
    });
  };

  let countriesPromiseTimeout;
  const countriesPromiseOptions = function (inputValue) {
    if (countriesPromiseTimeout !== null) {
      clearTimeout(countriesPromiseTimeout);
    }

    if (!inputValue) {
      return [];
    }

    return new Promise((resolve) => {
      countriesPromiseTimeout = setTimeout(() => {
        getCountries(inputValue, resolve);
      }, 1000);
    });
  };

  const handleEdit = () => {
    setErros({});
    setIsLoading(true);
    const headers = { 'Content-Type': 'application/json' };

    if(isThreat && narrative?.workspace_id !== currentUser?.workspace?.id) {
      headers['workspace-id'] = narrative?.workspace_id;
    }

    let parameters = null;
    let type = narrativeTypes[currentNarrativeType].type;

    if(narrativeTypes[currentNarrativeType].type === 'INCIDENT') {
      parameters = {
        keywords: [],
        examples: disableFilters ? [] : examples.filter((e) => e.length > 0),
        start_date: startDate ? format(startDate, 'yyyy-LL-dd') : null,
        end_date: endDate ? format(endDate, 'yyyy-LL-dd') : null,
        apply_supervised_classifier: applySupervisedClassifier,
        source_types: [],
        source_group_ids: [],
        disable_filters: disableFilters,
        languages: [],
        source_ids: [],
        source_origin_country_ids: [],
        translate_keywords_query: false,
        keywords_query_origin_language: '',
        sentiment: [],
        content_types: [],
        similarity_threshold: similarity_threshold
          ? similarity_threshold
          : null,
        create_stories: createStories,
        stories_candidates_min_threshold: createStories ? storiesCandidatesMinThreshold : null,
        stories_grouping_max_distance: createStories && useStoriesGroupingMaxDistance ? storiesGroupingMaxDistance: null,
        source_state_affiliated_all_countries: false,
        source_state_affiliated_country_ids: [],
        source_discreditated_entity_types: [],
        source_bots: false,
      };
    }

    if (narrativeTypes[currentNarrativeType].type === 'NARRATIVE') {

      if (disableFilters && sourceGroups.length < 1) {
        setErros((oldValues) => ({
          ...oldValues,
          sourceGroups: t('At least one source must be selected'),
        }));
        return;
      }

      if (isMultilang && nativeLang === null) {
        setErros((oldValues) => ({
          ...oldValues,
          language: t(
            'You must select a native language for multilanguage search',
          ),
        }));
        return;
      }

      if (
        examples.filter((e) => e.length > 0).length === 0 &&
        similarity_threshold
      ) {
        setErros((oldValues) => ({
          ...oldValues,
          similarity_threshold: t(
            "You can't set similarity threshold and do not provide any examples",
          ),
        }));
        return;
      }

      parameters = {
        keywords: disableFilters ? [] : keywords ? [keywords] : [],
        examples: disableFilters ? [] : examples.filter((e) => e.length > 0),
        start_date: startDate ? format(startDate, 'yyyy-LL-dd') : null,
        end_date: endDate ? format(endDate, 'yyyy-LL-dd') : null,
        apply_supervised_classifier: applySupervisedClassifier,
        source_types: sourceTypes.map((v) => v.value),
        source_group_ids: sourceGroups.map((v) => v.value),
        disable_filters: disableFilters,
        languages: disableFilters ? [] : languages.map((v) => v.value),
        source_ids: disableFilters ? [] : sources.map((v) => v.value),
        source_origin_country_ids: disableFilters
          ? []
          : countries.map((v) => v.value),
        translate_keywords_query: isMultilang,
        keywords_query_origin_language: nativeLang ? nativeLang.value : '',
        sentiment: sentiment.map((score) => score.value),
        content_types: disableFilters ? [] : contentTypes.map((v) => v.value),
        similarity_threshold:similarity_threshold
        ? similarity_threshold
        : null,
        create_stories: createStories,
        stories_candidates_min_threshold: createStories ? storiesCandidatesMinThreshold : null,
        stories_grouping_max_distance: createStories && useStoriesGroupingMaxDistance ? storiesGroupingMaxDistance: null,
        source_state_affiliated_all_countries: affiliationAll,
        source_state_affiliated_country_ids: affiliationAll ? [] : affiliationCountries.map(v => v.value),
        source_discreditated_entity_types: discreditations.map((v) => v.value),
        source_bots: showBotsOnly,
        aspect_based_sentiment: isAspectBasedSentimentEnabled,
        aspect_based_sentiment_object: isAspectBasedSentimentEnabled ? aspectBasedSentimentObject.value : null,
        aspect_based_sentiment_subject: isAspectBasedSentimentEnabled ? aspectBasedSentimentSubject : null,
      };
    }

    API.fetch('PATCH', `/API/v1/narratives/${narrativeId}`, headers, {
      name: values.name,
      description: values.description,
      type: type === 'MANUAL' ? 'NARRATIVE' : type,
      is_active: isActive,
      is_manual: narrativeTypes[currentNarrativeType].type === 'MANUAL',
      is_threat: isThreat,
      is_created_by_ucbi_reporter: narrative?.is_created_by_ucbi_reporter,
      incident_type: narrative?.incident_type || null,
      topic: narrative?.topic || null,
      geo_scope: narrative?.geo_scope || null,
      workspace_ids: narrative?.workspace_ids || null,
      review_status: reviewStatus.value,
      ...( type === 'IMPACT_ASSESSMENT' ? {} : {parameters: parameters})
    }).then((data) => {
      ampli.track({
        event_type: 'Edit case',
        event_properties: {
          user_id: currentUser?.id,
          workspace_id: currentUser?.workspace_id,
          narrative_id: narrativeId,
        }
      });
      setIsLoading(false);
      if(isThreat) {
        navigate(`/threats/${data.id}`);
      } else {
        navigate(`/narratives/${data.id}`);
      }
    })
    .catch((e) => {
      setIsLoading(false);
      setErros({...errors, general: e.message})
    });
  };

  const handleCopyFromEdit = () => {
    setErros({});
    setIsLoading(true);

    const headers = { 'Content-Type': 'application/json' };

    if(isThreat && narrative?.workspace_id !== currentUser?.workspace?.id) {
      headers['workspace-id'] = narrative?.workspace_id;
    }

    let parameters = null;
    let type = narrativeTypes[currentNarrativeType].type;

    if(narrativeTypes[currentNarrativeType].type === 'INCIDENT') {
      parameters = {
        keywords: [],
        examples: disableFilters ? [] : examples.filter((e) => e.length > 0),
        start_date: startDate ? format(startDate, 'yyyy-LL-dd') : null,
        end_date: endDate ? format(endDate, 'yyyy-LL-dd') : null,
        apply_supervised_classifier: applySupervisedClassifier,
        source_types: [],
        source_group_ids: [],
        disable_filters: disableFilters,
        languages: [],
        source_ids: [],
        source_origin_country_ids: [],
        translate_keywords_query: false,
        keywords_query_origin_language: '',
        sentiment: [],
        content_types: [],
        similarity_threshold: similarity_threshold
          ? similarity_threshold
          : null,
        create_stories: createStories,
        stories_candidates_min_threshold: createStories ? storiesCandidatesMinThreshold : null,
        stories_grouping_max_distance: createStories && useStoriesGroupingMaxDistance ? storiesGroupingMaxDistance: null,
        source_state_affiliated_all_countries: false,
        source_state_affiliated_country_ids: [],
        source_discreditated_entity_types: [],
        source_bots: false,
      };
    }

    if (narrativeTypes[currentNarrativeType].type === 'NARRATIVE') {

      if (disableFilters && sourceGroups.length < 1) {
        setErros((oldValues) => ({
          ...oldValues,
          sourceGroups: t('At least one source must be selected'),
        }));
        return;
      }

      if (isMultilang && nativeLang === null) {
        setErros((oldValues) => ({
          ...oldValues,
          language: t(
            'You must select a native language for multilanguage search',
          ),
        }));
        return;
      }

      if (
        examples.filter((e) => e.length > 0).length === 0 &&
        similarity_threshold
      ) {
        setErros((oldValues) => ({
          ...oldValues,
          similarity_threshold: t(
            "You can't set similarity threshold and do not provide any examples",
          ),
        }));
        return;
      }

      parameters = {
        keywords: keywords ? [keywords] : [],
        examples: examples.filter((e) => e.length > 0),
        start_date: startDate ? format(startDate, 'yyyy-LL-dd') : null,
        end_date: endDate ? format(endDate, 'yyyy-LL-dd') : null,
        apply_supervised_classifier: applySupervisedClassifier,
        source_types: sourceTypes.map((v) => v.value),
        source_group_ids: sourceGroups.map((v) => v.value),
        languages: disableFilters ? [] : languages.map((v) => v.value),
        source_ids: disableFilters ? [] : sources.map((v) => v.value),
        source_origin_country_ids: disableFilters
          ? []
          : countries.map((v) => v.value),
        translate_keywords_query: isMultilang,
        keywords_query_origin_language: nativeLang ? nativeLang.value : '',
        sentiment: sentiment.map((score) => score.value),
        content_types: disableFilters ? [] : contentTypes.map((v) => v.value),
        similarity_threshold: similarity_threshold
          ? similarity_threshold
          : null,
        create_stories: createStories,
        stories_candidates_min_threshold: createStories ? storiesCandidatesMinThreshold : null,
        stories_grouping_max_distance: createStories && useStoriesGroupingMaxDistance ? storiesGroupingMaxDistance: null,
        source_state_affiliated_all_countries: affiliationAll,
        source_state_affiliated_country_ids: affiliationAll ? [] : affiliationCountries.map(v => v.value),
        source_discreditated_entity_types: discreditations.map((v) => v.value),
        source_bots: showBotsOnly,
        aspect_based_sentiment: isAspectBasedSentimentEnabled,
        aspect_based_sentiment_object: isAspectBasedSentimentEnabled ? aspectBasedSentimentObject.value : null,
        aspect_based_sentiment_subject: isAspectBasedSentimentEnabled ? aspectBasedSentimentSubject : null,
      };
    }

    API.fetch('POST', '/API/v1/narratives', headers, {
      name: values.name,
      description: values.description,
      type: type === 'MANUAL' ? 'NARRATIVE' : type,
      is_manual: narrativeTypes[currentNarrativeType].type === 'MANUAL',
      review_status: reviewStatus.value,
      is_active: isActive,
      is_threat: isThreat,
      is_created_by_ucbi_reporter: narrative?.is_created_by_ucbi_reporter,
      incident_type: narrative?.incident_type || null,
      topic: narrative?.topic || null,
      geo_scope: narrative?.geo_scope || null,
      workspace_ids: narrative?.workspace_ids || null,
      on_create: {
        from_narrative_id: narrativeId,
      },
      parameters: parameters,
    }).then((data) => {
      ampli.track({
        event_type: 'Edit case',
        event_properties: {
          user_id: currentUser?.id,
          workspace_id: currentUser?.workspace_id,
          narrative_id: data.id,
        }
      });

      API.fetch('DELETE', `/API/v1/narratives/${narrativeId}`).then(() => {
        setIsLoading(false);
        if(isThreat) {
          navigate(`/threats/${data.id}`);
        } else {
          navigate(`/narratives/${data.id}`);
        }
      });
    }).catch((e) => {
      setIsLoading(false);
      setErros({...errors, general: e.message})
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if(narrativeTypes[currentNarrativeType].type === 'NARRATIVE') {
      if((keywords?.trim().length === 0 || keywords === null) && sources?.length === 0 && (!disableFilters && examples?.filter((e) => e.length > 0).length === 0) && sourceGroups.length === 0) {
        setErros((oldValues) => ({
          ...oldValues,
          general: `To save the changes, you must provide at least one of the following: add keywords, add examples, choose at least one actor or an actor group.`,
        }));
        return;
      }
    }

    if (!refreshHistory) {
      handleEdit();
    } else {
      handleCopyFromEdit();
    }
  };

  useEffect(() => {
    API.fetch('GET', `/API/v1/narratives/${narrativeId}`).then((data) => {
      setCurrentNarrativeType(data.is_manual ? 1 : narrativeTypes.findIndex((type) => type.type === data.type));
      setValues({ name: data.name, description: data.description });
      setIsActive(data.is_active);
      setNarrative(data);

    });
  }, [narrativeId]);

  useEffect(() => {
    if (narrative?.parameters?.keywords) {
      const keywordsValue = String(narrative?.parameters?.keywords);
      keywordsRef.current = keywordsValue;
      queryInputRef?.current?.set(keywordsValue);
      setKeywords(keywordsValue);
    }

    if (narrative?.parameters?.examples) {
      setExamples(narrative?.parameters?.examples);
    }

    if (narrative?.parameters?.languages) {
      setLanguages(
        narrative?.parameters?.languages.map((v) => ({
          value: v,
          label: languageNamesMapping[v],
        })),
      );
    }

    if (narrative?.parameters?.similarity_threshold) {
      setSimilarityThreshold(narrative?.parameters?.similarity_threshold);
    }

    if (narrative?.parameters?.content_types) {
      setContentTypes(
        narrative?.parameters?.content_types.map((v) => ({
          value: v,
          label: contentTypesMapping[v],
        })),
      );
    }

    if (narrative?.parameters?.start_date) {
      setStartDate(new Date(narrative?.parameters?.start_date));
    }

    if (narrative?.parameters?.end_date) {
      setEndDate(new Date(narrative?.parameters?.end_date));
    }

    if (narrative?.parameters?.apply_supervised_classifier !== undefined) {
      setApplySupervisedClassifier(
        narrative?.parameters?.apply_supervised_classifier,
      );
    }
    if (narrative?.parameters?.source_types) {
      setSourceTypes(
        narrative?.parameters?.source_types.map((v) => ({
          value: v,
          label: v,
        })),
      );
    }

    if (narrative?.parameters?.source_group_ids) {
      const promises = narrative?.parameters?.source_group_ids.map(
        (source_group_id) =>
          API.fetch('GET', `/API/v1/groups/${source_group_id}`),
      );

      Promise.all(promises).then((results) =>
        setSourceGroups(
          results
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((v) => ({ value: v.id, label: v.name })),
        ),
      );
    }

    if (narrative?.parameters?.source_ids) {
      const promises = narrative?.parameters?.source_ids.map((source_id) =>
        API.fetch('GET', `/API/v1/sources/${source_id}`),
      );

      Promise.all(promises).then((results) =>
        setSources(
          results
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((v) => ({ value: v.id, label: v.name })),
        ),
      );
    }

    if (narrative?.parameters?.source_origin_country_ids) {
      const promises = narrative?.parameters?.source_origin_country_ids.map(
        (country_id) => API.fetch('GET', `/API/v1/countries/${country_id}`),
      );

      Promise.all(promises).then((results) =>
        setCountries(
          results
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((v) => ({ value: v.id, label: v.name })),
        ),
      );
    }

    if (narrative?.parameters?.disable_filters !== undefined) {
      setDisableFilters(narrative?.parameters?.disable_filters);
    }

    if (narrative?.parameters?.translate_keywords_query === true) {
      setIsMultilang(true);
      setNativeLang({
        value: narrative?.parameters?.keywords_query_origin_language,
        label:
          languageNamesMapping[
            narrative?.parameters?.keywords_query_origin_language
          ],
      });
    }

    if (narrative?.review_status) {
      setReviewStatus({value: narrative.review_status, label: t(mapThreatsStatus[narrative.review_status])});
    }

    if (
      narrative?.parameters?.sentiment !== null &&
      narrative?.parameters?.sentiment.length > 0
    ) {
      const sentimentList = narrative?.parameters?.sentiment.map(
        (sentiment) => {
          return { value: sentiment, label: sentimentNameMapping[sentiment] };
        },
      );
      setSentiment(sentimentList);
    }

    setCreateStories(narrative?.parameters?.create_stories);

    if (narrative?.parameters?.stories_candidates_min_threshold) {
      setStoriesCandidatesMinThreshold(narrative?.parameters?.stories_candidates_min_threshold);
    }

    if (narrative?.parameters?.stories_grouping_max_distance) {
      setStoriesGroupingMaxDistance(narrative?.parameters?.stories_grouping_max_distance);
      setUseStoriesGroupingMaxDistance(true);
    }

    if (narrative?.parameters?.source_bots === true) {
      setShowBotsOnly(true)
    }

    if (narrative?.parameters?.source_state_affiliated_all_countries === true) {
      setAffliationAll(true)
    }

    if (narrative?.parameters?.source_discreditated_entity_types) {
      let selected = narrative?.parameters?.source_discreditated_entity_types.map(option => mapIoCType[option])
      setDiscreditations(selected)
    }

    if (narrative?.parameters?.source_state_affiliated_country_ids) {
      const promises = narrative?.parameters?.source_state_affiliated_country_ids.map(
        (country_id) => API.fetch('GET', `/API/v1/countries/${country_id}`),
      );

      Promise.all(promises).then((results) =>
        setAffiliationCountries(
          results
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((v) => ({ value: v.id, label: v.name })),
        ),
      );
    }

    if (narrative?.parameters?.content_link) {
      setContentLink(narrative?.parameters?.content_link);
    }

    if (narrative?.parameters?.sentiment_method) {
      let selected = impactSentimentOptions.filter(option => option.value === narrative?.parameters?.sentiment_method)

      setImpactSentiment(selected[0]);
    }

    if (narrative?.parameters?.sentiment_subject) {
      setSentimentSubject(narrative?.parameters?.sentiment_subject)
    }

    if (narrative?.parameters?.aspect_based_sentiment) {
      setIsAspectBasedSentimentEnabled(narrative?.parameters?.aspect_based_sentiment);
    }

    if (narrative?.parameters?.aspect_based_sentiment_object) {
      let selected = aspectBasedSentimentOptions.filter(option => option.value === narrative?.parameters?.aspect_based_sentiment_object)

      setAspectBasedSentimentObject(selected[0]);
    }

    if (narrative?.parameters?.aspect_based_sentiment_subject) {
      setAspectBasedSentimentSubject(narrative?.parameters?.aspect_based_sentiment_subject)
    }

  }, [narrative]);


  const container = ({ children }) => {
    return (
      <div className="react-datepicker custom-datepicker">
        <CalendarContainer className={styles.container}>
          <div style={{ position: 'relative' }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  const impactSentimentOptions = [
    {label: t('Sentiment towards media product'), value: 'SENTIMENT_VIDEO'},
    {label: t('Sentiment towards a topic or narrative'), value: 'SENTIMENT_STATEMENT'},
    {label: t('Sentiment towards person'), value: 'SENTIMENT_PERSON'},
    {label: t('Sentiment towards organization'), value: 'SENTIMENT_ORGANIZATION'},
  ]

  const IoCTypeList = [
    {label: t('Sanctions'), value: 'SANCTIONS'},
    {label: t('Disinformation'), value: 'DISINFORMATION'},
    {label: t('Hacked / Stolen'), value: 'HACKED_OR_STOLEN'},
    {label: t('Influence operations'), value: 'INVOLVED_IN_INFLUENCE_OPERATIONS'},
  ]

  const sentimentAnalysisTitle = {
    'SENTIMENT_STATEMENT': {title: t('Topic/Narrative'), hint: t('Give a short name of a topic, narrative or opinion to measure reaction to. E.g.: ceasefire in Gaza, Covid vaccines can cause death, sending Western troops to Ukraine.')},
    'SENTIMENT_PERSON': {title: t('Person'), hint: t('Write a name and minimum necessary content that helps identify a person in content. E.g.: Vladimir Putin, leader of PWC Wagner')},
    'SENTIMENT_ORGANIZATION': {title: t('Organization'), hint: t('Write a name of an organization. E.g.: UN, EU')},
    'SENTIMENT_COUNTRY': {title: t('Country'), hint: t('Write a name of a country. E.g.: Russia, USA')},
    'SENTIMENT_CUSTOM': {title: t('Custom sentiment option'), hint: t('Write a custom keyword or phrase to measure sentiment towards. E.g.: "I love my country"')},
  }

  const handleSelectAllAffiliations = () => {
    setAffliationAll(!affiliationAll);
    if(!affiliationAll === true) {
      setAffiliationCountries([{value: 'all', label: t('Select all')}]);
    } else {
      setAffiliationCountries([])
    }
  }

  return (
    <div className={styles.root}>
      <div className="page-header">
        <div className="breadcrumb">
          <span>
            <Link to="/narratives">{t('Cases')}</Link>
          </span>
          <span>
            <Link to={`/narratives/${narrativeId}`}>{values.name}</Link>
          </span>
          <span>{t('Edit')}</span>
        </div>
      </div>
      <div className="form-wrapper">
        <form method="post" onSubmit={handleSubmit}>
          <div className={errors.name ? 'form-element error' : 'form-element'}>
            <label htmlFor="name">{t('Name')}&#42;</label>
            <input
              className=""
              id="name"
              name="name"
              type="text"
              value={values.name}
              onChange={handleChange}
              required
            />
            <div className="error-message">{errors.name}</div>
          </div>
          {currentUser.type !== 'UNDERPRIVILEGED' ? (
            <div className="form-element">
              <label htmlFor="case-active" className={styles.label}>
                {t('Active')}
              </label>
              <Switch
                id="case-active"
                value={isActive}
                onChange={(v) => {
                  setIsActive(v);
                  if (!v) setrefreshHistory(false);
                }}
              />
            </div>
          ) : ''}

          {isCaseModerationEnabled && currentUser.type !== 'UNDERPRIVILEGED' ? (
            <div className={errors.reviewStatus ? 'form-element error' : 'form-element'}>
              <label>{t('Status')}</label>
              <Select
                className="react-select-container"
                classNamePrefix="status"
                isMulti={false}
                placeholder={t('Status')}
                options={reviewStatusOptions}
                name="reviewStatus"
                value={reviewStatus}
                onChange={(value) => setReviewStatus(value)}
              />
            </div>
          ) : ''}

          <div
            className={
              errors.description ? 'form-element error' : 'form-element'
            }
          >
            <label>{t('Description')}</label>
            <textarea
              className=""
              id="description"
              name="description"
              value={values.description}
              onChange={handleChange}
            ></textarea>
            <div className="error-message">{errors.description}</div>
          </div>
          {currentNarrativeType !== null ? (
            <div className={styles.narrativeTypeContainer}>
              <h3>{t('Suggested case type')}</h3>
              <div className={styles.typesWrapper}>
                {narrativeTypes.map((type, index) => {
                  if (!availableCaseTypes.includes(type.type)) {
                    return null;
                  }

                  return (
                    <div
                      className={
                        currentNarrativeType === index
                          ? `${styles.narrativeType} ${styles.active}`
                          : styles.narrativeType
                      }
                      onClick={() => setCurrentNarrativeType(index)}
                      key={`narrative_type_${index}`}
                    >
                      <span className={styles.typeIconWrapper}>
                        {type.icon}
                      </span>
                      {type.title}
                    </div>
                  );
                })}
              </div>
              <div className={styles.typeInformationWrapper}>
                <div className={styles.infoIcon}>
                  {' '}
                  <AnchorIcon />{' '}
                </div>
                <div className={styles.typeInformation}>
                  {/* <div className={styles.typeInformationTitle}>{t(`What's going on here`)}</div> */}
                  <div className={styles.typeDescription}>
                    {narrativeTypes[currentNarrativeType].description}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <LoaderSmall />
          )}
          {narrativeTypes[currentNarrativeType]?.type === 'NARRATIVE' || narrativeTypes[currentNarrativeType]?.type === 'INCIDENT' ? (
            <div>
              <div
                className={
                  errors.startDate ? 'form-element error' : 'form-element'
                }
              >
                <label>{t('Start date')}&#42;</label>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  calendarContainer={container}
                  maxDate={endDate}
                  required
                  showDisabledMonthNavigation
                />
                <div className="error-message">{errors.startDate}</div>
              </div>

              <div
                className={
                  errors.endDate ? 'form-element error' : 'form-element'
                }
              >
                <label>{t('End date')}</label>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  calendarContainer={container}
                  minDate={startDate}
                  showDisabledMonthNavigation
                />
                <div className="error-message">{errors.endDate}</div>
              </div>
              {narrativeTypes[currentNarrativeType]?.type !== 'INCIDENT' ? <> <div className="form-element">
                <label htmlFor="case-multilang" className={styles.label}>
                  {t('Multilanguage search')}
                </label>
                <Switch
                  id="case-multilang"
                  value={isMultilang}
                  onChange={setIsMultilang}
                />
              </div>
              <div
                className={
                  errors.language ? 'form-element error' : 'form-element'
                }
              >
                <label>{t('Select native language')}</label>
                <Select
                  className="react-select-container"
                  classNamePrefix="native-lang"
                  isDisabled={!isMultilang}
                  isMulti={false}
                  placeholder={t('Language')}
                  options={keywordsNativeLanguageOptions.map(option => {return {value: option.value, label: t(option.label)}})}
                  name="languages"
                  value={nativeLang}
                  // menuIsOpen={true}
                  onChange={(value) => setNativeLang(value)}
                />
                <div className="error-message">{errors.language}</div>
              </div>
              <div className="form-element">
                <label>
                  {t('Keywords')}{' '}
                  <span className={styles.subLabel}>
                    {t(
                      'e.g. Zelensky, president AND terrorism AND NOT Biden, Putin',
                    )}
                    <span className={styles.help}>
                      <InfoIcon data-tooltip-id="word-forms-tooltip" />
                    </span>
                  </span>
                  <Tooltip
                    className="tooltip"
                    id="word-forms-tooltip"
                    type="info"
                    style={{ width: '600px' }}
                  >
                    <div>
                      <p>
                        {t(
                          `We automatically build all possible word forms for each search term  (e.g. black cat → black cats, чорний кіт → чорного кота, чорних котів, …).`,
                        )}
                      </p>
                      <p>
                        {t(`To limit results to an exact search term, use the `)}{' '}
                        <b>{t(` exact match operator ""`)}</b>
                        {t(`, e.g “cat”.`)}
                      </p>
                      <br />
                      <p>
                        {t(`Search supports`)} <b>{t('logical operators')}</b>:{' '}
                        {t('AND (&), OR (|), NOT (!).')}
                      </p>
                      <p>
                        {t(`Use`)} <b>{t(`parenthesis ()`)}</b>{' '}
                        {t(
                          `to group keywords. E.g.: (black cat OR white dog) AND NOT grey elephant`,
                        )}
                      </p>
                      <br />
                      <p>
                        {t(`Use`)} <b> *</b>{' '}
                        {t(
                          `to match any number of characters at the end of a word, e.g. philosoph* → philosophy, philosopher`,
                        )}
                      </p>
                      <br />
                      <p>
                        {t(`Use`)} <b>{t(` [{number}]`)}</b>{' '}
                        {t(
                          `notation to match a fixed number of characters at the end of a word, e.g. ap[3] → apple, apply`,
                        )}
                      </p>
                    </div>
                  </Tooltip>
                </label>
                <QueryInput
                  isAdvanced={isAdvancedSyntax}
                  ref={queryInputRef}
                  onChange={onKeywordsChange}
                  disabled={disableFilters}
                  keywords={keywords}
                />
              </div>
              <div
                className={
                  errors.sourceTypes ? 'form-element error' : 'form-element'
                }
              >
                <label>{t('Platforms')}</label>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isMulti={true}
                  placeholder={t('Platforms')}
                  options={platformOptions}
                  name="sourceTypes"
                  value={sourceTypes}
                  onChange={(values) => setSourceTypes(values)}
                />
                <div className="error-message">{errors.sourceTypes}</div>
              </div>
              <div
                className={
                  errors.languages ? 'form-element error' : 'form-element'
                }
              >
                <label>{t('Languages')}</label>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isMulti={true}
                  placeholder={t('Languages')}
                  options={languageOptions.map(option => {return {value: option.value, label: t(option.label)}})}
                  name="languages"
                  value={languages}
                  onChange={(values) => setLanguages(values)}
                />
                <div className="error-message">{errors.languages}</div>
              </div>
              <div
                className={
                  errors.contentTypes ? 'form-element error' : 'form-element'
                }
              >
                <label>{t('Content types')}</label>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isMulti={true}
                  placeholder={t('Content types')}
                  options={contentTypesOptions}
                  name="contentTypes"
                  value={contentTypes}
                  onChange={(values) => setContentTypes(values)}
                />
                <div className="error-message">{errors.contentTypes}</div>
              </div>
              <div
                className={
                  errors.sources ? 'form-element error' : 'form-element'
                }
              >
                <label>{t('Actors')}</label>
                <Async
                  className="react-select-container"
                  classNamePrefix="react-select"
                  cacheOptions
                  defaultOptions
                  isMulti={true}
                  loadOptions={sourcesPromiseOptions}
                  components={{ Option: IconOption }}
                  placeholder={t('Please enter at least 1 symbol to search')}
                  noOptionsMessage={() => t('Please enter at least 1 symbol')}
                  loadingMessage={() => t('Loading...')}
                  name="sources"
                  value={sources}
                  onChange={(values) => setSources(values)}
                />
                <div className="error-message">{errors.sources}</div>
              </div>
              <div
                className={
                  errors.countries ? 'form-element error' : 'form-element'
                }
              >
                <label>{t('Actors origin countries')}</label>
                <Async
                  className="react-select-container"
                  classNamePrefix="react-select"
                  cacheOptions
                  defaultOptions={countriesTop}
                  isMulti={true}
                  loadOptions={countriesPromiseOptions}
                  placeholder={t('Please enter at least 1 symbol to search')}
                  noOptionsMessage={() => t('Please enter at least 1 symbol')}
                  loadingMessage={() => t('Loading...')}
                  name="countries"
                  value={countries}
                  onChange={(values) => setCountries(values)}
                />
                <div className="error-message">{errors.countries}</div>
              </div>

              <div
                className={
                  errors.affiliationCountries ? 'form-element error' : 'form-element'
                }
              >
                <label>{t('State-affiliated actors')}</label>
                <Async
                  className="react-select-container"
                  classNamePrefix="react-select"
                  cacheOptions
                  defaultOptions={countriesTop}
                  isMulti={true}
                  loadOptions={countriesPromiseOptions}
                  placeholder={t('Please enter at least 1 symbol to search')}
                  noOptionsMessage={() => t('Please enter at least 1 symbol')}
                  loadingMessage={() => t('Loading...')}
                  name="affiliationCountries"
                  value={affiliationCountries}
                  onChange={(values) => setAffiliationCountries(values)}
                  isDisabled={affiliationAll}
                />
                <div className="form-element" style={{marginTop: '1rem'}}>
                  <label htmlFor='all-affiliations' className={styles.label}>{t('Select all affiliation countries')}</label>
                  <Switch id='all-affiliations' value={affiliationAll} onChange={() => handleSelectAllAffiliations()}/>
                </div>
                <div className="error-message">{errors.affiliationCountries}</div>
              </div>

              <div
                className={
                  errors.discreditations ? 'form-element error' : 'form-element'
                }
              >
                <label>{t('Compromised accounts')}</label>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isMulti={true}
                  placeholder={t('Compromised accounts')}
                  options={IoCTypeList}
                  name="discreditations"
                  value={discreditations}
                  onChange={(values) => setDiscreditations(values)}
                />
                <div className="error-message">{errors.discreditations}</div>
              </div>

              <div className="form-element">
                <label htmlFor='all_bots' className={styles.label}>{t('Inauthentic behavior')}</label>
                <Switch id='all-bots' value={showBotsOnly} onChange={() => setShowBotsOnly(!showBotsOnly)}/>
              </div>

              <div
                className={
                  errors.sourceGroups ? 'form-element error' : 'form-element'
                }
              >
                <label>{t('Actor groups')}</label>
                <Async
                  className="react-select-container"
                  classNamePrefix="react-select"
                  cacheOptions
                  defaultOptions
                  isMulti={true}
                  loadOptions={promiseOptions}
                  placeholder={t('Select')}
                  name="narratives"
                  value={sourceGroups}
                  onChange={(values) => setSourceGroups(values)}
                />
                <div className="error-message">{errors.sourceGroups}</div>
              </div>
              <div
                className={
                  errors.sentiment ? 'form-element error' : 'form-element'
                }
              >
                <label>{t('Sentiment')}</label>
                <Select
                  className="select-filter"
                  classNamePrefix="select-filter"
                  isMulti={true}
                  placeholder={t('Sentiment')}
                  options={sentimentOptions}
                  name="sentiment"
                  value={sentiment}
                  onChange={(values) => setSentiment(values)}
                />
                <div className="error-message">{errors.sentiment}</div>
              </div>
              <div className="form-element">
                <label htmlFor="case-disable-filters" className={styles.label}>
                  {t('Disable filters')}
                </label>
                <Switch
                  id="case-disable-filters"
                  value={disableFilters}
                  onChange={setDisableFilters}
                />
              </div> </> : ''}
              <div className="form-element">
                <label>{t('Examples')}</label>
                {examples.map((example, i) => (
                  <div key={i} className={styles.example}>
                    <textarea
                      className=""
                      onChange={(e) =>
                        setExamples((old) => {
                          return [
                            ...old.map((v, idx) =>
                              i === idx ? e.target.value : v,
                            ),
                          ];
                        })
                      }
                      value={example}
                      disabled={disableFilters}
                    ></textarea>
                    <div>
                      <button
                        className="btn-danger"
                        disabled={disableFilters}
                        type='button'
                        onClick={(e) => deleteExample(e,i)}
                      >
                        <i className="las la-trash"></i>
                      </button>
                    </div>
                  </div>
                ))}
                <div className="add-example">
                  <button
                    type="button"
                    className="secondary"
                    disabled={disableFilters}
                    onClick={() => setExamples((old) => [...old, ''])}
                  >
                    <i className="las la-plus"></i>
                    {t('Add another example')}
                  </button>
                </div>
              </div>
              
              <div
                className={
                  errors.similarity_threshold
                    ? 'form-element error'
                    : 'form-element'
                }
              >
                <label htmlFor="similarity_threshold">
                  {t('Similarity threshold')}
                </label>
                <RangeSlider 
                  min={0.3} 
                  max={0.9} 
                  step={0.05}
                  data={similarity_threshold}
                  onChange={(value) => setSimilarityThreshold(value)}
                  disabled={examples.filter((e) => e.length > 0).length === 0}
                />
                <div className="error-message">
                  {errors.similarity_threshold}
                </div>
              </div>

              {narrativeTypes[currentNarrativeType].type === 'NARRATIVE' && aspectBasedSentimentAvailable ? <>
              <div className="form-element">
                <label htmlFor='is-aspect-based-sentiment-enabled' className={styles.label}>
                  <span className={styles.labelIcon}><AIIcon/></span>
                  {t('Apply aspect based sentiment to case')}
                  <div className={styles.subLabel}>{t('Calculate sentiment towards an entity or event.')}</div>
                </label>
                <Switch
                  id='is-aspect-based-sentiment-enabled'
                  value={isAspectBasedSentimentEnabled}
                  onChange={setIsAspectBasedSentimentEnabled}
                />
              </div>

              {isAspectBasedSentimentEnabled ? <>
                <div className={errors.aspectBasedSentimentObject ? 'form-element error' : 'form-element'}>
                  <label htmlFor='aspect-based-sentiment-object'>{t('Sentiment analysis')}</label>
                  <Select
                    className="select-filter"
                    classNamePrefix="select-filter"
                    placeholder={t('Choose context for sentiment analysis')}
                    options={aspectBasedSentimentOptions}
                    id="aspect-based-sentiment-object"
                    name="aspect-based-sentiment-object"
                    value={aspectBasedSentimentObject}
                    onChange={(value) => setAspectBasedSentimentObject(value)}
                    menuPlacement='auto'
                  />
                  <div className="error-message">{errors.aspectBasedSentimentObject}</div>
                </div>

                {aspectBasedSentimentObject ? <div className={errors.aspectBasedSentimentSubject ? 'form-element error' : 'form-element'}>
                  <label htmlFor='aspect-based-sentiment-subject'>
                    {sentimentAnalysisTitle[aspectBasedSentimentObject?.value]?.title}
                    <span className={styles.subLabel}> {sentimentAnalysisTitle[aspectBasedSentimentObject?.value]?.hint}</span>
                  </label>
                  <input
                    className=""
                    id="aspect-based-sentiment-subject"
                    name="aspect-based-sentiment-subject"
                    type="text"
                    value={aspectBasedSentimentSubject}
                    onChange={(e) => setAspectBasedSentimentSubject(e.target.value)}
                    required={isAspectBasedSentimentEnabled}
                  />
                  <div className="error-message">{errors.aspectBasedSentimentSubject}</div>
                </div> : ''}
              </> : ''}
            </> : ''}

              {narrativeTypes[currentNarrativeType].type === 'NARRATIVE' ? <div>
                <div className="form-element">
                  <label htmlFor='create-stories' className={styles.label}>
                    <span className={styles.labelIcon}><AIIcon/></span>
                    {t('Create stories from case')}
                    <div className={styles.subLabel}>{t('Group messages into key topics.')}</div>
                  </label>
                  <Switch
                    id='create-stories'
                    value={createStories}
                    onChange={setCreateStories}
                  />
                </div>

                <div className="form-element">
                  <label htmlFor="case-refresh" className={styles.label}>
                    {t('Refresh whole case history')}
                  </label>
                  <Switch
                    id="case-refresh"
                    value={refreshHistory}
                    onChange={(value) => setrefreshHistory(value)}
                  />
                </div>

                {showStoriesParameters ? <div>
                  <div className={errors.storiesCandidatesMinThreshold ? 'form-element error' : 'form-element'}>
                    <label htmlFor="stories_candidates_min_threshold">{t('Candidates min threshold')}</label>
                    <RangeSlider
                      min={0.05}
                      max={1}
                      step={0.05}
                      data={storiesCandidatesMinThreshold}
                      onChange={(value) => setStoriesCandidatesMinThreshold(value)}
                      disabled={!createStories}
                    />
                    <div className="error-message">{errors.storiesCandidatesMinThreshold}</div>
                  </div>

                  <div className="form-element">
                    <label htmlFor='use-max-grouping-distance' className={styles.label}>
                      {t('Use max grouping distance')}
                    </label>
                    <Switch
                      id='use-max-grouping-distance'
                      value={createStories && useStoriesGroupingMaxDistance}
                      onChange={setUseStoriesGroupingMaxDistance}
                      disabled={!createStories}
                    />
                  </div>

                  <div className={errors.storiesGroupingMaxDistance ? 'form-element error' : 'form-element'}>
                  <label htmlFor="stories_grouping_max_distance">{t('Grouping max distance')}</label>
                  <RangeSlider
                    min={0.05}
                    max={1}
                    step={0.05}
                    data={storiesGroupingMaxDistance}
                    onChange={(value) => setStoriesGroupingMaxDistance(value)}
                    disabled={!useStoriesGroupingMaxDistance || !createStories}
                  />
                  <div className="error-message">{errors.storiesGroupingMaxDistance}</div>
                </div>
                </div> : ''}
              </div> : ''}
            </div>
          ) : (
            ''
          )}

          {narrativeTypes[currentNarrativeType]?.type === 'IMPACT_ASSESSMENT'
            ? <>
                <div className={errors.name ? 'form-element error' : 'form-element'}>
                  <label htmlFor="name">{t('Link to media product')}&#42;</label>
                  <input
                    className=""
                    id="contentLink"
                    name="contentLink"
                    type="url"
                    onChange={e => setContentLink(e.target.value)}
                    value={contentLink}
                    disabled={true}
                  />
                  <div className="error-message">{errors.contentLink}</div>
                </div>

                <div
                  className={
                    errors.sentiment ? 'form-element error' : 'form-element'
                  }
                >
                  <label>{t('Sentiment analysis')}</label>
                  <Select
                    className="select-filter"
                    classNamePrefix="select-filter"
                    placeholder={t('Sentiment')}
                    options={impactSentimentOptions}
                    name="impact-sentiment"
                    value={impactSentiment}
                    onChange={(value) => setImpactSentiment(value)}
                    isDisabled={true}
                    menuPlacement='auto'
                  />
                  <div className="error-message">{errors.impactSentiment}</div>
                </div>

                {impactSentiment && ['SENTIMENT_STATEMENT', 'SENTIMENT_PERSON', 'SENTIMENT_ORGANIZATION'].includes(impactSentiment?.value) ? <div
                  className={
                    errors.sourceGroups ? 'form-element error' : 'form-element'
                  }
                >
                  <label>
                    {sentimentAnalysisTitle[impactSentiment?.value].title}
                    <span className={styles.subLabel}> {sentimentAnalysisTitle[impactSentiment?.value].hint}</span>
                  </label>
                  <input
                    className=""
                    id="sentiment-subject"
                    name="sentiment-subject"
                    type="text"
                    value={sentimentSubject}
                    disabled={true}
                  />
                  <div className="error-message">{errors.sentimentCase}</div>
                </div> : ''}
              </>
            : ''}

          <div className="form-element">
            <button
              className="new-button"
              type="submit"
              disabled={!isValidQuery || isLoading}
            >
              {t('Save')}
            </button>

            {!isValidQuery && (
              <span className={styles.errorDescription}>
                {t('Fix the keywords query')}
              </span>
            )}

{errors.general ? <div className={styles.errorMessage}>{t(errors.general)} <span onClick={() => setErros({...errors, general: null})}> <CloseIcon/> </span></div> : ''}
          </div>
        </form>
      </div>
    </div>
  );
}
