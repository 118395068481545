import './index.css';

export default function Switch({ value, id, onChange }) {
  return (
    <label className="switch">
      <input
        type="checkbox"
        id={id}
        checked={value ? value : false}
        onChange={(e) => onChange(!value)}
      />
      <span className="slider round"></span>
    </label>
  );
}
