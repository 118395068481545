import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import styles from './SummaryPanel.module.scss';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
};

export const SummaryCardSmall = ({ title, data }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.summarySmallCard}>
      <div className={styles.summarySmallCardTitle}>{t(title)}</div>
      <div className={styles.summarySmallCardData}>
        {formatWithCustomGigaBillion(data)}
      </div>
    </div>
  );
};
