import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import Async from 'react-select/async';
import DatePicker from 'react-datepicker';
import { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { API } from '../../API';
import './ReportAddPage.scss';

export function ReportAddPage() {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [values, setValues] = useState({
    name: '',
  });
  const [narrativeIDs, setNarrativeIDs] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  function removeTimezoneAndSetMidnight(dateString) {
    const date = format(dateString, 'yyyy-LL-dd') + ' 00:00:00'
    return date;
  }

  const handleChange = (e) => {
    setValues((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      API.fetch('GET', `/API/v1/narratives?q=${inputValue}`).then((data) => {
        resolve(
          data.objects.map((narrative) => {
            return { value: narrative.id, label: narrative.name };
          }),
        );
      });
    });

  const handleSubmit = (e) => {
    e.preventDefault();

    API.fetch('POST', `/API/v1/reports`, null, {
      name: values.name,
      parameters: {
        narrative_ids: narrativeIDs,
        start_date: startDate ? removeTimezoneAndSetMidnight(startDate) : null,
        end_date: endDate ? removeTimezoneAndSetMidnight(endDate) : null,
      },
    }).then((data) => {
      navigate('/reports');
    });
  };

  const container = ({ children }) => {
    return (
      <div className='react-datepicker custom-datepicker'>
        <CalendarContainer>
          <div style={{ position: "relative" }}>{children}</div>
        </CalendarContainer>
      </div>
    )
  }

  return (
    <div>
      <div className="page-header">
        <div className="breadcrumb">
          <span>
            <Link to="/reports">{t('Reports')}</Link>
          </span>
          <span>{t('New')}</span>
        </div>
      </div>
      <div className="form-wrapper">
        <form method="post" onSubmit={handleSubmit}>
          <div className="form-element">
            <label htmlFor="name">{t('Name')}</label>
            <input
              className=""
              id="name"
              name="name"
              type="text"
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-element">
            <label>{t('Narratives')}</label>
            <Async
              className="react-select-container"
              classNamePrefix="react-select"
              cacheOptions
              defaultOptions
              isMulti={true}
              placeholder={t('Select')}
              loadOptions={promiseOptions}
              name="narratives"
              onChange={(values) => setNarrativeIDs(values.map((v) => v.value))}
            />
          </div>
          <div className="form-element">
            <label>{t('Start date')}</label>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              calendarContainer={container}
              maxDate={endDate}
              showDisabledMonthNavigation
            />
          </div>

          <div className="form-element">
            <label>{t('End date')}</label>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              calendarContainer={container}
              minDate={startDate}
              showDisabledMonthNavigation
            />
          </div>

          <div className="form-element">
            <div className="submit-wrapper">
              <input type="submit" value={t('Create')} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
