import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Switch from '../../components/Switch';
import { useCurrentUser } from '../../contexts/CurrentUser';

import styles from './UserAddPage.module.scss';

import { API } from '../../API';

export function UserAddPage() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [currentUser] = useCurrentUser();

  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const [type, setType] = useState({ value: 'STANDARD', label: t('Standard') });
  const [userLanguage, setUserLanguage] = useState({ value: 'EN', label: 'English'});
  const [error, setError] = useState(null);

  const types = [
    { value: 'UNDERPRIVILEGED', label: t('Underprivileged') },
    { value: 'STANDARD', label: t('Standard') },
    { value: 'MODERATOR', label: t('Moderator') },
  ];

  const languageOptions = [
    {value: 'EN', label: 'English'},
    {value: 'UK', label: 'Ukrainian'}
  ]

  useEffect(() => {
    if (!currentUser.is_admin) {
      navigate('/');
    }
  }, []);

  const handleChange = (e) => {
    setValues((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);

    API.fetch('POST', `/API/v1/users`, null, {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      type: type.value,
      is_admin: isAdmin,
      language: userLanguage.value,
    }).then((data) => {
      navigate('/users');
    }).catch(e => {
      setError(e.message)
    })
  };

  return (
    <div className={styles.root}>
      <div className="page-header">
        <div className="breadcrumb">
          <span>
            <Link to="/users">{t('Users')}</Link>
          </span>
          <span>{t('New')}</span>
        </div>
      </div>
      <div className="form-wrapper">
        <form method="post" onSubmit={handleSubmit}>
          <div className="form-element">
            <label htmlFor="name">{t('First name')}</label>
            <input
              className=""
              id="first_name"
              name="first_name"
              type="text"
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-element">
            <label htmlFor="name">{t('Last name')}</label>
            <input
              className=""
              id="last_name"
              name="last_name"
              type="text"
              onChange={handleChange}
              required
            />
          </div>

          <div className={error ? 'form-element error' : 'form-element'}>
            <label htmlFor="name">{t('Email')}</label>
            <input
              className=""
              id="email"
              name="email"
              type="text"
              onChange={handleChange}
              required
            />
            <div className="error-message">{t(error)}</div>
          </div>

          <div className="form-element">
            <label htmlFor="name">{t('Password')}</label>
            <input
              className=""
              id="password"
              name="password"
              type="password"
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-element">
            <label htmlFor="language">{t('System language')}</label>
            <Select
              className="chart-select"
              onChange={(data) => {
                setUserLanguage(data);
              }}
              value={userLanguage}
              options={languageOptions}
            />
          </div>

          <div className="form-element">
            <label htmlFor="name">{t('User type')}</label>
            <Select
              className="chart-select"
              onChange={(data) => {
                setType(data);
              }}
              value={type}
              options={types}
            />
          </div>

          <div className="form-element">
            <label htmlFor="case-active" className={styles.label}>
              {t('Admin')}
            </label>
            <Switch id="case-active" value={isAdmin} onChange={setIsAdmin} />
          </div>

          {error ? <div></div> : ''}

          <div className="form-element">
            <div className="submit-wrapper">
              <input type="submit" value={t('Create')} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
