import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Async from 'react-select/async';
import { API } from '../../API';
import { useCurrentUser } from '../../contexts/CurrentUser';
import Switch from '../../components/Switch';
import { Loader } from '../../components/Loader/Loader';
import { ReactComponent as CloseIcon } from '../SearchPage/assets/close.svg';

import styles from './SourceEditPage.module.scss';


export const SourceEditPage = () => {
  const { t } = useTranslation();
  let { sourceId } = useParams();
  const [currentUser] = useCurrentUser();

  let navigate = useNavigate();
  const [source, setSource] = useState(null);
  const [errors, setErrors] = useState({});

  const [name, setName] = useState('');
  const [sourceURL, setSourceURL] = useState('');
  const [country, setCountry] = useState('');
  const [countriesTop, setCountriesTop] = useState('');
  const [audience, setAudience] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [inauthenticBehavior, setInauthenticBehavior] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const fetchSource = useCallback(() => {
    API.fetch('GET', `/API/v1/sources/${sourceId}`).then(data => {
      let followers_cnt = data.audience;
      let countryOption = data.origin_country === null ? {value: null, label: t('Not selected')} : {value: data.origin_country?.id, label: data.origin_country?.name};
      let isBot = data.is_bot || data.inauthentic_behavior ? true : false;

      setSource(data);
      setName(data.name);
      setSourceURL(data.url);
      setCountry(countryOption);
      setAudience(followers_cnt);
      setIsVerified(data.is_verified);
      setInauthenticBehavior(isBot);
      
    })
    .catch(error => {
      setErrors(state => ({...state, general: error.message}))
    });
  }, [sourceId]);

  useEffect(() => {
    fetchSource()
  }, [fetchSource]);

  const getAllCountries = useCallback(() => {
    API.fetch('GET', `/API/v1/countries`).then((data) => {
      let topCountries = data.objects.slice(0, 10).map((country) => {
        return { value: country.id, label: country.name };
      });

      topCountries.unshift({value: null, label: t('Not selected')})

      setCountriesTop(topCountries);
    });
  }, []);


  useEffect(getAllCountries, [getAllCountries]);

  const getCountries = (inputValue, callback) => {
    return API.fetch('GET', `/API/v1/countries?q=${inputValue}`).then(
      (data) => {
        callback(
          data.objects
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((country) => {
              return { value: country.id, label: country.name };
            }),
        );
      },
    );
  };

  let countriesPromiseTimeout;
  const countriesPromiseOptions = function (inputValue) {
    if (countriesPromiseTimeout !== null) {
      clearTimeout(countriesPromiseTimeout);
    }

    if (!inputValue) {
      return [];
    }

    return new Promise((resolve) => {
      countriesPromiseTimeout = setTimeout(() => {
        getCountries(inputValue, resolve);
      }, 1000);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let followers_cnt = source.audience;
    let body;

    if(audience == followers_cnt) {
      body = {
        name: name,
        url: sourceURL,
        origin_country_id: country.value,
        is_bot:inauthenticBehavior,
        inauthentic_behavior: inauthenticBehavior,
        is_verified: isVerified,
      }
    } else {
      body = {
        name: name,
        url: sourceURL,
        origin_country_id: country.value,
        audience: audience || null,
        is_bot:inauthenticBehavior,
        inauthentic_behavior: inauthenticBehavior,
        is_verified: isVerified,
      }
    }


    API.fetch('PATCH', `/API/v1/sources/${sourceId}`, null, body).then(data => {
      setIsLoading(false);
      navigate(`/sources/${sourceId}`)
    }).catch(error => {
      setIsLoading(false);
      setErrors(errors => ({...errors, general: error.message}))
    })
  }

  if(!currentUser?.is_super_admin) {
    navigate(`/sources`)
  }

  if(!sourceId || !source) {
    return <Loader/>
  }

  return(
    <div className={styles.root}>
      <div className="page-header">
        <div className="breadcrumb">
          <span>
            <Link to="/sources">{t('Actors')}</Link>
          </span>
          <span>
            <Link to={`/sources/${sourceId}`}>{source.name}</Link>
          </span>
          <span>{t('Edit')}</span>
        </div>
      </div>
      <div className="form-wrapper">
        <form onSubmit={handleSubmit}>
          <div className={errors.name ? 'form-element error' : 'form-element'}>
            <label htmlFor="name" className={styles.label}>{t('Name')}&#42;</label>
            <input
              className=""
              id="name"
              name="name"
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
            <div className="error-message">{errors.name}</div>
          </div>
          <div className={errors.sourceURL ? 'form-element error' : 'form-element'}>
            <label htmlFor="name" className={styles.label}>{t('URL')}&#42;</label>
            <input
              className=""
              id="sourceURL"
              name="sourceURL"
              type="url"
              value={sourceURL}
              onChange={e => setSourceURL(e.target.value)}
              required
            />
            <div className="error-message">{errors.sourceCountry}</div>
          </div>
          <div className={errors.audience ? 'form-element error' : 'form-element'}>
            <label htmlFor="name" className={styles.label}>{t('Audience')}</label>
            <input
              className=""
              id="audience"
              name="audience"
              type="number"
              min={0}
              value={audience}
              onChange={e => setAudience(e.target.value)}
            />
            <div className="error-message">{errors.audience}</div>
          </div>
          <div className={errors.sourceCountry ? 'form-element error' : 'form-element'}>
            <label htmlFor="name" className={styles.label}>{t('Origin country')}</label>
            <Async
              className="react-select-container"
              classNamePrefix="react-select"
              cacheOptions
              defaultOptions={countriesTop}
              loadOptions={countriesPromiseOptions}
              placeholder={t('Please enter at least 1 symbol to search')}
              noOptionsMessage={() => t('Please enter at least 1 symbol')}
              loadingMessage={() => t('Loading...')}
              name="countries"
              value={country}
              onChange={(value) => setCountry(value)}
            />
            <div className="error-message">{errors.sourceCountry}</div>
          </div>

          <div className="form-element">
            <label htmlFor='verification' className={styles.label}>
              {t('Verification')}
            </label>
            <Switch
              id='verification'
              value={isVerified}
              onChange={setIsVerified}
            />
          </div>
          <div className="form-element">
            <label htmlFor='inauthentic' className={styles.label}>
              {t('Inauthentic behavior')}
            </label>
            <Switch
              id='inauthentic'
              value={inauthenticBehavior}
              onChange={setInauthenticBehavior}
            />
          </div>

          <div className="form-element">
            <button
              className="new-button"
              type="submit"
              disabled={isLoading}
            >
              {t('Save')}
            </button>

            {errors.general 
              ? <div className={styles.errorMessage}>
                  {t(errors.general)}
                  <span onClick={() => setErrors({...errors, general: null})}>
                    <CloseIcon/>
                  </span>
                </div>
              : ''}
          </div>
        </form>
      </div>
    </div>
  )
}

