import AsyncCreatableSelect from 'react-select/async-creatable';
import { useCallback, useEffect, useState,} from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../API';
import Modal from "../Modal";
import { LoaderSmall } from '../LoaderSmall/LoaderSmall';
import { ReactComponent as PlusIcon } from './assets/plus.svg';

import styles from './AddToSourceGroupModal.module.scss';


export const AddToSourceGroupModal = ({isOpen, allMessages=null, messages=null, onChange, isSearch=false, deduplication=false}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [group, setGroup] = useState(null);
  const [options, setOptions] = useState(null);
  const [error, setError] = useState();
  const [narrativeSelect, setNarrativeSelect] = useState(false);
  const [sourcesList, setSourcesList] = useState(null);
  
  const messagesList = Object.keys(messages);
  
  const userInstruction = <div className={styles.userNote}>
    {t(`Start typing an actor group name to add actors to an existing one or create a new group by typing its name and pressing the 'Create New Group' button.`)}
  </div>

  const getSources = useCallback(() => {
    if (messages === null) {
      return
    }

    if (isSearch === true) {
      const messagesSet = new Set(messagesList);
      let sourcesIds = [];
      allMessages.forEach(message => {
        if (messagesSet.has(message.id)) {
          sourcesIds.push(message.source.id);
        }
    });
      setSourcesList(sourcesIds)
    } else {

      const urlParams = new URLSearchParams();
      messagesList?.forEach(message => urlParams.append('ids', message))

      API.fetch('GET', `/API/v1/messages?${urlParams.toString()}`).then((data) => {
        let sources = data?.objects?.map(message => message.source_id);
        setSourcesList(sources)
      })

    }
    

  }, [])

  useEffect(getSources, [getSources]);

  const getGroupsOptions = useCallback(() => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', '20');
    urlParams.set('page', 1);

    API.fetch('GET', `/API/v1/groups?${urlParams.toString()}`).then(data => {
      let newOptions = data.objects.map(group => {return {label: group.name, value: group.id}});
      setOptions(newOptions)
    })
  }, [])

  useEffect(getGroupsOptions, [getGroupsOptions]);

  const getGroups = (inputValue, callback) => {
    const urlParams = new URLSearchParams();
    urlParams.set('q', inputValue);

    return API.fetch('GET', `/API/v1/groups?${urlParams.toString()}`).then((data) => {
      callback(
        data.objects
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((group) => {
            return {value: group.id, label: group.name};
          })
      );
    });
  };

  const handleCreate = (inputvalue) => {
      setIsLoading(true);
      API.fetch('POST', `/API/v1/groups`, null, {
        name: inputvalue,
      }).then(data => {
        let newOption = {value: data.id, label: data.name};
        setIsLoading(false);
        setOptions(prev => [...prev, newOption])
        setGroup(newOption)
      })
      .catch(e => {
        // setError(e.message);
        setError(t('Something went wrong. Please, try again...'));
        setIsLoading(false);
      });
  }

  const handleAddToSourceGroup = () => {
    if(!messages) {
      setError('There are no selected messages')
      return
    }
    let link = `/API/v1/groups/${group?.value}/sources`;

    let body = {
      source_ids: sourcesList,
    };
    
    API.fetch('POST', link, null, body).then().catch(e => {
      setError(t('Something went wrong. Please, try again...'));
    });
  }

  useEffect(() => {
    if(options) {
      const container = document.querySelector('#message-to-narrative-modal');
      if(narrativeSelect === true ) {
        let content = document.querySelector(".addToNarrativeModal__menu");
        if(container.offsetHeight < content.offsetHeight) {
          container.style.height=container.offsetHeight + content.offsetHeight + "px";
        }
      } else {
        container.style.height = ' ';
      }
    }
 }, [narrativeSelect])


   
  return (
      <Modal
        isVisible={isOpen}
        title={t('Save actors to an actor group')}
        className={styles.addToNarrativeModal}
        content={
          options ? <div id='message-to-narrative-modal'>
            {userInstruction}
            <label>{t('Actor group name')}</label>
            <AsyncCreatableSelect
              cacheOptions
              defaultOptions={options}
              value={group}
              loadOptions={getGroups}
              isClearable
              isDisabled={isLoading}
              isLoading={isLoading}
              loadingMessage={() => t('Loading...')}
              placeholder={t('Start typing an existing group name or create a new one')}
              onChange={(newValue) => setGroup(newValue)}
              onCreateOption={handleCreate}
              onMenuOpen={() => setNarrativeSelect(true)}
              onMenuClose={() => setNarrativeSelect(false)}
              menuIsOpen={narrativeSelect}
              className='addToNarrativeModal'
              classNamePrefix="addToNarrativeModal"
              formatCreateLabel={(inputValue) => <span className={styles.create}><PlusIcon/>{t('Create a new actor group')} "{inputValue}"</span>}
              createOptionPosition='first'
            />
            {error ? <div className={styles.error}>{error}</div> : ''}
          </div> : <LoaderSmall/>
        }
        footer={
          <>
            <button
              className="btn-primary"
              onClick={() => {
                handleAddToSourceGroup();
                setGroup(null);
                setSourcesList(null);
                setError(null);
                onChange(false);
              }}
              
            >
              {t('Save')}
            </button>
            <button
              className="btn-reset"
              onClick={() => {
                setGroup(null);
                setSourcesList(null);
                setError(null);
                onChange(false);
              }}
            >
              {t('Cancel')}
            </button>
          </>
        }
        onClose={() => {
          onChange(false)
          setSourcesList(null);
          setGroup(null);
          setError(null);
        }
        }
      />
  )
}


