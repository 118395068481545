import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import { Paginator } from '../../../components/Paginator/Paginator';
import { API } from '../../../API';
import { Tabs } from '../../../components/Tabs/Tabs';
import { SourcesTable } from './SourcesTable';
import { MessagesTable } from './MessagesTable';

import styles from './ThreatsModal.module.scss';

const sortingFieldNames = {
  DATE: 'date_publicated',
  SOURCE: 'source',
  MESSAGE: 'text',
  VIEWS: 'impressions',
  REACTIONS: 'engagement',
};

export const ThreatsModal = ({ threatsModal, onChange }) => {
  const { t } = useTranslation();
  const [tablistIndex, setTablistIndex] = useState(0);
  const [messages, setMessages] = useState(null);
  const [sources, setSources] = useState(null);
  const [messagesSorting, setMessagesSorting] = useState(null);
  const [sourcesSorting, setSourcesSorting] = useState(null);
  const storyID = threatsModal.id;
  const storyTitle = threatsModal.title;

  // const page = searchParams.get('page') || 1;

  const fetchMessages = useCallback((storyID, messagesSorting, page = 1) => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', '20');
    urlParams.set('page', page);

    const sortingQuery = messagesSorting
      ? `${messagesSorting.isAscending ? '' : '-'}${messagesSorting.fieldName}`
      : null;

    if (messagesSorting) {
      urlParams.set('sorting', sortingQuery);
    }

    if (!storyID) {
      return
    }
    urlParams.set('story_id', storyID);

    API.fetch('GET', `/API/v1/messages?${urlParams.toString()}`).then(
      (data) => {
        setMessages(data);
      },
    );
  }, []);

  useEffect(() => {
    fetchMessages(storyID, messagesSorting);
  }, [storyID, messagesSorting]);

  const fetchSources = useCallback((storyID, sourcesSorting, page = 1) => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', '20');
    urlParams.set('page', page);

    const sortingQuery = sourcesSorting
      ? `${sourcesSorting.isAscending ? '' : '-'}${sourcesSorting.fieldName}`
      : null;

    if (sourcesSorting) {
      urlParams.set('q', sortingQuery);
    }

    if (!storyID) {
      return
    }
    urlParams.set('story_id', storyID);

    setSources(null);

    API.fetch('GET', `/API/v1/sources?${urlParams.toString()}`).then((data) => {
      setSources(data);
    });
  }, []);

  useEffect(() => {
    fetchSources(storyID, sourcesSorting);
  }, [storyID, sourcesSorting]);

  const handleMessagesSortingClick = (fieldName) => {
    const isAscending =
      messagesSorting && messagesSorting.fieldName === fieldName
        ? !messagesSorting.isAscending
        : true;

    setMessagesSorting({
      isAscending,
      fieldName: fieldName,
    });
  };

  const handleSourcesSortingClick = (fieldName) => {
    const isAscending =
      sourcesSorting && sourcesSorting.fieldName === fieldName
        ? !sourcesSorting.isAscending
        : true;

    setSourcesSorting({
      isAscending,
      fieldName: fieldName,
    });
  };

  const tabslist = [
    {
      title: t('Actors'),
      data: sources,
      onPageChange: (newPage) => fetchSources(sourcesSorting, newPage),
      content: (
        <SourcesTable
          sources={sources}
          sorting={sourcesSorting}
          // type={typesSelect.props.value.value}
          handleSourceSortingClick={(fieldname) =>
            handleSourcesSortingClick(fieldname)
          }
        />
      ),
    },
    {
      title: t('Content'),
      data: messages,
      onPageChange: (newPage) => fetchMessages(messagesSorting, newPage),
      content: (
        <MessagesTable
          messages={messages}
          sorting={messagesSorting}
          handleMessagesSortingClick={(fieldname) =>
            handleMessagesSortingClick(fieldname)
          }
        />
      ),
    },
  ];

  return (
    <Modal
      isVisible={threatsModal.isActive}
      title={storyTitle}
      content={
        <>
          {/* <div className={styles.modalSelects}>
            {select}
            {typesSelect}
          </div> */}
          <div className="modalControls">
            <Tabs
              tabslist={tabslist}
              heading=""
              onIndexChange={(index) => setTablistIndex(index)}
            />
          </div>
        </>
      }
      footer={
        <>
          <Paginator
            size={20}
            page={tabslist[tablistIndex]?.data?.page}
            total={tabslist[tablistIndex]?.data?.total}
            onPageChange={(newPage) => {
              tabslist[tablistIndex]?.onPageChange(newPage);
            }}
          />
        </>
      }
      onClose={() => {
        onChange(false);
      }}
      className={styles.threatsModal}
    />
  );
};
