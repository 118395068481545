import { useMemo, useEffect, useState, useContext, useRef } from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../components/Loader/Loader';
import styles from '../../NarrativePage.module.scss';

export const Description = ({ narrative, isShare }) => {
  const { t } = useTranslation();

  const getThreatDescription = (threat) => {
    if (!threat?.is_public) {
      return threat?.description;
    }

    let public_description;
    if (
      localStorage.getItem('i18nextLng') === 'ua-UK' ||
      localStorage.getItem('i18nextLng') === 'ru-RU' ||
      localStorage.getItem('i18nextLng') === 'ru' ||
      localStorage.getItem('i18nextLng') === 'uk'
    ) {
      public_description = threat?.public_description_uk;
    } else {
      public_description = threat?.public_description_en;
    }

    return public_description ?? threat?.description;
  };

  let caseDescription = narrative.is_threat
    ? getThreatDescription(narrative)
    : narrative.description;
  const newText = caseDescription.replace(/\n+(\s*\n+)?/g, '\n').split('\n');
  const sanitizedText = newText.map((item) =>
    DOMPurify.sanitize(item, {
      ALLOWED_TAGS: ['mark', 'span'],
      ALLOWED_ATTR: ['class'],
    }),
  );

  const clean = useMemo(() => {
    return sanitizedText;
  }, [sanitizedText]);

  if (!narrative) {
    return <Loader />;
  }

  return (
    <div>
      {caseDescription.length > 0 ? (
        <div className="report-section">
          <h3>{t('Description')}</h3>
          <div className={styles.descriptionCard}>
            {clean.map((item, i) => (
              <p
                key={`paragraph_${i}`}
                dangerouslySetInnerHTML={{ __html: item }}
              ></p>
            ))}
          </div>
        </div>
      ) : (
        <div className="report-section">
          <h3>{t('Description')}</h3>
          <div className={styles.noData}>
            {t('There is no data to display')}
          </div>
        </div>
      )}
    </div>
  );
};
