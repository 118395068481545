import { useEffect, useState, useContext } from "react";
import * as d3 from 'd3';
import { API } from "../../../API";
import { ShareAPI } from "../../../API";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BreakdownsContext } from "./contexts/BreakdownsContext";
import {platformIconsMapping, platformNamesMapping} from '../../../utils/platforms';
import { BreakdownsContainer } from "../../../components/BreakdownsContainer/BreakdownsContainer";
import { LoaderSmall } from "../../../components/LoaderSmall/LoaderSmall";
import { decodeSourceName } from '../../../utils/decodeURI';
import { ReactComponent as VerifiedTwitter } from '../../SourcePage/assets/verifiedTwitter.svg';
import Tooltip from "../../../components/Tooltip/Tooltip";
import { cutString } from "../../../utils/cutString";
import styles from '../NarrativePage.module.scss';

const formatNumber = d3.format(",d");
const formatNumberSignificant = d3.format('.3~s',);
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
}

export const Bots = ({isShare, narrative, details, isClickable=true}) => {
  const { t } = useTranslation();
  const [bots, setBots] = useState(null);
  const [topBots, setTopBots] = useState(null);
  const {openModal, setTypesFilter, setSubtypesFilter, sourcesSubtypesFilter, sourcesTypesFilter} = useContext(BreakdownsContext);

  const api = isShare ? ShareAPI : API;

  const mapBotEntityTypes = {
    'suspended_by_platform': 'Suspended by platform',
    'inauthentic_behavior': 'Inauthentic behavior',
    'immature_account':'Immature accounts',
  }

  const getNarrativeBots = () => {
    api.fetch('GET', `/API/v1/narratives/${narrative.id}/bots`).then(
      data => setBots(data)
    )
  }

  const getBotsTop = () => {
    const urlParams = new URLSearchParams();
      urlParams.set('size', '5');
      urlParams.set('page', 1);
      urlParams.set('narrative_id', narrative.id);

      urlParams.set('sorting', '-audience');
      
      urlParams.set('is_bot', true);

      api.fetch('GET', `/API/v1/sources?${urlParams.toString()}`).then(
        (data) => {
          setTopBots(data);
        },
      );
  }

  useEffect(() => {
    getNarrativeBots()
    getBotsTop()
  }, [narrative])

  const totalSources = bots?.immature_account + bots?.suspended_by_platform + bots?.inauthentic_behavior;

  return(
    <BreakdownsContainer
      title='Inauthentic accounts'
      details={details}
      onClick={() => {
        setTypesFilter({value: 'is_bot', label: 'Bots', bool: true})
        openModal()
      }}
    >
     {bots 
      ? (bots?.messages !== 0
      ? <div className={styles.widgetRow}>
        <div className={styles.widgetSummary}>
          <h5>{t('Total actors')}</h5>
          <div> 
            <h2>{formatNumber(totalSources)}</h2>
            <p>{t('Total audience')}: </p>
            <p>{formatWithCustomGigaBillion(bots.audience)}</p>
          </div>
        </div>
        <div className={styles.widgetStats}>
          <h5>{t('Split by type')}</h5>
          <div className={styles.breakdownsListMain}>
            
           {bots.suspended_by_platform > 0 ? <div key={`botType_1`} className={styles.listItem}>
              <div className={styles.info}>
                <span 
                  className={styles.title}
                  onClick={() => {
                    if(isClickable) {
                      setTypesFilter({value: 'is_bot', label: 'Bots', bool: false})
                      setSubtypesFilter('suspended_by_platform')
                      openModal()
                    }                    
                  }}
                  >
                    <Tooltip content={t('Account has been suspended by a platform')} position='bottom'>
                      {t('Suspended by platform')}
                    </Tooltip>
                  </span>
              </div>
              <div className={styles.count}>{formatNumber(bots.suspended_by_platform)}</div>
            </div> : ''}
            {bots.immature_account > 0 ? <div key={`botType_2`} className={styles.listItem}>
              <div className={styles.info}>
                <span 
                  className={styles.title}
                  onClick={() => {
                    if(isClickable) {
                      setTypesFilter({value: 'is_bot', label: 'Bots', bool: false})
                      setSubtypesFilter('immature_account')
                      openModal()
                    }
                  }}
                  >
                    <Tooltip content={t('Recently created accounts or accounts with few followers')} position='bottom'>
                      {t('Immature accounts')}
                    </Tooltip>
                  </span>
              </div>
              <div className={styles.count}>{formatNumber(bots.immature_account)}</div>
            </div> : ''}
            {bots.inauthentic_behavior > 0 ? <div key={`botType_3`} className={styles.listItem}>
              <div className={styles.info}>
                <span 
                  className={styles.title}
                  onClick={() => {
                    if(isClickable) {
                      setTypesFilter({value: 'is_bot', label: 'Bots', bool: false})
                      setSubtypesFilter('inauthentic_behavior')
                      openModal()
                    }
                  }}
                  >
                    <Tooltip content={t('Account was engaged in an inauthentic behavior')} position='bottom'>
                      {t('Inauthentic behavior')}
                    </Tooltip>
                  </span>
              </div>
              <div className={styles.count}>{formatNumber(bots.inauthentic_behavior)}</div>
            </div> : ''}
          </div>
        </div>
        <div className={styles.widgetTop}>
        <h5>{t('Top inauthentic accounts')}</h5>
        {topBots ? <div className={styles.breakdownsListMain}>
          {topBots?.objects?.map((source, index) => {
            let entityType = t('N/A');
            if(source?.suspended_by_platform === true) {
              entityType = mapBotEntityTypes['suspended_by_platform'];
            } else if(source?.inauthentic_behavior === true || source?.is_bot === true) {
              entityType = mapBotEntityTypes['inauthentic_behavior'];
            }else if(source?.immature_account === true) {
              entityType = mapBotEntityTypes['immature_account'];
            }

            return(
              <div key={`bot_${index}`} className={styles.listItem}>
                <div className={styles.info}>
                  <span>{platformIconsMapping[source.source_type]}</span>
                  <span className={styles.title}>
                  {isShare || !isClickable ?  <Tooltip content={source.name} position='bottom'>{cutString(decodeSourceName(source.name), 30)}</Tooltip> 
                            : <Link to={`/sources/${source.id}`}>
                                
                                <Tooltip content={source.name} position='bottom'>{cutString(decodeSourceName(source.name), 30)}</Tooltip>
                                {source?.is_verified ? (
                                  <span className={styles.verified}>
                                      <VerifiedTwitter/>
                                  </span>
                                      ) : (
                                        ''
                                  )}
                              </Link>}
                  </span>
                </div>
                <div className={styles.details}>{t(entityType)}</div>
              </div>
            )
          })}
        </div> : <div className={styles.widdgetLoader}><LoaderSmall /></div>}
      </div>
        
      </div> 
      : <div className={styles.noData}>{t('No inauthentic accounts identified')}</div>
      ) :(<div className={styles.widdgetLoader}><LoaderSmall /></div>)}

    </BreakdownsContainer>
  )
}

