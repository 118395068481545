import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback, useRef, useReducer } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { API } from '../../API';
import { format, parseISO, subDays, addDays } from 'date-fns';
import { decodeSourceName } from '../../utils/decodeURI';

import { useCurrentUser } from '../../contexts/CurrentUser';
import Modal from '../../components/Modal';
import { Multichoice } from '../../components/DropdownMenu/Multichoice';
import { Search } from '../../components/Search/Search';
import { QueryInput } from '../NarrativeAddPage/QueryInput/QueryInput';
import { Calendar } from './Calendar/Calendar';
import { Filter } from './Filter/Filter';
import { ExpandableText } from '../../components/CollapsibleText/ExpandableText';
import { ReactComponent as SaveIcon } from './assets/save.svg';
import { ReactComponent as ArrowIcon } from '../NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../NarrativePage/assets/arrowDown.svg';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import { ReactComponent as LinkIcon } from '../../assets/link.svg';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { ReactComponent as ViewsIcon } from '../../assets/views.svg';
import { ReactComponent as EngagementIcon } from '../../assets/engagement.svg';
import { ReactComponent as ManipulationIcon } from '../../assets/manipulation.svg';
import { ReactComponent as FollowersIcon } from '../../assets/followers.svg';
import { ReactComponent as TranslationIcon } from '../../assets/translation.svg';
import { ReactComponent as OriginalTextIcon } from '../../assets/originalText.svg';
import { ReactComponent as ExpandIcon } from '../../assets/expand.svg';
import { ReactComponent as ChevronIcon } from './assets/chevron-down.svg';
import { ReactComponent as AddToIcon } from '../../assets/addTo.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import { ReactComponent as SearchIcon } from './assets/search.svg';
import { ReactComponent as AnchorIcon } from '../../assets/anchor.svg';
import { ReactComponent as ActorIcon } from '../../pages/SearchPage/assets/link.svg';
import { ReactComponent as SourceIcon } from '../../pages/SearchPage/assets/person.svg';
import { ReactComponent as SimilarityIcon } from '../../assets/similarity.svg';
import { LoaderSmall } from '../../components/LoaderSmall/LoaderSmall';
import { Loader } from "../../components/Loader/Loader";
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import { Radiobutton } from '../../components/DropdownMenu/Radiobutton';
import { SortingOrder } from '../../components/DropdownMenu/SortingOrder';
import { ampli } from "../../ampli";
import { MessageModal } from '../../components/MessagesTable/MessageModal';
import {contentTypesIconsMapping, contentTypesMapping} from '../../utils/contentTypes';
import {platformIconsMapping, platformNamesMapping} from '../../utils/platforms';
import styles from './SearchPage.module.scss';
import messageStyles from '../../components/MessagesTable/ExtendedMessageView.module.scss';
import Tooltip from '../../components/Tooltip/Tooltip';
import { getSentimentIcon, getSentimentName } from "../../utils/sentiment";
import {ReactComponent as AIIcon} from '../../assets/ai.svg';

import { AddToNarrativeModal } from '../../components/AddToNarrativeModal/AddToNarrativeModal';
import { AddToSourceGroupModal } from '../../components/AddToSourceGroupModal/AddToSourceGroupModal';
import { CheckboxField } from '../../components/Checkbox/CheckboxField';
import { areAllIdsSelected } from '../NarrativePage/contexts/MessagesContext';

import { languageNamesMapping, languageMapping, languageNumberMapping } from '../../utils/languages';
import {Feedback, feedbackContexts} from "../../components/Feedback/Feedback";
import Switch from "../../components/Switch";
import Select from "react-select";

const formatNumber = d3.format(",d");
const formatNumberSignificant = d3.format('.3~s',);

const platformMapping = {
  1: 'TELEGRAM',
  2: 'FACEBOOK',
  4: 'VK',
  3: 'TWITTER',
  5: 'WEB',
  7: 'YOUTUBE',
  8: 'TIKTOK',
}

const sortingFieldNames = {
  DATE: 'published_at',
  SOURCE: 'source',
  MESSAGE: 'message',
  VIEWS: 'views',
  // REACTIONS: 'engagement',
  MANIPULATION: 'manipulation_index',
  ENGAGEMENT: 'engagement',
  RELEVANCE: 'relevance',
  SENTIMENT: 'sentiment',
};

const sortingFieldNamesBack = {
  'published_at': 'DATE',
  'source': 'SOURCE',
  'message': 'MESSAGE',
  'views': 'VIEWS',
  // 'engagement': 'REACTIONS',
  'manipulation_index': 'MANIPULATION',
  'engagement': 'ENGAGEMENT',
  'relevance': 'RELEVANCE',
  'sentiment': 'SENTIMENT'
}



const reducer = (state, action) => {
  switch (action.type) {
    case 'APPLY_FILTER': {
      return Object.assign({}, state, {
        platforms: action.payload.platforms, 
        countries: action.payload.countries, 
        manipulation_index_lte: action.payload.manipulation_index_lte,
        manipulation_index_gte: action.payload.manipulation_index_gte,
        sourceGroups: action.payload.sourceGroups,
        languages: action.payload.languages,
        sources: action.payload.sources,
        sentiment: action.payload.sentiment,
        contentTypes: action.payload.contentTypes,
        bots: action.payload.bots,
        discreditations: action.payload.discreditations,
        affiliations: action.payload.affiliations,
        cursor: action.payload.cursor,
      })
    }

    case 'UPDATE_LANGUAGES': {
      return Object.assign({}, state, {
        languages: action.payload,
      })
    }

    case 'SET_DATE': {
      return Object.assign({}, state, {
        cursor: null,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate
      })
    }

    case 'SET_SIMILAR_TO': {
      return Object.assign({}, state, {
        cursor: null,
        examples: action.payload.examples,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      })
    }

    case 'SET_EXAMPLES': {
      return Object.assign({}, state, {
        examples: action.payload.examples,
      })
    }

    case 'SORT_BY': {
      return Object.assign({}, state, {
        cursor: null,
        sorting: {...state.sorting, fieldName: action.fieldName}
      })
    }

    case 'IS_ASCENDING': {
      return Object.assign({}, state, {
        cursor: null,
        sorting: {...state.sorting, isAscending: action.isAscending}
      })
    }

    case 'SET_CURSOR': 
    return Object.assign({}, state, {
      cursor: action.cursor,
    })
    default: 
      return state;
  }
}

export function SearchPage() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [currentUser] = useCurrentUser();
  const workspaceId = parseInt(localStorage.getItem('workspace_id'));
  const nativeLang = window.clientInformation.language.split('-', 1);
  const [searchParams, setSearchParams] = useSearchParams();

  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const isAdvancedSyntax = workspaceConfig?.keywords_parser_class === 'Workspace16ESKeywordsQuery';
  const similarTo = searchParams.get('similar_to');
  const showNer = featureFlags?.includes('ner_widget');
  const showMessageManipulationIndex = featureFlags?.includes('show_manipulation_index');
  const addToActorGroup = featureFlags?.includes('add_to_actor_group');
  const aspectBasedSentimentAvailable = featureFlags?.includes('aspect_based_sentiment');

  const isAdminWorkspace = currentUser?.workspace_id === 1;

  const [dropdownMenu, setDropdownMenu] = useState(false);

  const sortingParam = searchParams.get('sorting') == null ? '-published_at' : searchParams.get('sorting');
  let urlSorting = sortingParam;

  let defineAscending = false; 
  if (urlSorting.startsWith('-')) {
    defineAscending = false;
    urlSorting = urlSorting.slice(1); 
  } else {
    defineAscending = true;
  }

  const [state, dispatch] = useReducer(reducer, {
    sorting: {isAscending: defineAscending, fieldName: sortingFieldNamesBack[urlSorting]},
    platforms: searchParams.getAll('platforms').map(i => parseInt(i)) || [],
    countries: searchParams.getAll('source_origin_country_ids') || [],
    manipulation_index_lte: searchParams.get('manipulation_index_lte') || 1,
    manipulation_index_gte: searchParams.get('manipulation_index_gte') || 0,
    languages: searchParams.getAll('languages').map(lang => parseInt(lang)) || [],
    sourceGroups: searchParams.getAll('source_group_ids').map(i => parseInt(i)) || [],
    sources: searchParams.getAll('source_ids').map(i => parseInt(i)) || [],
    sentiment: searchParams.getAll('sentiment') || [],
    contentTypes: searchParams.getAll('content_types') || [],
    bots: searchParams.get('show_bots') !== null ? true : false ,
    discreditations: searchParams.getAll('IoC') || [],
    affiliations: searchParams.getAll('affiliations') || [],
    startDate: searchParams.get('start_date') ? parseISO(searchParams.get('start_date')) : similarTo ? null : subDays(new Date(), 7),
    endDate: searchParams.get('end_date') ? parseISO(searchParams.get('end_date')) : similarTo ? null : new Date(),
    examples: null,
    cursor: null
  })

  const sentimentAnalysisTitle = {
    'SENTIMENT_STATEMENT': {title: t('Topic/Narrative'), hint: t('Give a short name of a topic, narrative or opinion to measure reaction to. E.g.: ceasefire in Gaza, Covid vaccines can cause death, sending Western troops to Ukraine.')},
    'SENTIMENT_PERSON': {title: t('Person'), hint: t('Write a name and minimum necessary content that helps identify a person in content. E.g.: Vladimir Putin, leader of PWC Wagner')},
    'SENTIMENT_ORGANIZATION': {title: t('Organization'), hint: t('Write a name of an organization. E.g.: UN, EU')},
    'SENTIMENT_COUNTRY': {title: t('Country'), hint: t('Write a name of a country. E.g.: Russia, USA')},
    'SENTIMENT_CUSTOM': {title: t('Custom sentiment option'), hint: t('Write a custom keyword or phrase to measure sentiment towards. E.g.: "I love my country"')},
  }

  const aspectBasedSentimentOptions = [
    {label: t('Sentiment towards a topic or narrative'), value: 'SENTIMENT_STATEMENT'},
    {label: t('Sentiment towards person'), value: 'SENTIMENT_PERSON'},
    {label: t('Sentiment towards organization'), value: 'SENTIMENT_ORGANIZATION'},
    {label: t('Sentiment towards country'), value: 'SENTIMENT_COUNTRY'},
    {label: t('Custom sentiment option'), value: 'SENTIMENT_CUSTOM'},
  ]

  const [saveModal, setSaveModal] = useState(false);
  const [addToModal, setAddToModal] = useState(false);
  const [addToSourceGroupModal, setAddToSourceGroupModal] = useState(false);
  const [messages, setMessages] = useState(null);
  const [messageModal, setMessageModal] = useState({message: null, isOpen: false, narrartiveMessage: null});
  const [activeLoadMoreButton, setActiveLoadMoreButton] = useState(false);
  const [useMultilingualSearch, setUseMultilingualSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAIPoweredSearch, setIsAIPoweredSearch] = useState(false);
  const [actorSearch, setActorSearch] = useState(searchParams.get('actor_search') ? searchParams.get('actor_search') : null);
  const [actor, setActor] = useState(null);
  const [caseSimilarityThreshold, setCaseSimilarityThreshold] = useState(null);
  const [storiesCandidatesMinThreshold, setStoriesCandidatesMinThreshold] = useState(0.8);
  const [storiesGroupingMaxDistance, setStoriesGroupingMaxDistance] = useState(0.64);
  const pageSize = 50;

  useEffect(() => {
    if (workspaceConfig?.narratives_similarity_threshold) {
      setCaseSimilarityThreshold(parseFloat(workspaceConfig?.narratives_similarity_threshold));
    }

    API.fetch('GET', '/API/v1/config/website_config').then((websiteConfig) => {
      if (!workspaceConfig?.narratives_similarity_threshold && websiteConfig?.narratives_similarity_threshold) {
        setCaseSimilarityThreshold(parseFloat(websiteConfig.narratives_similarity_threshold));
      }

      if (websiteConfig?.stories_candidates_min_threshold) {
        setStoriesCandidatesMinThreshold(parseFloat(websiteConfig.stories_candidates_min_threshold));
      }

      if (websiteConfig?.stories_grouping_max_distance) {
        setStoriesGroupingMaxDistance(parseFloat(websiteConfig.stories_grouping_max_distance));
      }
    });
  }, []);

  useEffect(() => {
    if(!actorSearch) {
      return
    }
    API.fetch('GET', `/API/v1/sources/${actorSearch}`).then(
      (data) => {
        setActor(data)
      }
    )

  }, [actorSearch])

  const sortingQuery = state.sorting.fieldName
    ? `${state.sorting.isAscending ? '' : '-'}${sortingFieldNames[state.sorting.fieldName]}`
    : null;


  const isAIPoweredSearchEnabled = !similarTo && featureFlags?.includes('ai_powered_search');
  const isMultilingualSearchEnabled = !similarTo;

  // const [startDate, setStartDate] = useState(initStartDate);
  // const [endDate, setEndDate] = useState(initEndDate);
  const decodedURLQuery = searchParams.get('q') == null ? null : decodeURIComponent(searchParams.get('q'));

  const queryInputRef = useRef(null);
  const keywordsRef = useRef(decodedURLQuery || null);
  const [isValidQuery, setIsValidQuery] = useState(true);
  const [narrativeName, setNarrativeName] = useState('');
  const [narrativeDescription, setNarrativeDescription] = useState('');
  const [narrativeCreateStories, setNarrativeCreateStories] = useState(false);
  const [narrativeIsAspectBasedSentimentEnabled, setNarrativeIsAspectBasedSentimentEnabled] = useState(false);
  const [narrativeAspectBasedSentimentObject, setNarrativeAspectBasedSentimentObject] = useState(null);
  const [narrativeAspectBasedSentimentSubject, setNarrativeAspectBasedSentimentSubject] = useState('');
  const [isCreateNarrativeDisabled, setIsCreateNarrativeDisabled] = useState(true);
  const [error, setError] = useState(null);

  const [addToNarrativeModal, setAddToNarrativeModal] = useState(false);

  useEffect(() => {
    if((similarTo || isAIPoweredSearch) && state.examples) {
      setIsCreateNarrativeDisabled(false)
    }
  }, [state.examples])

  useEffect(() => {
    if(keywordsRef?.current?.length > 0) {
      setIsCreateNarrativeDisabled(false)
    }
  }, [keywordsRef])

  useEffect(() => {
    if (isAIPoweredSearch || similarTo) {
      dispatch({type: 'SORT_BY', fieldName: 'RELEVANCE'});
    }
    
    if(useMultilingualSearch && !isAIPoweredSearch) {
      dispatch({type: 'SORT_BY', fieldName: 'DATE'});
    }
  }, [isAIPoweredSearch, useMultilingualSearch]);

  const isValueSet = (value) => {
    if (Array.isArray(value) && !value.some(isValueSet)) {
      return false;
    }

    return value !== "" && value !== undefined && value !== null;
  }
  const isAnyFilterApplied = (filters) => {
    let filters_copy = { ...filters }

    const is_examples_set = isValueSet(filters_copy.examples)

    if (isAIPoweredSearch && !is_examples_set) {
      return false;
    }

    if (isValueSet(filters_copy.keywords) || is_examples_set) {
      return true;
    }

    // Drop keys don't used in filters check
    delete filters_copy.manipulation_index_lte;
    delete filters_copy.manipulation_index_gte;
    delete filters_copy.keywords;
    delete filters_copy.start_date;
    delete filters_copy.end_date;
    delete filters_copy.use_multilingual_search;

    const values = Object.values(filters_copy);

    return  (isValueSet(filters.start_date) || isValueSet(filters.end_date)) && values.some(isValueSet);
  }

  const onKeywordsChange = useCallback((value, isValid) => {
    keywordsRef.current = value;
    setIsValidQuery(isValid);

    const k = keywordsRef.current.replace(/(\r\n|\n|\r)/gm, "")

    if (k.length > 0) {
      setIsCreateNarrativeDisabled(false)
    } else {
      setIsCreateNarrativeDisabled(true)
    }

  }, []);

  const abortControllerRef = useRef();

    useEffect(() => {
      return () => {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
      }
    }, [])
  
    const createURL = () => {
      const urlParams = new URLSearchParams();
  
      const keyword = keywordsRef.current ? keywordsRef.current.replace(/(\r\n|\n|\r)/gm, "") : "";
  
      if(keyword) {
        urlParams.set('q', encodeURIComponent(keyword))
      }
  
      if(state.startDate) {
        urlParams.set('start_date', format(state.startDate, 'yyyy-LL-dd'))
      }
  
      if (state.endDate) {
        urlParams.set('end_date', format(state.endDate, 'yyyy-LL-dd'))
      }
  
      if (state.platforms) {
        state.platforms.forEach((platform) => {
          urlParams.append('platforms', platform);
        });
      }
  
      if (state.sentiment) {
        state.sentiment.forEach((sentiment) => {
          urlParams.append('sentiment', sentiment);
        });
      }
  
      if (state.sourceGroups) {
        state.sourceGroups.forEach(sourceGroup => {
          urlParams.append('source_group_ids', sourceGroup)
        })
      }
  
      if(state.manipulation_index_gte > 0) {
        urlParams.set('manipulation_index_gte', state.manipulation_index_gte)
      }
  
      if(state.manipulation_index_lte < 1) {
        urlParams.set('manipulation_index_lte', state.manipulation_index_lte)
      }
  
      if(state.languages) {
        state.languages.forEach(language => {
          urlParams.append('languages', language)
        })
      }
  
      if (state.contentTypes) {
        state.contentTypes.forEach(contentType => {
          urlParams.append('content_types', contentType)
        })
      }
  
      if(state.sources) {
        state.sources.forEach(source => {
          urlParams.append('source_ids', source)
        })
      }

      if(state.bots === true) {
        urlParams.set('show_bots', true)
      }

      if(state.discreditations) {
        state.discreditations.forEach(discreditation => {
          urlParams.append('IoC', discreditation)
        })
      }
  
      if (state.countries) {
        state.countries.forEach(country => {
          urlParams.append('source_origin_country_ids', country)
        })
      }

      if(state.affiliations.length > 0 && state.affiliations[0] === 'all') {
        urlParams.set('affiliations', 'all')
      } else {
        state.affiliations.forEach(affiliation => {
          urlParams.append('affiliations', affiliation)
        })
      }

  
      if (state.sorting) {
        if (Array.isArray(state.sorting.fieldName)) {
          state.sorting.fieldName.forEach((element) => {
            urlParams.append(
              'sorting',
              `${state.sorting.isAscending ? '' : '-'}${element}`,
            );
          });
        } else {
          urlParams.set('sorting', sortingQuery);
        }
      }

      if (similarTo) {
        urlParams.set('similar_to', similarTo)
      }
  
      return urlParams.toString();
    }

  const fetchMessages = useCallback((
    keywords,
    start,
    end,
    platforms,
    sourceGroups,
    manipulation_index_gte,
    manipulation_index_lte,
    isValid,
    languages,
    sources,
    countries,
    sorting,
    sentiment,
    contentTypes,
    bots,
    discreditations,
    affiliations,
    useMultilingualSearch,
    isAIPoweredSearch,
    examples=null,
    cursor=null) => {

    const urlParams = new URLSearchParams();
    urlParams.set('size', pageSize);

    if (cursor) {
      urlParams.set('cursor', state.cursor);
    } else {
      setMessages(null);
    }
      
    

    if (!isValid) {
      return
    }

    const keyword = keywords ? keywords.replace(/(\r\n|\n|\r)/gm, "") : "";

    const bodyRequest = {
      start_date: start ? format(start, 'yyyy-LL-dd') : null,
      end_date: end ? format(end, 'yyyy-LL-dd') : null,
      use_multilingual_search: useMultilingualSearch,
    }

    if (isAIPoweredSearch) {
      bodyRequest.examples = [keyword];
    } else {
      bodyRequest.keywords = [keyword];
    }

    if (platforms) {
      bodyRequest.platforms = platforms;
    }

    if (sentiment) {
      bodyRequest.sentiment = sentiment;
    }

    if (examples) {
      bodyRequest.examples = examples;
    }

    if (sourceGroups) {
      bodyRequest.source_group_ids = sourceGroups;
    }

    bodyRequest.manipulation_index_gte = manipulation_index_gte;

    bodyRequest.manipulation_index_lte = manipulation_index_lte;
    

    if(languages) {
      let enumList = languages;
      bodyRequest.languages = enumList;
    }

    if (contentTypes) {
      bodyRequest.content_types = contentTypes;
    }

    if(sources) {
      bodyRequest.source_ids = sources;
    }

    if (countries) {
      bodyRequest.source_origin_country_ids = countries;
    }

    if (bots === true) {
      bodyRequest.source_bots = true;
    }

    if(discreditations) {
      bodyRequest.source_discreditated_entity_types = discreditations;
    }

    if(affiliations.length > 0 && affiliations[0] === 'all') {
      bodyRequest.source_state_affiliated_all_countries = true;
    } else {
      bodyRequest.source_state_affiliated_country_ids = affiliations;
    }

    if (! isAnyFilterApplied(bodyRequest)) {
      return;
    }

    if (sorting) {
      if (Array.isArray(sorting.fieldName)) {
        sorting.fieldName.forEach((element) => {
          urlParams.append(
            'sorting',
            `${sorting.isAscending ? '' : '-'}${element}`,
          );
        });
      } else {
        urlParams.set('sorting', sortingQuery);
      }
    }

    setIsLoading(true);

    // Check if there's an existing request and abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const signal = abortController.signal;

    API.fetch('POST', `/API/v1/messages/search?${urlParams.toString()}`, null, bodyRequest, signal).then(
      (data) => {
        setError(null);
        setIsLoading(false);

        // If no cursor - we haven't fetched messages before, so just set them
        // otherwise append new messages to the end
        if (!cursor) {
            setMessages(data);
        } else {
            setMessages(prevMessages => {
                return {...data, objects: [...prevMessages?.objects, ...data?.objects]}
            });
        }

        dispatch({type: 'SET_CURSOR', cursor: data.cursor});

        ampli.track({
          event_type: 'Search Perform',
          event_properties: {
            found_results: data.total,
            search_history_id: data.search_history_id,
          }
        });
      },
    )
    .catch(e => {
      if (e.name === 'AbortError') {
        return;
      }

      dispatch({type: 'SET_CURSOR', cursor: null});
      setActiveLoadMoreButton(false);
      let error_text = isAdminWorkspace && e.message ? e.message : "Something went wrong..."
      setError(error_text);
      setIsLoading(false);
    });
  }, [state.cursor, sortingQuery, state.sorting]); // TODO replace state.page with state.cursor

  useEffect(() => {
    if (isValidQuery) {
      navigate(`?${createURL()}`);
    }
    fetchMessages(keywordsRef.current, state.startDate, state.endDate, state.platforms, state.sourceGroups, state.manipulation_index_gte, state.manipulation_index_lte, isValidQuery, state.languages, state.sources, state.countries, state.sorting, state.sentiment, state.contentTypes, state.bots, state.discreditations, state.affiliations, useMultilingualSearch, isAIPoweredSearch, state.examples, state.cursor)
  }, [state.startDate, state.endDate, state.platforms, state.sourceGroups, state.manipulation_index_gte, state.manipulation_index_lte, state.sorting, state.languages, state.sources, state.countries, state.sentiment, state.contentTypes, state.bots, state.discreditation, state.affiliations, state.examples])

  useEffect(() => {
    ampli.track({event_type: 'Search Page Open'});
  }, [])

  useEffect(() => {
    setActiveLoadMoreButton(messages && messages.cursor !== null && ((messages.total !== null && messages.objects.length < messages.total) || messages.total === null));
  }, [messages]);
  
  const [searchQuery, setSearchQuery] = useState(null);
  const [narratives, setNarratives] = useState(null);
  const [selectedNarrative, setSelectedNarrative] = useState(null);
  const [isSaveSearchQuery, setIsSaveSearchQuery] = useState(true);

  const fetchNarratives = useCallback((searchQuery) => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', '10');
    // urlParams.set('page', state.page);

     if (searchQuery !== null && searchQuery.length > 0) {
       urlParams.set('q', searchQuery)
     }

     API.fetch('GET', `/API/v1/narratives?${urlParams.toString()}`).then(
       (data) => {
         setNarratives(data);
       },
     );
   }, [searchQuery]);

   useEffect(() => {
     fetchNarratives(searchQuery)
   }, [searchQuery])


  const findSimilarTo = useCallback((composite_id) => {
    if (!composite_id) {
      return;
    }

    setIsLoading(true);
    API.fetch('GET', `/API/v1/messages/composite_id/${composite_id}`).then((data) => {
      let date_publicated = new Date(data.date_publicated);
      let start_date = subDays(date_publicated, 7);
      let end_date = addDays(date_publicated, 7);
      // setExamples([data.text]);

      dispatch({
        type: 'SET_SIMILAR_TO',
        payload: {startDate: start_date, endDate: end_date, examples: [data.text]}
      });
      
    }).catch(e => {
      setError(e.message);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    findSimilarTo(similarTo)
  }, [findSimilarTo, similarTo])

  const handleCreateNarrativ = (name, description, createStories, isAspectBasedSentimentEnabled, aspectBasedSentimentObject, aspectBasedSentimentSubject, keywords, startDate, endDate, platforms, sourceGroups, languages, sources, countries, sentiment, contentTypes, bots, discreditations, affiliations, examples, useMultilingualSearch, isAIPoweredSearch, isSimilarTo) => {
    let keyword;
    if(keywords) {
      keyword = keywords.replace(/(\r\n|\n|\r)/gm, "")
    }

    let allAffiliationsCountries = false;

    if(affiliations.length > 0 && affiliations[0] === 'all') {
      allAffiliationsCountries = true;
    }

    API.fetch('POST', `/API/v1/narratives`, null, {
      name: name,
      description: description ? description : '',
      is_active: true,
      type: 'NARRATIVE',
      parameters: {
        keywords: (isAIPoweredSearch || isSimilarTo) ? [] : [keyword],
        examples: examples ? examples : isAIPoweredSearch ? [keyword] : [],
        start_date: startDate ? format(startDate, 'yyyy-LL-dd') : null,
        end_date: endDate ? format(endDate, 'yyyy-LL-dd') : null,
        source_types: platforms ? platforms.map(platform => platformMapping[platform]) : [],
        source_group_ids: sourceGroups ? sourceGroups : [],
        languages: languages ? languages.map(lang => languageNumberMapping[lang]) : [],
        source_ids: sources ? sources : [],
        source_origin_country_ids: countries ? countries : [],
        disable_filters: false,
        sentiment: sentiment ? sentiment : [],
        content_types: contentTypes ? contentTypes : [],
        source_bots: bots,
        source_discreditated_entity_types: discreditations ? discreditations : [],
        source_state_affiliated_all_countries: allAffiliationsCountries,
        source_state_affiliated_country_ids: allAffiliationsCountries ? [] : affiliations,
        translate_keywords_query: useMultilingualSearch,
        similarity_threshold: caseSimilarityThreshold,
        create_stories: createStories,
        stories_candidates_min_threshold: createStories ? storiesCandidatesMinThreshold : null,
        stories_grouping_max_distance: createStories ? storiesGroupingMaxDistance: null,
        aspect_based_sentiment: isAspectBasedSentimentEnabled,
        aspect_based_sentiment_object: isAspectBasedSentimentEnabled ? aspectBasedSentimentObject.value : null,
        aspect_based_sentiment_subject: isAspectBasedSentimentEnabled ? aspectBasedSentimentSubject: null,
      },
      adjustment_suggestions: null,
    }).then((data) => {
      ampli.track({event_type: 'Search Saved As Narrative'});
      navigate('/narratives');
    });
  }

  if (currentUser?.workspace?.config?.is_reporter_only_user) {
    // redirect UCBI reporters to a Report an instance
    navigate('/threats/new');
  }

  const handleAddToNarrativ = () => {

  }

  const translateMessage = (messageURL, text, nativeLang, sourceLang='') => {
    let body;
    if(sourceLang) {
      body = {
        text: text,
        destination_language: nativeLang,
        source_language: sourceLang
      }
    } else {
      body = {
        text: text,
        destination_language: nativeLang,
      }
    }
    API.fetch('POST', '/API/v1/translations/translate', null, body).then((data) => {
      setMessages({...messages, objects: messages.objects.map(message => {
      if(message.message_url === messageURL) {
        message.isTranslation = true;
        message.translated = data.destination_text;
        return message;
      } else {
        return message;
      }
      })})
    })
    .catch(e => {
      setMessages({...messages, objects: messages.objects.map(message => {
        if(message.message_url === messageURL) {
          message.isTranslation = true;
          message.error = true;
          message.translated = t('We were not able to translate this text.');
          return message;
        } else {
          return message;
        }
        })})
    });
  }

  const toggleTranslate = (messageURL, isTranslation) => {
    setMessages({...messages, objects: messages.objects.map(message => {
      if(message.message_url === messageURL) {
        message.isTranslation = isTranslation;
        return message;
      } else {
        return message;
      }
    })})
  }

  const handleTranslation = (messageURL, text, isTranslation) => {
    const target = messages.objects.find(message => message.message_url === messageURL);
    if (target.translated) {
      toggleTranslate(messageURL, isTranslation)
    } else {
      toggleTranslate(messageURL, true)
      translateMessage(messageURL, text, nativeLang[0].toUpperCase())
    }
  }

  const buttonsOnScroll = () => {
    if(Object.keys(selectedMessages).length > 0) {
      const buttons = document.getElementById('bulk');
      let scrolly = window.pageYOffset;
      if (!buttons) {
        return
      }

      if(scrolly > 150) {
        buttons.classList.add(styles.floating)
      } else {
        buttons.classList.remove(styles.floating)
      }
    } else {
      return
    }
  }

  useEffect(() => {
      window.addEventListener('scroll', buttonsOnScroll);
      window.addEventListener('mousemove', buttonsOnScroll);

    return() => {
      window.removeEventListener('scroll', buttonsOnScroll);
      window.removeEventListener('mousemove', buttonsOnScroll);
    }
  })

  let noResults;
  let guideLink;

  if(
    localStorage.getItem('i18nextLng') === 'ua-UK' ||
    localStorage.getItem('i18nextLng') === 'ru-RU' ||
    localStorage.getItem('i18nextLng') === 'ru' ||
    localStorage.getItem('i18nextLng') === 'uk'
  ) {
    guideLink = 'https://maple-fuel-09c.notion.site/C-796a2dfb19ca40a1a03551df662d0bac'
  } else {
    guideLink = 'https://maple-fuel-09c.notion.site/Osavul-Search-syntax-02ce73ac32964c22b840d6e0583e7bcd'
  }

  const noResultsText = (
    <>
        {t('Enter your query above. For help with search syntax, see our ')} <a href={guideLink} target="_blank" rel="noreferrer">{t('search guide')}</a>. {t('For advanced AI search')} <a href='/narratives/new'>{t('create a case')}</a>.
    </>
)

  if(messages === null) {
    // noResults = t('We are not able to show results, add your request');
    noResults = noResultsText;
    
  } else if (messages?.objects.length === 0) {
    noResults = t('No results were found for this query, refine your search')
  }

  const sortingMenu = [
    {
      name: t('Date'),
      value: 'DATE',
    },
    {
      name: t('Relevance'),
      value: 'RELEVANCE',
    },
    {
      name: t('Views'),
      value: 'VIEWS',
    },
    {
      name: t('Engagement'),
      value: 'ENGAGEMENT',
    },
    // {
    //   name: t('Manipulation'),
    //   value: 'MANIPULATION',
    // },
    {
      name: t('Sentiment'),
      value: 'SENTIMENT',
    },
  ]

  if (showMessageManipulationIndex) {
    sortingMenu.push({
      name: t('Manipulation'),
      value: 'MANIPULATION',
    },)
  }

  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon/>,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon/>,
    },
  ]

  const nerTypesList = [
    {value: 'KEYWORDS', label: t('Matched keywords')},
  ]

  if(showNer) {
    nerTypesList.push(
      {value: 'PERSON', label: t('Persons')},
      {value: 'ORGANIZATION', label: t('Organizations')},
      {value: 'LOCATION', label: t('Locations')}
    )
  }

  const [highlights, setHighlights] = useState(['KEYWORDS']);
  const [modalHighlights, setModalHighlights] = useState([]);
  const [highlightsMenu, setHighlightsMenu] = useState(false);

  const [selectedMessages, setSelectedMessages] = useState({});

  const toggleSelected = (messageId) => {
    if(messageId in selectedMessages) {
      let newMessagesList = Object.fromEntries(
        Object.entries(selectedMessages).filter(
          ([k, v]) => k !== messageId.toString(),
        )
      )
      setSelectedMessages(newMessagesList)
    } else {
      setSelectedMessages({...selectedMessages, [messageId]: true})
    }
  }

  const toggleAll = () => {
    if(areAllIdsSelected(messages.objects, selectedMessages)) {
      const messagesToRemove = {...messages};
      const updatedSelectedMessages = { ...selectedMessages};

      for (const message of messagesToRemove.objects) {
        const messageId = message.id;
        if (updatedSelectedMessages.hasOwnProperty(messageId)) {
          delete updatedSelectedMessages[messageId];
        }
      }
      setSelectedMessages(updatedSelectedMessages)
      
    } else {
      const newSelectedMessages = Object.fromEntries(messages.objects.map(message => [message.id, true] ))
      setSelectedMessages({...selectedMessages, ...newSelectedMessages})
    }
  }

  const clearAllSelectedMessages = () => {
    setSelectedMessages({})
  }

  return (
    <div className={styles.root}>
      <div className="page-header">
        <div className="breadcrumb"></div>
        <div className="controls">
          {/* <button
            className={`${styles.button} ${styles.save}`}
            onClick={() => {
              setAddToModal(true)
              fetchNarratives()
            }}
            disabled={isCreateNarrativeDisabled}
          >
            <PlusIcon />
            {t('Add to narrative')}
          </button> */}
          <button
            className='new-button'
            onClick={() => {
              setSaveModal(true);
              ampli.track({
                event_type: 'Search Save As Narrative Click',
              });
            }}
            disabled={isCreateNarrativeDisabled}
          >
            <SaveIcon />
            {t('Save as case')}
          </button>
        </div>
      </div>
      <div className="next-card-header">
        <div className="row">
          <h2>{''}</h2>
          {Object.keys(selectedMessages).length > 0 ? <div className={styles.bulkButtonsWrapper} id='bulk'>
            <button>{Object.keys(selectedMessages).length} {t('selected')} <span className={styles.clear} onClick={() => clearAllSelectedMessages()}><CloseIcon/></span></button>
            <button
              onClick={() => setAddToNarrativeModal(true)}
            >
              <AddToIcon/>
              {t('Add to case')}
            </button>
            {addToActorGroup ? <button
              onClick={() => setAddToSourceGroupModal(true)}
            >
              <AddToIcon/>
              {t('Add to actor group')}
            </button> : ''}
          </div> : ''}
        </div>
      </div>
      <div className={styles.searchCheckboxesWrapper}>
        { isMultilingualSearchEnabled ?
          <div className={styles.multilingualSearchCheckboxWrapper}>
            <CheckboxField
              checked={useMultilingualSearch}
              onChange={() => {
                setUseMultilingualSearch(!useMultilingualSearch)
                dispatch({type: 'SET_CURSOR', cursor: null})
              }}
            >
              {t('Multilingual search')}
              <Tooltip content={t('Multilingual search text')} position='top'>
                <AnchorIcon/>
              </Tooltip>
            </CheckboxField>
          </div> : '' }
        { isAIPoweredSearchEnabled ?
          <div className={styles.aipoweredSearchCheckboxWrapper}>
            <CheckboxField
              checked={isAIPoweredSearch}
              onChange={() => {
                setIsAIPoweredSearch(!isAIPoweredSearch)
                dispatch({type: 'SET_CURSOR', cursor: null})
              }}
            >
              {t('AI-powered search')}
              <Tooltip content={t('AI-powered search hint')} position='top'>
                <AnchorIcon/>
              </Tooltip>
            </CheckboxField>
        </div> : '' }
      </div>
      <div className={styles.searchWrapper}>
        <QueryInput
          keywords={keywordsRef.current}
          isAdvanced={isAdvancedSyntax}
          search={true}
          clear={() => setMessages(null)}
          ref={queryInputRef}
          className={styles.queryInput}
          onChange={onKeywordsChange}
          isInvalid={!isValidQuery}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && event.shiftKey === false) {
              event.preventDefault();
              if(isValidQuery) {
                navigate(`?${createURL()}`);
              } else if (!isValidQuery && isAIPoweredSearch) {
                setIsValidQuery(true)
                navigate(`?${createURL()}`);
              }
              dispatch({type: 'SET_CURSOR', cursor: null})
              fetchMessages(keywordsRef.current, state.startDate, state.endDate, state.platforms, state.sourceGroups, state.manipulation_index_gte, state.manipulation_index_lte, isValidQuery, state.languages, state.sources, state.countries, state.sorting, state.sentiment, state.contentTypes, state.bots, state.discreditations, state.affiliations, useMultilingualSearch, isAIPoweredSearch, state.examples, null)
            }
          }}
          onClick={(e) => {
            e.preventDefault();
            if(isValidQuery) {
              navigate(`?${createURL()}`);
            } else if (!isValidQuery && isAIPoweredSearch) {
              setIsValidQuery(true)
              navigate(`?${createURL()}`);
            }
            dispatch({type: 'SET_CURSOR', cursor: null})
            fetchMessages(keywordsRef.current, state.startDate, state.endDate, state.platforms, state.sourceGroups, state.manipulation_index_gte, state.manipulation_index_lte, isValidQuery, state.languages, state.sources, state.countries, state.sorting, state.sentiment, state.contentTypes, state.bots, state.discreditations, state.affiliations, useMultilingualSearch, isAIPoweredSearch, state.examples, null)
          }}
        />

        <div className={styles.calendarWrapper}>
          <Calendar
            start={state.startDate}
            end={state.endDate}
            onChange={(start, end) => {
              dispatch({type: 'SET_DATE', payload: {startDate: start, endDate: end}})
            }}

            initStartDate={state.startDate}
            initEndDate={state.endDate}
            overrideStartDate={state.startDate}
            overrideEndDate={state.endDate}
          />
        </div>

        <DropdownMenu  
          isOpen={dropdownMenu}
          header={t('Sort by')}
          onClick={() => setDropdownMenu(!dropdownMenu)}
          buttonName={t('Sort')}
          icon={<SortIcon/>}
        >
          <Radiobutton 
            itemsList={sortingMenu}
            current={state.sorting.fieldName}
            onChange={(value) => dispatch({type: 'SORT_BY', fieldName: value})}
          />

          <SortingOrder 
            itemsList={sortingOrder}
            onClick={value => dispatch({type: 'IS_ASCENDING', isAscending: value})}
            current={state.sorting.isAscending}
          />
        </DropdownMenu>

        <Filter
          state={state}
          onChange={(platformsList, sourceGroupsList, manipulationIndex, languages, sources, countries, sentiment, contentTypes, bots, discreditations, affiliations) => {
            dispatch({type: 'APPLY_FILTER', 
            payload: {
              platforms: platformsList, 
              countries: countries, 
              manipulation_index_lte: manipulationIndex[1],
              manipulation_index_gte: manipulationIndex[0],
              sourceGroups: sourceGroupsList,
              languages: languages,
              sources: sources,
              sentiment: sentiment,
              contentTypes: contentTypes,
              bots: bots,
              discreditations: discreditations,
              affiliations: affiliations,
              cursor: null,
            }})
          }}
          
        />
      </div>

      {similarTo && (state.examples && state.examples.length > 0) ?
      <div className={styles.similarMessagesSearchWrapper}>
        <div className={styles.infoIcon}>
          {' '}
        </div>
        <div className={styles.messageText}>
          <div className={styles.title}>{t(`Showing results for Similar message search`)}:</div>
          <ExpandableText length={100} text={state.examples[0]} textClassName={styles.text}/>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => {
              dispatch({type: 'SET_EXAMPLES', payload: {examples: null}});
              searchParams.delete('similar_to')
              setSearchParams(searchParams)
            }}
          />
        </div>
      </div> : ''}

      {actorSearch && actor ?
      <div className={styles.similarMessagesSearchWrapper}>
        <div className={styles.infoIcon}>
          {' '}
        </div>
        <div className={styles.messageText}>
          <div className={styles.title}>{t('Showing search results for the actor:')}<span className={styles.messageActorName}> "{actor.name}"</span></div>
          {/* <ExpandableText length={100} text={state.examples[0]} textClassName={styles.text}/> */}
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => {
              setActorSearch(null);
              setActor(null);
            }}
          />
        </div>
      </div> : ''}

      { isLoading && !activeLoadMoreButton ? <Loader/> : messages?.objects.length > 0 && !error ?  <table className={`${messageStyles.extendedMessages} ${styles.extendedMessages}`}>
      <thead>
        <tr>
          <td>
            <CheckboxField
              onChange={() => toggleAll(messages, selectedMessages)}
              checked={
                // Object.keys(selectedMessages).length === messages.objects.length
                areAllIdsSelected(messages.objects, selectedMessages)
              }
            /> 
          </td>
          <td className={messageStyles.headerInfo}>
            <span>{ t('Total messages') + ': ' + formatNumber(messages.total !== null ? messages.total : messages.objects.length) }{(similarTo || isAIPoweredSearch) && messages.total >= 1000 ?  '+' : ''}</span>
          </td>
          <td>
            <DropdownMenu 
              buttonName={t('Highlights')}
              isOpen={highlightsMenu}
              onClick={() => setHighlightsMenu(!highlightsMenu)}
              menuClassName='highlights'
              icon={<ChevronIcon/>}
            >
              <Multichoice
                itemsList={nerTypesList}
                selected={highlights}
                onChange={selectedList => {
                  setHighlights(selectedList)
                }}
              />
            </DropdownMenu>
          </td>
        </tr>
      </thead>
      <tbody>
        {messages.objects.map((message, index) => {
          let followers_cnt = message?.source?.audience;

          let textClass = messageStyles.extendedMessage;

          if(message.error && message.isTranslation) {
            textClass = `${messageStyles.extendedMessage} ${messageStyles.error}`
          } else if (message.error && !message.isTranslation) {
            textClass = messageStyles.extendedMessage;
          }

          return(
            <tr key={`message.message_url_${index}`}>
              <td>
               <CheckboxField
                  checked={message.id in selectedMessages}
                  onChange={() => toggleSelected(message.id)}
                /> 
              </td>
              <td>
              <div className={messageStyles.messageInfoWrapper}> 
                <div className={messageStyles.messageInfo}>
                  {message.content_type ? <div className={messageStyles.contentType}>
                    <Tooltip content={t(contentTypesMapping[message.content_type])} position='bottom'>
                      {contentTypesIconsMapping[message.content_type]}
                    </Tooltip>
                  </div> : ''}
                  <div className={messageStyles.date}>
                    <span className={messageStyles.anchor}>
                      <Tooltip content={t('Publication date')} position='bottom'><CalendarIcon /></Tooltip>
                    </span>
                    {format(parseISO(message.published_at + 'Z'), 'dd LLL yyyy HH:mm',)}
                  </div>
                  <div className={messageStyles.platform}>
                    <span className={messageStyles.anchor}>
                      <Tooltip content={platformNamesMapping[message.source?.source_type]} position='bottom'>
                        {platformIconsMapping[message.source?.source_type]}
                      </Tooltip>
                    </span>
                    {followers_cnt ? <span className={messageStyles.anchor}>
                        <Tooltip content={t('Followers')} position='bottom'>
                          <FollowersIcon />
                        </Tooltip>
                        <span>{formatNumberSignificant(followers_cnt)}</span>
                      </span> : ''}
                  </div>
                  <div className={messageStyles.sourceActorWrapper}>
                    <span className={messageStyles.anchor}>
                      <Tooltip content={message.source === null ? t('N/A') : decodeSourceName(message.source?.name)} position='bottom'>
                        <ActorIcon />
                        <span className={messageStyles.cutText}>
                        {message.source === null ? t('N/A') : <Link to={`/sources/${message.source?.id}`}>{decodeSourceName(message.source?.name)}</Link>}
                        </span>
                      </Tooltip>
                      { message.actor && message.actor.id !== message.source?.id ?
                        <Tooltip content={decodeSourceName(message.actor?.name)} position='bottom'>
                          <SourceIcon />
                          <span className={messageStyles.cutText}>
                          {<Link to={`/sources/${message.actor?.id}`}>{decodeSourceName(message.actor?.name)}</Link>}
                          </span>
                        </Tooltip> : ''}
                      <a href={message.message_url} className={messageStyles.externalLink} target="_blank" rel="noreferrer">
                        <LinkIcon />
                      </a>
                    </span>
                  </div>
                </div>

                <div className={messageStyles.modalIcon}>
                  <ExpandIcon  onClick={() => setMessageModal({message: message, isOpen: true, narrartiveMessage: null})}/> 
                </div>
              </div>
                
                <div className={`${messageStyles.messageContainer} ${styles.messageContainer}`}>
                  <div className={messageStyles.messageMetrics}>
                    <div className={styles.metricsWrapper}>
                      {message.sentiment_score !== null ? <Tooltip content={t(getSentimentName(message.sentiment_score))} position='bottom'>{getSentimentIcon(message.sentiment_score)}</Tooltip> : ''}
                      <span className={messageStyles.anchor}>
                        <Tooltip content={t('Views')} position='bottom'>
                          <ViewsIcon/>
                        </Tooltip>
                        {formatNumberSignificant(message.views)}
                      </span>
                      <span className={messageStyles.anchor}>
                        <Tooltip content={t('Reactions')} position='bottom'>
                          <EngagementIcon />
                        </Tooltip>
                        {/* engagement */}
                        {formatNumberSignificant(parseInt(message.forwards ?? 0) + parseInt(message.reactions ?? 0) + parseInt(message.replies ?? 0))}
                      </span>
                      {showMessageManipulationIndex ? <span className={messageStyles.anchor}>
                        <Tooltip content={t('Manipulation')} position='bottom'>
                          <ManipulationIcon />
                        </Tooltip>
                        {message.manipulation_index ? message.manipulation_index.toFixed(2) : '0'}
                      </span> : ''}
                      {message.similarity ?
                      <span className={styles.anchor}>
                        <Tooltip content={t('Similarity1')} position='bottom'>
                          <SimilarityIcon />
                        </Tooltip>
                        {(message.similarity + "").slice(0, 4)}
                        </span>
                      : ''}
                    </div>
                    <div className={styles.searchIconWrapper}>
                    <Tooltip content={ t('Find similar messages') } position='bottom'>
                      <span className={messageStyles.cutText}>
                        <Link to={`/search?similar_to=${encodeURIComponent(message.id)}`} onClick={() => window.location.href = `/search?similar_to=${encodeURIComponent(message.id)}` }><SearchIcon /></Link>
                      </span>
                    </Tooltip>
                    </div>
                  </div>
                  <div>
                    {message.isTranslation === true && !message.translated ?  
                    <div><LoaderSmall /></div> : 
                    <ExpandableText length={100} highlights={highlights} text={message.isTranslation && message.translated ? message.translated : message.highlighted_text || message.message} textClassName={textClass}/>}
                  </div>
                </div>
              </td>
              <td>
                { isAIPoweredSearch || similarTo ?
                <div className={messageStyles.controls}>
                  <Feedback
                    context={ isAIPoweredSearch ? feedbackContexts.AI_POWERED_SEARCH_MESSAGE : feedbackContexts.SIMILARITY_MESSAGE }
                    feedbackParams={{
                      selected_message_composite_id: message.id,
                      selected_message_similarity: message.similarity,
                      search_id: messages?.search_history_id,
                      ...( isAIPoweredSearch ? {query: keywordsRef?.current} : {search_message_composite_id: similarTo}),
                    }}
                  />
                </div>
                  : ''}
                <div className={styles.translationWrapper}>
                    <span 
                      className={message?.isTranslation ? '' : styles.active}
                      onClick={() =>  handleTranslation(message.message_url, message.message, false)}
                    >
                      <OriginalTextIcon />
                    </span>
                    <span 
                      className={message?.isTranslation ? styles.active : '' }
                      onClick={() => handleTranslation(message.message_url, message.message, true)}
                    >
                      <TranslationIcon />
                    </span> 

                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table> : <div className={styles.noResults}>{error ? t(error) : noResults}</div>}

    { activeLoadMoreButton ?
        <button
          className={styles.loadMore}
          disabled={isLoading}
          onClick={(e) => {
            e.preventDefault();
          fetchMessages(
            keywordsRef.current,
            state.startDate,
            state.endDate,
            state.platforms,
            state.sourceGroups,
            state.manipulation_index_gte,
            state.manipulation_index_lte,
            isValidQuery,
            state.languages,
            state.sources,
            state.countries,
            state.sorting,
            state.sentiment,
            state.contentTypes,
            state.bots,
            state.discreditations,
            state.affiliations,
            useMultilingualSearch,
            isAIPoweredSearch,
            state.examples,
            state.cursor
          );
            ampli.track({
              event_type: 'Search Page clicked',
              event_properties: {load_more: true}
            });
          }}
        >{isLoading ? <LoaderSmall/> : t('Load More')}</button> : ''}

      <Modal
        isVisible={saveModal}
        title={t('Save as case')}
        content={
          <div className={styles.modalForm} style={{width: '500px'}}>
            <form method="post" onSubmit={(e) => {
              e.preventDefault()
              handleCreateNarrativ(
                narrativeName,
                narrativeDescription,
                narrativeCreateStories,
                narrativeIsAspectBasedSentimentEnabled,
                narrativeAspectBasedSentimentObject,
                narrativeAspectBasedSentimentSubject,
                keywordsRef.current,
                state.startDate,
                state.endDate,
                state.platforms,
                state.sourceGroups,
                state.languages,
                state.sources,
                state.countries,
                state.sentiment,
                state.contentTypes,
                state.bots,
                state.discreditations,
                state.affiliations,
                state.examples,
                useMultilingualSearch,
                isAIPoweredSearch,
                similarTo
              )
            }}>
              <div className='form-element'>
                <label htmlFor='modal-name'>{t('Name')}</label>
                <input
                  type='text'
                  id='modal-name'
                  onChange={(e) => setNarrativeName(e.target.value)}
                />
              </div>
              <div className='form-element'>
                <label htmlFor='modal-description'>{t('Description')}</label>
                <textarea
                  id='modal-description'
                  onChange={(e) => setNarrativeDescription(e.target.value)}
                ></textarea>
              </div>
              <div className="form-element">
                <label htmlFor='create-stories' className={styles.label}>
                  <span className={styles.labelIcon}><AIIcon/></span>
                  {t('Create stories')}
                  <div className={styles.subLabel}>{t('Group messages into key topics.')}</div>
                </label>
                <Switch
                  id='create-stories'
                  value={narrativeCreateStories}
                  onChange={setNarrativeCreateStories}
                />
              </div>
              {aspectBasedSentimentAvailable ? <>
              <div className="form-element">
                <label htmlFor='is-aspect-based-sentiment-enabled' className={styles.label}>
                  <span className={styles.labelIcon}><AIIcon/></span>
                  {t('Run Contextual Sentiment Analysis')}
        
                  <div className={styles.subLabel}>{t('Calculate sentiment towards an entity or event.')}</div>
                </label>
                <Switch
                  id='is-aspect-based-sentiment-enabled'
                  value={narrativeIsAspectBasedSentimentEnabled}
                  onChange={setNarrativeIsAspectBasedSentimentEnabled}
                />
              </div>

              {narrativeIsAspectBasedSentimentEnabled ? <>
                  <label className={styles.label} htmlFor='aspect-based-sentiment-object'>{t('Sentiment analysis')}</label>
                  <Select
                    className={styles.sentimentSelect}
                    classNamePrefix="select-filter"
                    placeholder={t('Choose context for sentiment analysis')}
                    options={aspectBasedSentimentOptions}
                    id="aspect-based-sentiment-object"
                    name="aspect-based-sentiment-object"
                    value={narrativeAspectBasedSentimentObject}
                    onChange={(value) => setNarrativeAspectBasedSentimentObject(value)}
                    menuPlacement='auto'
                  />

                  {Object.keys(sentimentAnalysisTitle).includes(narrativeAspectBasedSentimentObject?.value) ? <div className='form-element'>
                    <label htmlFor='aspect-based-sentiment-subject' className={styles.label}>
                      {sentimentAnalysisTitle[narrativeAspectBasedSentimentObject?.value]?.title}
                      <div className={styles.subLabel}> {sentimentAnalysisTitle[narrativeAspectBasedSentimentObject?.value].hint}</div>
                    </label>
                    
                    <input
                      className=""
                      id="aspect-based-sentiment-subject"
                      name="aspect-based-sentiment-subject"
                      type="text"
                      onChange={(e) => setNarrativeAspectBasedSentimentSubject(e.target.value)}
                      required={narrativeIsAspectBasedSentimentEnabled}
                    />
                  </div> : ''}
                </> : ''}
              </> : ''}
              {!state.startDate ? <div
                className={styles.modalError}>{t('You need to select a start date to be able to save the search results as a case')}</div> : ''}
            </form>
          </div>
        }
        footer={
          <>
            <button
              className='new-button'
              onClick={(e) => {
                e.preventDefault()
                handleCreateNarrativ(
                  narrativeName,
                  narrativeDescription,
                  narrativeCreateStories,
                  narrativeIsAspectBasedSentimentEnabled,
                  narrativeAspectBasedSentimentObject,
                  narrativeAspectBasedSentimentSubject,
                  keywordsRef.current,
                  state.startDate,
                  state.endDate,
                  state.platforms,
                  state.sourceGroups,
                  state.languages,
                  state.sources,
                  state.countries,
                  state.sentiment,
                  state.contentTypes,
                  state.bots,
                  state.discreditations,
                  state.affiliations,
                  state.examples,
                  useMultilingualSearch,
                  isAIPoweredSearch,
                  similarTo)
                setSaveModal(false)
              }}
              disabled={narrativeName.length > 0 && state.startDate ? false : true}
            >
              {t('Save')}
            </button>
            <button
              className='btn-reset'
              onClick={() => {
                setSaveModal(false)
                setNarrativeName('')
                setNarrativeDescription('')
              }}
            >
              {t('Cancel')}
            </button>
          </>
        }
        onClose={() => {
          setSaveModal(false)
          setNarrativeName('')
          setNarrativeDescription('')
        }}
      />

      <Modal
        isVisible={addToModal}
        title={t('Add to narrative')}
        content={
          <div>
            <div className='modal-search'>
              <Search className={styles.searchInput} onChange={(value) => setSearchQuery(value)}/>
            </div>
            <ul className={styles.modalList}>
              {narratives?.objects.map(narrative => {
                return (
                  <li 
                    className={narrative.id === selectedNarrative ? `${styles.modalListItem} ${styles.selected}` : styles.modalListItem}
                    key={narrative.id}
                    onClick={() => {
                      if(narrative.id === selectedNarrative) {
                        setSelectedNarrative(null)
                      } else {
                        setSelectedNarrative(narrative.id)
                      }
                    }}
                  >
                    <span>{narrative.id}</span>
                    <span>{narrative.name}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        }
        footer={
          <div className={styles.footerWrapper}>
            <div className={styles.modalCheckbox}>
              <input 
                id='save'
                type='checkbox'
                checked={isSaveSearchQuery}
                onChange={() => setIsSaveSearchQuery(!isSaveSearchQuery)}
              />
              <label htmlFor="save">Save search query in the narrative</label>
            </div>

            <div className={styles.ModalControls}>
              <button
                className='new-button'
                onClick={() => {
                  handleAddToNarrativ()
                  setAddToModal(false)
                }}
                disabled={selectedNarrative ? false : true}
              >
                {t('Save')}
              </button>
              <button
                className='btn-reset'
                onClick={() => {
                  setAddToModal(false)
                }}
              >
                {t('Cancel')}
              </button>
            </div>

            
          </div>
        }
        onClose={() => {
          setAddToModal(false);
        }}
      />

    {messageModal.isOpen ? <MessageModal 
      message={messageModal.message}
      isOpen={messageModal.isOpen}
      onChange={(bool, selected) => {
        setMessageModal({message: null, isOpen: bool, narrartiveMessage: null})
        setModalHighlights(selected)
    }}
      narrativeMessage={messageModal.narrartiveMessage}
      selected={modalHighlights.length === 0 ? highlights : modalHighlights}
    /> : ''}

{addToNarrativeModal ? <AddToNarrativeModal 
  isOpen={addToNarrativeModal} 
  messages={selectedMessages} 
  onChange={(bool) => {
    setAddToNarrativeModal(bool)
    setSelectedMessages({})
  }} 
  isSearch={true}/> : ''}

{addToSourceGroupModal ? <AddToSourceGroupModal
  isOpen={addToSourceGroupModal}
  messages={selectedMessages}
  allMessages={messages.objects}
  isSearch={true}
  onChange={(bool) => {
    setAddToSourceGroupModal(bool)
    setSelectedMessages({})
  }}
  /> : ''}

    </div>
  )
}
