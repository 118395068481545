import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useRef, useState} from 'react';
import { getSentimentIcon, getSentimentName, getSentiment, getSentimentIconBubbleChart } from '../../utils/sentiment';
import { useClickOutside } from '../../utils/useClickOutside';
import Tooltip from '../Tooltip/Tooltip';

import styles from './Sentiment.module.scss';
import { API } from '../../API';

export const Sentiment = ({sentimentScore, messageId, narrative, isShare, isStories=false, isChart=false, onChange}) => {
  const clickRef = useRef();
  const { t } = useTranslation();
  const [sentiment, setSentiment] = useState(null);
  const [isSentimentChooserOpen, setIsSentimentChooserOpen] = useState(false);

  useClickOutside(clickRef, () => {
    if(isSentimentChooserOpen) {
      setIsSentimentChooserOpen(false);
    }
  })

  useEffect(() => {
    setSentiment(sentimentScore)
  }, [sentimentScore])

  const handleChange = (sentiment) => {
    if(isStories === true) {
      if(isChart === true) {
        onChange(sentiment, messageId)
      } else {
        API.fetch('PATCH', `/API/v1/narrative_stories/${narrative.id}/story/${messageId}`, null, {sentiment: sentiment});
      }
      
    } else {
      API.fetch('PATCH', `/API/v1/narratives/${narrative.id}/messages/${messageId}/sentiment`, null, {sentiment: sentiment});
    }
  }

  return (
    
    <div ref={clickRef} className={styles.sentimentContainer} id='sentiment-container'>
      <Tooltip content={t(getSentimentName(sentiment))} position='bottom'>
        <span 
          onClick={() => setIsSentimentChooserOpen(!isSentimentChooserOpen)}
        >
          {isChart ? getSentimentIconBubbleChart(sentiment) : getSentimentIcon(sentiment)}
        </span>
      </Tooltip>
      
      {isSentimentChooserOpen && !isShare && (narrative && !narrative?.is_threat)
        ? <div className={styles.sentimentChooser}>
            <ul>
              <li>
                <input
                  id='positive'
                  name='positive'
                  type="radio"
                  checked={getSentiment(sentiment) === 'POSITIVE' ? true : false}
                  onChange={() => {
                    setSentiment(0.4);
                    handleChange(getSentiment(0.4));
                    setIsSentimentChooserOpen(false)
                  }}
                />
                <label htmlFor="positive">{isChart ? getSentimentIconBubbleChart(0.4) : getSentimentIcon(0.4)}</label>
              </li>
              <li>
                <input
                  id='neutral'
                  name='neutral'
                  type="radio"
                  checked={getSentiment(sentiment) === 'NEUTRAL' ? true : false}
                  onChange={() => {
                    setSentiment(0.2);
                    handleChange(getSentiment(0.2));
                    setIsSentimentChooserOpen(false)
                  }}
                />
                <label htmlFor="neutral">{isChart ? getSentimentIconBubbleChart(0.2) : getSentimentIcon(0.2)}</label>
              </li>
              <li>
                <input
                  id='negative'
                  name='negative'
                  type="radio"
                  checked={getSentiment(sentiment) === 'NEGATIVE' ? true : false}
                  onChange={() => {
                    setSentiment(-0.5);
                    handleChange(getSentiment(-0.5));
                    setIsSentimentChooserOpen(false)
                  }}
                />
                <label htmlFor="negative">{isChart ? getSentimentIconBubbleChart(-0.5) : getSentimentIcon(-0.5)}</label>
              </li>
            </ul>
          </div> 
        : ''}
    </div>
  )
}