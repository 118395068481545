import { useEffect, useState, useContext } from "react";
import { API } from "../../../API";
import { ShareAPI } from "../../../API";
import { BreakdownsContext } from "./contexts/BreakdownsContext";
import {platformIconsMapping, platformNamesMapping} from '../../../utils/platforms';
import { BreakdownsContainer } from "../../../components/BreakdownsContainer/BreakdownsContainer";
import { LoaderSmall } from "../../../components/LoaderSmall/LoaderSmall";
import { useTranslation } from 'react-i18next';
import styles from '../NarrativePage.module.scss';

export const Platforms = ({narrative, isShare, isClickable=true}) => {
  const { t } = useTranslation();
  const [platforms, setPlatforms] = useState(null);

  const {openModal, setPlatformsFilter} = useContext(BreakdownsContext);

  const api = isShare ? ShareAPI : API;

  const getNarrativePlatforms = () => {
    api.fetch('GET', `/API/v1/narratives/${narrative.id}/platforms`).then(
      data => setPlatforms(data)
    )
  }

  useEffect(() => {
    getNarrativePlatforms()
  }, [narrative])




  return(
    <BreakdownsContainer title='Platforms' onClick={() => openModal()} details={false}>
     {platforms 
      ? (platforms.objects?.length > 0
      ? <div className={styles.breakdownsListMain}>
        {platforms.objects.map((platform, index) => {
          return(
            <div key={`platforms_${index}`} className={styles.listItem}>
              <div className={styles.info}>
                <span>{platformIconsMapping[platform?.platform]}</span>
                <span 
                  className={styles.title}
                  onClick={() => {
                    if (isClickable) {  
                      setPlatformsFilter({value: platform?.platform, label: platformNamesMapping[platform?.platform]})
                      openModal()
                    }
                  }}
                  >
                      {platformNamesMapping[platform?.platform]}
                  </span>
              </div>
              <div className={styles.count}>{platform?.count}</div>
            </div>
          )
        })}
      </div> 
      : <div className={styles.noData}>{t('There is no data to display')}</div>
      ) : (<div className={styles.widdgetLoader}><LoaderSmall /></div>)}

    </BreakdownsContainer>
  )
}