import AsyncCreatableSelect from 'react-select/async-creatable';
import { useCallback, useEffect, useState,} from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../API';
import Modal from "../Modal";
import { LoaderSmall } from '../LoaderSmall/LoaderSmall';
import { ReactComponent as PlusIcon } from './assets/plus.svg';

import styles from './AddToNarrative.module.scss';


export const AddToNarrativeModal = ({isOpen, messages=null, onChange, isSearch=false, deduplication=false, caseId}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [narrative, setNarrative] = useState(null);
  const [options, setOptions] = useState(null);
  const [error, setError] = useState();
  const [narrativeSelect, setNarrativeSelect] = useState(false);
  
  const messagesList = Object.keys(messages);
  
  const userInstruction = <div className={styles.userNote}>
    {t(`Start typing a case name to add messages to an existing one or create a new case by typing its name and pressing the 'Create New Case' button.`)}
  </div>

  const getNarrativesOptions = useCallback(() => {
    const urlParams = new URLSearchParams();
    urlParams.set('size', '10');
    urlParams.set('page', 1);
    urlParams.set('is_threat', false);

    API.fetch('GET', `/API/v1/narratives?${urlParams.toString()}`).then(data => {
      let newOptions = data.objects.map(narrative => {return {label: narrative.name, value: narrative.id}});
      setOptions(newOptions)
    })
  }, [])

  useEffect(getNarrativesOptions, [getNarrativesOptions]);

  const getNarratives = (inputValue, callback) => {
    const urlParams = new URLSearchParams();
    urlParams.set('q', inputValue);
    urlParams.set('is_threat', false);

    return API.fetch('GET', `/API/v1/narratives?${urlParams.toString()}`).then((data) => {
      callback(
        data.objects
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((narrative) => {
            return {value: narrative.id, label: narrative.name};
          })
      );
    });
  };

  const handleCreate = (inputvalue) => {
    if(!deduplication) {
      setIsLoading(true);
      API.fetch('POST', `/API/v1/narratives`, null, {
        name: inputvalue,
        description: '',
        type: 'NARRATIVE',
        is_manual: true,
        parameters: null
      }).then(data => {
        let newOption = {value: data.id, label: data.name};
        setIsLoading(false);
        setOptions(prev => [...prev, newOption])
        setNarrative(newOption)
      })
      .catch(e => {
        // setError(e.message);
        setError(t('Something went wrong. Please, try again...'));
        setIsLoading(false);
      });
    } else {
      setIsLoading(true);
      let newOption = {value: 'new', label: inputvalue};
      setIsLoading(false);
      setOptions(prev => [...prev, newOption])
      setNarrative(newOption)
    } 
    
  }

  const handleAddToNarrative = () => {
    if(!messages) {
      setError('There are no selected messages')
      return
    }
    let link = `/API/v1/narratives/${narrative?.value}/messages`;

    if (deduplication) {
      link = `/API/v1/narratives/${caseId}/copy_from_stories`
    }

    let body;
    if(isSearch) {
      body = {
        composite_message_ids: messagesList,
        status: "READ"
      }
    } else if(deduplication) {
      let newNarrative = null;
      if(narrative?.value === 'new') {
        let newCaseBody = {
          name: narrative?.label,
          description: '',
        }
        newNarrative = newCaseBody;
      } 
      body = {
        new_narrative: newNarrative,
        existing_narrative_id: narrative?.value === 'new' ? null : narrative?.value,
        story_ids: messagesList,
      }
    } else {
      body = {
        message_ids: messagesList,
        status: "READ"
      }
    }

    API.fetch('POST', link, null, body).then().catch(e => {
      // setError(e.message);
      setError(t('Something went wrong. Please, try again...'));
    });
  }

  useEffect(() => {
    if(options) {
      const container = document.querySelector('#message-to-narrative-modal');
      if(narrativeSelect === true ) {
        let content = document.querySelector(".addToNarrativeModal__menu");
        if(container.offsetHeight < content.offsetHeight) {
          container.style.height=container.offsetHeight + content.offsetHeight + "px";
        }
      } else {
        container.style.height = ' ';
      }
    }
 }, [narrativeSelect])


   
  return (
      <Modal
        isVisible={isOpen}
        title={t('Save messages to a case')}
        className={styles.addToNarrativeModal}
        content={
          options ? <div id='message-to-narrative-modal'>
            {userInstruction}
            <label>{t('Case name')}</label>
            <AsyncCreatableSelect
              cacheOptions
              defaultOptions={options}
              value={narrative}
              loadOptions={getNarratives}
              isClearable
              isDisabled={isLoading}
              isLoading={isLoading}
              loadingMessage={() => t('Loading...')}
              placeholder={t('Start typing an existing case name or create a new one')}
              onChange={(newValue) => setNarrative(newValue)}
              onCreateOption={handleCreate}
              onMenuOpen={() => setNarrativeSelect(true)}
              onMenuClose={() => setNarrativeSelect(false)}
              menuIsOpen={narrativeSelect}
              className='addToNarrativeModal'
              classNamePrefix="addToNarrativeModal"
              formatCreateLabel={(inputValue) => <span className={styles.create}><PlusIcon/>{t('Create a new case')} "{inputValue}"</span>}
              createOptionPosition='first'
            />
            {error ? <div className={styles.error}>{error}</div> : ''}
          </div> : <LoaderSmall/>
        }
        footer={
          <>
            <button
              className="btn-primary"
              onClick={() => {
                handleAddToNarrative();
                setNarrative(null);
                setError(null);
                onChange(false);
              }}
              
            >
              {t('Save')}
            </button>
            <button
              className="btn-reset"
              onClick={() => {
                setNarrative(null);
                setError(null);
                onChange(false);
              }}
            >
              {t('Cancel')}
            </button>
          </>
        }
        onClose={() => {
          onChange(false)
          setNarrative(null);
          setError(null);
        }
        }
      />
  )
}


