import styles from '../NarrativePage.module.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Loader } from '../../../components/Loader/Loader';
import cn from 'classnames';
import * as d3 from 'd3';
import { format, parseISO } from 'date-fns';
import { ExpandableText } from '../../../components/CollapsibleText/ExpandableText';
import { ReactComponent as ArrowIcon } from '../assets/arrow.svg';
import { ReactComponent as ApproveIcon } from '../assets/approve.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete.svg';
import { ReactComponent as LinkIcon } from '../../../assets/link.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/calendar.svg';
import { ReactComponent as ViewsIcon } from '../../../assets/views.svg';
import { ReactComponent as EngagementIcon } from '../../../assets/engagement.svg';
import { ReactComponent as SimilarityIcon } from '../../../assets/similarity.svg';
import { ReactComponent as ManipulationIcon } from '../../../assets/manipulation.svg';
import { ReactComponent as FollowersIcon } from '../../../assets/followers.svg';
import { ReactComponent as RecoverIcon } from '../../../assets/recover.svg';
import { ReactComponent as EnvelopeIcon } from '../../../assets/envelope.svg';
import { ReactComponent as EnvelopeOpenIcon } from '../../../assets/envelopeOpen.svg';
import { ReactComponent as TranslationIcon } from '../../../assets/translation.svg';
import { ReactComponent as OriginalTextIcon } from '../../../assets/originalText.svg';
import { ReactComponent as ExpandIcon } from '../../../assets/expand.svg';
import { ReactComponent as ChevronIcon } from '../../../pages/SearchPage/assets/chevron-down.svg';
import { ReactComponent as ActorIcon } from '../../../pages/SearchPage/assets/link.svg';
import { ReactComponent as SourceIcon } from '../../../pages/SearchPage/assets/person.svg';
import { ReactComponent as HeartSolidIcon } from '../../../assets/heart-solid.svg';
import { ReactComponent as HeartRegularIcon } from '../../../assets/heart-regular.svg';
import { ReactComponent as SearchIcon } from '../../../pages/SearchPage/assets/search.svg';
import { decodeSourceName } from '../../../utils/decodeURI';
import {platformIconsMapping, platformNamesMapping} from '../../../utils/platforms';
import {contentTypesIconsMapping, contentTypesMapping} from '../../../utils/contentTypes';
import { DropdownMenu } from '../../../components/DropdownMenu/DropdownMenu';
import { Multichoice } from '../../../components/DropdownMenu/Multichoice';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { useCurrentUser } from '../../../contexts/CurrentUser';
import { areAllIdsSelected } from '../../../pages/NarrativePage/contexts/MessagesContext';
import { Sentiment } from '../../../components/Sentiment/Sentiment';

import { LoaderSmall } from '../../../components/LoaderSmall/LoaderSmall';

import messageStyles from "../../../components/MessagesTable/ExtendedMessageView.module.scss";


const sortingFieldNames = {
  DATE: 'date_publicated',
  SOURCE: 'source',
  MESSAGE: 'text',
  VIEWS: 'impressions',
  REACTIONS: 'engagement',
  MANIPULATION_INDEX: 'manipulation_index',

  AGE: 'user_age',
  LOCATION: 'user_location',
  GROUPS: 'user_groups',
};

const formatNumber = d3.format(",d");
const formatNumberSignificant = d3.format('.3~s',);

export const ModalMessagesTable = ({
  messages,
  sorting,
  handleMessagesSortingClick,
  isLoading,
  isShare = false,
  handleTranslation,
  narrative
}) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();

  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const showNer = featureFlags?.includes('ner_widget');
  const showMessageManipulationIndex = featureFlags?.includes('show_manipulation_index');

  const statusMapping = {
    NEW: {class: messageStyles.new, name: 'New'},
    READ: {class: messageStyles.approved, name: 'Approved'},
    DELETED: {class: messageStyles.deleted, name: 'Deleted'}
  }

  const nerTypesList = [
    {value: 'KEYWORDS', label: t('Matched keywords')},
  ]

  if(showNer) {
    nerTypesList.push(
      {value: 'PERSON', label: t('Persons')},
      {value: 'ORGANIZATION', label: t('Organizations')},
      {value: 'LOCATION', label: t('Locations')}
    )
  }
  
  const [highlights, setHighlights] = useState(['KEYWORDS']);
 
  return (
    <>
      {isLoading ? <Loader /> : messages?.objects.length > 0 ? (
        <table className={messageStyles.extendedMessages}>
        <thead>
          <tr>
            <td className={messageStyles.headerInfo}>
              <span>{t('Total messages')}: {formatNumber(messages.total)}</span>
            </td>
            <td className='message-controlls'>
            </td>
          </tr>
        </thead>
        <tbody>
          {messages.objects.map(message => {
            let followers_cnt = message?.source?.audience;
            let textClass = styles.extendedMessage;
  
            if(message.error && message.isTranslation) {
              textClass = `${styles.extendedMessage} ${styles.error}`
            } else if (message.error && !message.isTranslation) {
              textClass = styles.extendedMessage;
            }
  
            return(
              <tr key={message.id}>
                <td>
                  <div className={messageStyles.messageInfoWrapper}>
                    <div className={messageStyles.messageInfo}>
                      <div className={`${messageStyles.status} ${statusMapping[message.status].class}`}>
                        {t(statusMapping[message.status].name)}
                      </div>
                      {message.content_type ? <div className={messageStyles.contentType}>
                        <Tooltip content={t(contentTypesMapping[message.content_type])} position='bottom'>
                          {contentTypesIconsMapping[message.content_type]}
                        </Tooltip>
                      </div> : ''}
                      <div className={messageStyles.date}>
                        <span  className={messageStyles.anchor}>
                          <Tooltip content={t('Publication date')} position='bottom'><CalendarIcon /></Tooltip>
                        </span>
                        {format(parseISO(message.date_publicated + 'Z'), 'dd LLL yyyy HH:mm',)}
                      </div>
                      <div className={messageStyles.platform}>
                        <span className={messageStyles.anchor}>
                          <Tooltip content={platformNamesMapping[message.source.source_type]} position='bottom'>
                            {platformIconsMapping[message.source.source_type]}
                          </Tooltip>
                        </span>
                        {followers_cnt ? <span className={messageStyles.anchor}>
                            <Tooltip content={t('Followers')} position='bottom'>
                              <FollowersIcon />
                            </Tooltip>
                            <span>{formatNumberSignificant(followers_cnt)}</span>
                          </span> : ''}
                      </div>
                      <div className={messageStyles.sourceActorWrapper}>
                        <span className={messageStyles.anchor}>
                          <Tooltip content={decodeSourceName(message.source.name)} position='bottom'>
                            <ActorIcon />
                            <span className={messageStyles.cutText}>
                              {isShare ? decodeSourceName(message.source.name) : message.source ? (
                                <Link 
                                  to={`/sources/${message.source?.id}`}
                                >
                                  {decodeSourceName(message.source.name)}
                                </Link>
                              ) : t('N/A')}
                            </span>
                          </Tooltip>
                          { message.actor && message.actor.id !== message.source?.id ?
                            <Tooltip content={decodeSourceName(message.actor?.name)} position='bottom'>
                              <SourceIcon />
                              <span className={messageStyles.cutText}>
                              {<Link to={`/sources/${message.actor?.id}`}>{decodeSourceName(message.actor?.name)}</Link>}
                              </span>
                            </Tooltip> : ''}
                          <a 
                            href={message.url}
                            className={messageStyles.externalLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <LinkIcon/>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  
                  <div className={messageStyles.messageContainer}>
                    <div className={messageStyles.messageMetricsWrapper}>
                      <div className={messageStyles.messageMetrics}>
                      {message.sentiment_score !== null ? <Sentiment sentimentScore={message.sentiment_score} messageId={message.id} narrative={narrative} isShare={isShare}/> : ''}
                        <span className={messageStyles.anchor}>
                          <Tooltip content={t('Views')} position='bottom'>
                            <ViewsIcon/>
                          </Tooltip>
                          {formatNumberSignificant(message.impressions)}
                        </span>
                        <span className={messageStyles.anchor}>
                          <Tooltip content={t('Reactions')} position='bottom'>
                            <EngagementIcon />
                          </Tooltip>
                          {formatNumberSignificant(message.engagement)}
                        </span>
                        {showMessageManipulationIndex ? <span className={messageStyles.anchor}>
                          <Tooltip content={t('Manipulation')} position='bottom'>
                            <ManipulationIcon />
                          </Tooltip>
  
                          {message.manipulation_index ? message.manipulation_index.toFixed(2) : '0'}
                          </span> : ''}
                        {message.similarity_score ?
                        <span className={messageStyles.anchor}>
                          <Tooltip content={t('Similarity1')} position='bottom'>
                            <SimilarityIcon />
                          </Tooltip>
                          {(message.similarity_score + "").slice(0, 4)}
                          </span>
                        : ''}
                      </div>
                    </div>
                    
                    <div className={messageStyles.textBlock}>
                      {message.isTranslation === true && !message.translated ?  
                      <div><LoaderSmall /></div> : 
                      <ExpandableText
                        length={100}
                        highlights={highlights}
                        text={message.isTranslation ? message.translated : message.highlighted_text || message.text}
                        textClassName={textClass}
                      />}
                    </div>
                  </div>
                </td>
                <td className='message-controlls'>
                  <div className={messageStyles.translationWrapper}>
                    <span 
                      className={message?.isTranslation ? '' : messageStyles.active}
                      onClick={() =>  handleTranslation(message.id, message.text, false)}
                    >
                      <OriginalTextIcon />
                    </span>
                    <span 
                      className={message?.isTranslation ? messageStyles.active : '' }
                      onClick={() => handleTranslation(message.id, message.text, true)}
                    >
                      <TranslationIcon />
                    </span> 
  
                  </div>
                  
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      ) : (
        <div className={messageStyles.noData}>{t('There is no data to display')}</div>
      )}
    </>
  );
};
