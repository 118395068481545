export const convertSourcesDataToTree = (
  data,
  source_type = null,
  t,
) => {
  const discreditated_entity_types_map = {
    INVOLVED_IN_INFLUENCE_OPERATIONS: {
      title: t('Influence operations'),
      tooltip: t('Account has been involved in influence operations'),
    },
    HACKED_OR_STOLEN: {
      title: t('Hacked / Stolen'),
      tooltip: t('Account has been hacked or stolen'),
    },
  };

  const bot_proof_map = {
    suspended_by_platform: {
      title: t('Suspended by platform'),
      tooltip: t('Account has been suspended by a platform'),
    },
    inauthentic_behavior: {
      title: t('Inauthentic behavior'),
      tooltip: t('Account was engaged in an inauthentic behavior'),
    },
    immature_account: {
      title: t('Immature accounts'),
      tooltip: t('Recently created accounts or accounts with few followers'),
    },
  };

  if (!data) {
    return null;
  }

  const total = data?.objects?.reduce((acc, val) => {
    if (source_type !== null && val.stats.source_type !== source_type) {
      return acc;
    }
    return acc + (val.stats.total_count || 0);
  }, 0);
  const verified_count = data?.objects?.reduce((acc, val) => {
    if (source_type !== null && val.stats.source_type !== source_type) {
      return acc;
    }
    return acc + (val.stats.verified_count || 0);
  }, 0);
  const influential_count = data?.objects?.reduce((acc, val) => {
    if (source_type !== null && val.stats.source_type !== source_type) {
      return acc;
    }
    return acc + (val.stats.influential_count || 0);
  }, 0);
  const immature_count = data?.objects?.reduce((acc, val) => {
    if (source_type !== null && val.stats.source_type !== source_type) {
      return acc;
    }
    return acc + (val.stats.immature_count || 0);
  }, 0);
  const state_affiliated_count = data?.objects?.reduce((acc, val) => {
    if (source_type !== null && val.stats.source_type !== source_type) {
      return acc;
    }
    return acc + (val.stats.state_affiliated_count || 0);
  }, 0);
  const state_affiliation_distribution_map = {};
  const countries_map = {};

  const discreditated_count = data?.objects?.reduce((acc, val) => {
    if (source_type !== null && val.stats.source_type !== source_type) {
      return acc;
    }
    return acc + (val.stats.discreditated_count || 0);
  }, 0);
  const discreditated_distribution_map = {};

  const bot_count = data?.objects?.reduce((acc, val) => {
    if (source_type !== null && val.stats.source_type !== source_type) {
      return acc;
    }
    return acc + (val.stats.bot_count || 0);
  }, 0);
  const bot_distribution_map = {};

  data?.objects.forEach((sourcesStats) => {
    if (source_type && sourcesStats.stats.source_type !== source_type) {
      return;
    }

    Object.entries(
      sourcesStats.stats.state_affiliation_distribution || {},
    ).forEach((entry) => {
      const [key, value] = entry;
      countries_map[key] = value.id;

      if (state_affiliation_distribution_map[key]) {
        state_affiliation_distribution_map[key] += value.count;
      } else {
        state_affiliation_distribution_map[key] = value.count;
      }
    });
    Object.entries(sourcesStats.stats.discreditated_distribution || {}).forEach(
      (entry) => {
        const [key, value] = entry;

        if (discreditated_distribution_map[key]) {
          discreditated_distribution_map[key] += value.count;
        } else {
          discreditated_distribution_map[key] = value.count;
        }
      },
    );
    Object.entries(sourcesStats.stats.bot_distribution || {}).forEach(
      (entry) => {
        const [key, value] = entry;

        if (bot_distribution_map[key]) {
          bot_distribution_map[key] += value.count;
        } else {
          bot_distribution_map[key] = value.count;
        }
      },
    );
  });

  let state_affiliation_distribution = Object.entries(
    state_affiliation_distribution_map,
  )
    .map(([key, value]) => ({
      name: key,
      total_count: value,
      type: 'state_affiliated',
      id: countries_map[key],
    }))
    .sort((a, b) => b.total_count - a.total_count);

  const otherStateAffiliationDistribution = state_affiliation_distribution
    .slice(5, state_affiliation_distribution.length)
    .reduce((acc, val) => acc + val.total_count, 0);

  state_affiliation_distribution = state_affiliation_distribution.slice(0, 5);

  if (otherStateAffiliationDistribution > 0) {
    state_affiliation_distribution.push({
      name: 'Other',
      type: 'state_affiliated',
      total_count: otherStateAffiliationDistribution,
    });
  }

  let discreditated_distribution = Object.entries(
    discreditated_distribution_map,
  )
    .map(([key, value]) => ({
      name: discreditated_entity_types_map[key]?.title,
      tooltip: discreditated_entity_types_map[key]?.tooltip,
      total_count: value,
      type: 'discreditated',
      sub_type: key,
    }))
    .sort((a, b) => b.total_count - a.total_count);

  let bot_distribution = Object.entries(bot_distribution_map)
    .map(([key, value]) => ({
      name: bot_proof_map[key]?.title,
      tooltip: bot_proof_map[key]?.tooltip,
      total_count: value,
      type: 'is_bot',
      sub_type: key,
    }))
    .sort((a, b) => b.total_count - a.total_count);

  const tree = {
    name: t('Total'),
    total_count: total,
    type: null,
    children: [
      {
        name: t('Verified'),
        tooltip: t('Verified by platform (Twitter blue, Telegram verified)'),
        total_count: verified_count,
        type: 'is_verified',
      },
      {
        name: t('Influential'),
        tooltip: t('100k+ followers'),
        total_count: influential_count,
        type: 'influential',
      },
      {
        name: t('State-affiliated'),
        tooltip: t(
          'Official gov accounts or accounts known to be linked to governments',
        ),
        total_count: state_affiliated_count,
        type: 'state_affiliated',
        children: state_affiliation_distribution,
      },
      {
        name: t('Compromised accounts'),
        tooltip: t('Compromised accounts'),
        total_count: discreditated_count,
        type: 'discreditated',
        children: discreditated_distribution,
      },
      {
        name: t('Bots'),
        tooltip: t('Accounts that was suspected to be bots'),
        total_count: bot_count,
        type: 'is_bot',
        children: bot_distribution,
      },
    ],
  };

  return tree;
};
