import * as d3 from 'd3';
import { useContext, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../components/Loader/Loader';
import { API, ShareAPI } from '../../../../API';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
};

export const SentimentBar = ({ narrative, mode, isShare }) => {
  const { t } = useTranslation();
  const api = isShare ? ShareAPI : API;
  const [sentimentData, setSentimentData] = useState(null);

  let chartTitle;
  if (mode === 'messages') {
    chartTitle = t('Sentiment: Messages');
  } else if (mode === 'views') {
    chartTitle = t('Sentiment: Views');
  } else if (mode === 'likes') {
    chartTitle = t('Sentiment: Reactions');
  }

  const getSentimentData = (narrative, statsAggregation, statsBreakdown) => {
    const urlParams = new URLSearchParams();
    urlParams.append('narrative_id', narrative.id);

    urlParams.set('aggregation', statsAggregation);

    let url = `/API/v1/stats/narratives/shares?${urlParams.toString()}`;

    if (statsBreakdown !== null) {
      urlParams.set('breakdown', statsBreakdown);
      url = `/API/v1/stats/narratives/${
        narrative.id
      }/shares?${urlParams.toString()}`;
    }

    api.fetch('GET', url).then((data) => {
      setSentimentData(data.sentiment);
    });
  };

  useEffect(() => {
    getSentimentData(narrative, 'DAY', null);
  }, []);

  if (!sentimentData) {
    return <Loader />;
  }

  return (
    <div className="list-content">
      <div className="report-section">
        <h3>{chartTitle}</h3>
        <div className="card card-bar">
          {/* <div className="card-header" /> */}
          <div className="card-body">
            <div className="sentiment-bar-container">
              {sentimentData?.positive[mode] === 0 ? (
                ''
              ) : (
                <span
                  className="sentiment-bar-positive"
                  style={{
                    width: `${sentimentData?.positive[mode + '_share']}%`,
                  }}
                >
                  {formatWithCustomGigaBillion(sentimentData?.positive[mode])}
                </span>
              )}
              {sentimentData?.neutral[mode] === 0 ? (
                ''
              ) : (
                <span
                  className="sentiment-bar-neutral"
                  style={{
                    width: `${sentimentData?.neutral[mode + '_share']}%`,
                  }}
                >
                  {formatWithCustomGigaBillion(sentimentData?.neutral[mode])}
                </span>
              )}
              {sentimentData?.negative[mode] === 0 ? (
                ''
              ) : (
                <span
                  className="sentiment-bar-negative"
                  style={{
                    width: `${sentimentData?.negative[mode + '_share']}%`,
                  }}
                >
                  {formatWithCustomGigaBillion(sentimentData?.negative[mode])}
                </span>
              )}
            </div>
            <div className="sentiment-bar-stats">
              <div className="sentiment-bar-stats-item">
                <span className="sentiment-bar-stats-color positive"></span>
                <span className="sentiment-bar-stats-data">{`${t(
                  'Positive',
                )}: ${
                  sentimentData?.positive[mode + '_share'] === 0
                    ? '0'
                    : sentimentData?.positive[mode + '_share'].toFixed(2)
                }%`}</span>
              </div>
              <div className="sentiment-bar-stats-item">
                <span className="sentiment-bar-stats-color neutral"></span>
                <span className="sentiment-bar-stats-data">{`${t('Neutral')}: ${
                  sentimentData?.neutral[mode + '_share'] === 0
                    ? '0'
                    : sentimentData?.neutral[mode + '_share'].toFixed(2)
                }%`}</span>
              </div>
              <div className="sentiment-bar-stats-item">
                <span className="sentiment-bar-stats-color negative"></span>
                <span className="sentiment-bar-stats-data">{`${t(
                  'Negative',
                )}: ${
                  sentimentData?.negative[mode + '_share'] === 0
                    ? '0'
                    : sentimentData?.negative[mode + '_share'].toFixed(2)
                }%`}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
