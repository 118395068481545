import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import '../../Filter/Filter.scss';


export const DistributionTabChooser = ({narrative, onChange, selected = []}) => {
  const { t } = useTranslation();

  const caseOptions = [
    {value: 'description', label: t('Description')},
    {value: 'summary', label: t('Summary')},
    {value: 'originalSource', label: t('Original source')},
    {value: 'bubbleChartCountries', label: t('Messages distribution: Countries')},
    {value: 'bubbleChartPlatforms', label: t('Messages distribution: Platforms')},
    {value: 'evolutionMessages', label: t('Evolution: Messages')},
    {value: 'evolutionViews', label: t('Evolution: Views')},
    {value: 'evolutionReactions', label: t('Evolution: Reactions')},
    {value: 'evolutionManipulations', label: t('Evolution: Manipulation index')},
    {value: 'sentimentMessages', label: t('Sentiment: Messages')},
    {value: 'sentimentViews', label: t('Sentiment: Views')},
    {value: 'sentimentReactions', label: t('Sentiment: Reactions')},
  ];

  if(narrative?.parameters?.create_stories === true) {
    caseOptions.splice(3, 0, {value: 'bubbleChartStories', label: t('Messages distribution: Stories')});
  }

  const impactAssessmentOptions = [
    {value: 'description', label: t('Description')},
    {value: 'summary', label: t('Summary')},
    {value: 'bubbleChartStories', label: t('Messages distribution: Stories')},
    {value: 'sentimentMessages', label: t('Sentiment: Messages')},
    {value: 'sentimentViews', label: t('Sentiment: Views')},
    {value: 'sentimentReactions', label: t('Sentiment: Reactions')},
  ]

  let distributionTabOptions = caseOptions;
 
  if(narrative?.type === 'IMPACT_ASSESSMENT') {
    distributionTabOptions = impactAssessmentOptions;
  }
  const handleSelect = () => {
    if (distributionTabOptions.length !== selected.length) {
      onChange(distributionTabOptions.map(item => item.value))
    } else {
      onChange([])
    }
  }

  return(
    <div className='chooser'>
      <div className='control' onClick={() => handleSelect()}>{distributionTabOptions?.length === selected.length ? t('Deselect all') : t('Select all')}</div>
      <ul>
        {distributionTabOptions?.map(item => {
          return(
            <li key={item.value}>
              <input 
                id={`distribTab_${item.value}`}
                type='checkbox'
                checked={selected.includes(item.value)}
                onChange={() => {
                  if (selected.includes(item.value)) {
                    onChange(selected.filter(v => v !== item.value)) 
                  } else {
                    onChange([...selected, item.value]) 
                  }
                }}
              />
              <label htmlFor={`distribTab_${item.value}`}>{item.label}</label>
            </li>
          )
        })}
        
      </ul>
    </div>
  )
}
