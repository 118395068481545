import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { platformNamesMapping } from '../../../utils/platforms';
import { ReactComponent as ArrowIcon } from '../../NarrativesPage/assets/arrow.svg';
import { Loader } from '../../../components/Loader/Loader';

import styles from './ThreatsModal.module.scss';

const entityTypes = {
  STATE_AGENCY: 'State agency',
  GOVERNMENT_OFFICIAL: 'Government official',
  STATE_OWNED_MEDIA: 'State owned media',
  REPORTEDLY_STATE_AFFILIATED: 'Reportedly state affiliated',
};

const sortingFieldNames = {
  FOLLOWERS: 'audience',
};

export const SourcesTable = ({
  sources,
  type,
  sorting,
  handleSourceSortingClick,
}) => {
  const { t } = useTranslation();

  if (!sources) {
    return <Loader />;
  }
  return (
    <>
      {sources?.objects.length > 0 ? (
        <div className="list-content">
          <div className="">
            <div className="card-body">
              <table className={styles.sources}>
                <thead>
                  <tr>
                    <td>
                      <div className={styles.wrapper}>
                        <span>{t('Platform')}</span>
                      </div>
                    </td>
                    <td>
                      <div className={styles.wrapper}>
                        <span>{t('Actor')}</span>
                      </div>
                    </td>
                    <td>
                      <div className={styles.wrapper}>
                        <span>{t('URL')}</span>
                      </div>
                    </td>
                    <td
                      className={styles.sortable}
                      onClick={() => {
                        handleSourceSortingClick(sortingFieldNames.FOLLOWERS);
                      }}
                    >
                      <div className={styles.wrapper}>
                        <span>{t('Followers count')}</span>
                        <ArrowIcon
                          className={cn(styles.icon, {
                            [styles.visible]:
                              sorting?.fieldName ===
                              sortingFieldNames.FOLLOWERS,
                            [styles.reversed]: !sorting?.isAscending,
                          })}
                        />
                      </div>
                    </td>
                    {type === 'state_affiliated' ? (
                      <>
                        <td>
                          <div className={styles.wrapper}>
                            <span>{t('Country')}</span>
                          </div>
                        </td>
                        <td>
                          <div className={styles.wrapper}>
                            <span>{t('Type of affiliation')}</span>
                          </div>
                        </td>
                        <td>
                          <div className={styles.wrapper}>
                            <span>{t('Proof of affiliation')}</span>
                          </div>
                        </td>
                      </>
                    ) : (
                      ''
                    )}
                  </tr>
                </thead>

                <tbody>
                  {sources?.objects?.map((source) => {
                    return (
                      <tr key={source.id}>
                        <td>{platformNamesMapping[source.source_type]}</td>
                        <td>
                          <Link to={`/sources/${source.id}`}>{source.name}</Link>
                        </td>
                        <td>
                          <a
                            href={source?.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {source?.url}
                          </a>
                        </td>
                        <td>{source?.audience}</td>
                        {type === 'state_affiliated' ? (
                          <>
                            <td>
                              {source.affiliations.length > 0
                                ? source?.affiliations[0].country.name
                                : ''}
                            </td>
                            <td>
                              {source.affiliations.length > 0
                                ? t(
                                    entityTypes[
                                      source?.affiliations[0].entity_type
                                    ],
                                  )
                                : ''}
                            </td>
                            <td>
                              {source?.affiliations[0]?.proof_link ===
                              'Twitter labeling' ? (
                                <a
                                  href="https://help.twitter.com/en/rules-and-policies/government-media-labels#:~:text=State%2Daffiliated%20media%20is%20defined,control%20over%20production%20and%20distribution"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {source.affiliations.length > 0
                                    ? source?.affiliations[0]?.proof_link
                                    : ''}
                                </a>
                              ) : (
                                <a href="#" target="_blank" rel="noreferrer">
                                  {source.affiliations.length > 0
                                    ? source?.affiliations[0]?.proof_link
                                    : ''}
                                </a>
                              )}
                            </td>
                          </>
                        ) : (
                          ''
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.noData}>{t('There is no data to display')}</div>
      )}
    </>
  );
};
