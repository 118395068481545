import * as d3 from 'd3';
import { useMemo, useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../components/Loader/Loader';
import { MessagesContext } from '../../contexts/MessagesContext';
import styles from '../../NarrativePage.module.scss';
import messageStyles from '../../../../components/MessagesTable/ExtendedMessageView.module.scss';
import {
  contentTypesMapping,
  contentTypesIconsMapping,
} from '../../../../utils/contentTypes';
import {
  platformNamesMapping,
  platformIconsMapping,
} from '../../../../utils/platforms';
import { decodeSourceName } from '../../../../utils/decodeURI';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { format, parseISO } from 'date-fns';
import { API, ShareAPI } from '../../../../API';
import { Link } from 'react-router-dom';
import { ExpandableText } from '../../../../components/CollapsibleText/ExpandableText';
import { ReactComponent as FollowersIcon } from '../../../../assets/followers.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/calendar.svg';
import { ReactComponent as ViewsIcon } from '../../../../assets/views.svg';
import { ReactComponent as EngagementIcon } from '../../../../assets/engagement.svg';
import { ReactComponent as ManipulationIcon } from '../../../../assets/manipulation.svg';
import { ReactComponent as SourceIcon } from '../../../SearchPage/assets/person.svg';
import { ReactComponent as ActorIcon } from '../../../SearchPage/assets/link.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/link.svg';
import { ReactComponent as ExpandIcon } from '../../../../assets/expand.svg';
import { ReactComponent as OriginalTextIcon } from '../../../../assets/originalText.svg';
import { ReactComponent as TranslationIcon } from '../../../../assets/translation.svg';
import { LoaderSmall } from '../../../../components/LoaderSmall/LoaderSmall';
import {
  getSentimentIcon,
  getSentimentName,
} from '../../../../utils/sentiment';
import { ampli } from '../../../../ampli';
import { useCurrentUser } from '../../../../contexts/CurrentUser';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
};

export const OriginalSource = ({ narrative, isShare }) => {
  const {
    messages,
    originalSources,
    deduplication,
    narrativeShares,
    narrativeStats,
    mostMentionedEntities,
    getMessages,
    fetchNarrativeStats,
    narrativeStatsStories,
    fetchNarrativeShares,
    fetchMostMentionedEntities,
    fetchOriginalSources,
    dispatch,
    translateMessage,
  } = useContext(MessagesContext);
  const { t } = useTranslation();
  const api = isShare ? ShareAPI : API;
  const [currentUser] = useCurrentUser();
  const [messagesList, setMessagesList] = useState(null);

  const featureFlags = currentUser?.workspace?.config.featureflags;
  const showMessageManipulationIndex = featureFlags?.includes(
    'show_manipulation_index',
  );

  useEffect(() => {
    if (!messages) {
      getMessages(narrative, null, 'date_publicated', null, deduplication, 1);
    }
    fetchOriginalSources(narrative, narrative.original_source_ids);
  }, []);

  useEffect(() => {
    if (originalSources) {
      setMessagesList(originalSources?.objects);
    } else if (messages?.objects?.length > 0) {
      setMessagesList(messages?.objects?.slice(0, 1));
    }
  }, [originalSources, messages]);

  if (narrative.type === 'IMPACT_ASSESSMENT') {
    return (
      <div className="report-section">
        <div className={styles.descriptionCard}>
          {t(
            'This type of case does not provide an original source information.',
          )}
        </div>
      </div>
    );
  }

  if (!messages && !originalSources) {
    return <Loader />;
  }

  const translateOriginalSource = (
    messageId,
    text,
    nativeLang,
    sourceLang = '',
  ) => {
    let body;
    if (sourceLang) {
      body = {
        text: text,
        destination_language: nativeLang,
        source_language: sourceLang,
      };
    } else {
      body = {
        text: text,
        destination_language: nativeLang,
      };
    }
    api
      .fetch('POST', '/API/v1/translations/translate', null, body)
      .then((data) => {
        setMessagesList(
          messagesList.map((message) => {
            if (message.id === messageId) {
              return {
                ...message,
                isTranslation: true,
                translated: data.destination_text,
              };
            } else {
              return message;
            }
          }),
        );
      })
      .catch((e) => {
        setMessagesList(
          messagesList.map((message) => {
            if (message.id === messageId) {
              return {
                ...message,
                isTranslation: true,
                translated: t('We were not able to translate this text.'),
                error: true,
              };
            } else {
              return message;
            }
          }),
        );
      });
  };

  const toggleTranslate = (messageId, isTranslation) => {
    setMessagesList(
      messagesList.map((message) => {
        if (message.id === messageId) {
          return {
            ...message,
            isTranslation: isTranslation,
          };
        } else {
          return message;
        }
      }),
    );
  };

  const handleTranslation = (messageId, text, isTranslation) => {
    const target = messagesList.find((message) => message.id === messageId);

    if (target.translated) {
      toggleTranslate(messageId, isTranslation);
    } else {
      const nativeLanguage = narrative.parameters?.translate_keywords_query
        ? narrative.parameters?.keywords_query_origin_language
        : window.clientInformation.language.split('-', 1)[0];
      toggleTranslate(messageId, true);
      translateOriginalSource(messageId, text, nativeLanguage);

      ampli.track({
        event_type: 'Translate original source',
        event_properties: {
          user_id: currentUser?.id,
          workspace_id: currentUser?.workspace_id,
          narrative_id: narrative.id,
        },
      });
    }
  };

  return (
    <>
      {narrative.type !== 'IMPACT_ASSESSMENT' && messagesList ? (
        <div className="report-section">
          <h3>{t('Original source')}</h3>
          <table
            className={`${messageStyles.extendedMessages} ${styles.extendedMessages} ${messageStyles.pdfMode}`}
          >
            <tbody>
              {messagesList.map((message, index) => {
                let followers_cnt = message?.source?.audience;
                let textClass = styles.extendedMessage;

                if (message.error && message.isTranslation) {
                  textClass = `${styles.extendedMessage} ${messageStyles.error}`;
                } else if (message.error && !message.isTranslation) {
                  textClass = styles.extendedMessage;
                }

                return (
                  <tr key={`message.message_url_${index}`}>
                    <td>
                      <div className={messageStyles.messageInfoWrapper}>
                        <div className={messageStyles.messageInfo}>
                          {message.content_type ? (
                            <div className={messageStyles.contentType}>
                              <Tooltip
                                content={t(
                                  contentTypesMapping[message.content_type],
                                )}
                                position="bottom"
                              >
                                {contentTypesIconsMapping[message.content_type]}
                              </Tooltip>
                            </div>
                          ) : (
                            ''
                          )}
                          <div className={messageStyles.date}>
                            <span className={messageStyles.anchor}>
                              <Tooltip
                                content={t('Publication date')}
                                position="bottom"
                              >
                                <CalendarIcon />
                              </Tooltip>
                            </span>
                            {format(
                              parseISO(message.date_publicated + 'Z'),
                              'dd LLL yyyy HH:mm',
                            )}
                          </div>
                          <div className={messageStyles.platform}>
                            <span className={messageStyles.anchor}>
                              <Tooltip
                                content={
                                  platformNamesMapping[
                                    message.source.source_type
                                  ]
                                }
                                position="bottom"
                              >
                                {
                                  platformIconsMapping[
                                    message.source.source_type
                                  ]
                                }
                              </Tooltip>
                            </span>
                            {followers_cnt ? (
                              <span className={messageStyles.anchor}>
                                <Tooltip
                                  content={t('Followers')}
                                  position="bottom"
                                >
                                  <FollowersIcon />
                                </Tooltip>
                                <span>
                                  {formatNumberSignificant(followers_cnt)}
                                </span>
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className={messageStyles.sourceActorWrapper}>
                            <span className={messageStyles.anchor}>
                              <Tooltip
                                content={
                                  message.source === null
                                    ? t('N/A')
                                    : decodeSourceName(message.source?.name)
                                }
                                position="bottom"
                              >
                                <SourceIcon />
                                <span className={messageStyles.cutText}>
                                  {isShare ? (
                                    decodeSourceName(message.source?.name)
                                  ) : message.source === null ? (
                                    t('N/A')
                                  ) : (
                                    <Link to={`/sources/${message.source?.id}`}>
                                      {decodeSourceName(message.source?.name)}
                                    </Link>
                                  )}
                                </span>
                              </Tooltip>
                              {message.actor &&
                              message.actor.id !== message.source?.id ? (
                                <Tooltip
                                  content={decodeSourceName(
                                    message.actor?.name,
                                  )}
                                  position="bottom"
                                >
                                  <ActorIcon />
                                  <span className={messageStyles.cutText}>
                                    {
                                      <Link
                                        to={`/sources/${message.actor?.id}`}
                                      >
                                        {decodeSourceName(message.actor?.name)}
                                      </Link>
                                    }
                                  </span>
                                </Tooltip>
                              ) : (
                                ''
                              )}
                              <a
                                href={message.url}
                                className={messageStyles.externalLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <LinkIcon />
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`${messageStyles.messageContainer} ${styles.messageContainer}`}
                      >
                        <div
                          className={messageStyles.messageMetrics}
                          style={{ justifyContent: 'left' }}
                        >
                          {message.sentiment_score !== null ? (
                            <Tooltip
                              content={t(
                                getSentimentName(message.sentiment_score),
                              )}
                              position="bottom"
                            >
                              {getSentimentIcon(message.sentiment_score)}
                            </Tooltip>
                          ) : (
                            ''
                          )}
                          <span className={messageStyles.anchor}>
                            <Tooltip content={t('Views')} position="bottom">
                              <ViewsIcon />
                            </Tooltip>
                            {formatNumberSignificant(message.impressions)}
                          </span>
                          <span className={messageStyles.anchor}>
                            <Tooltip content={t('Reactions')} position="bottom">
                              <EngagementIcon />
                            </Tooltip>
                            {/* engagement */}
                            {formatNumberSignificant(message.engagement)}
                          </span>
                          {showMessageManipulationIndex ? (
                            <span className={messageStyles.anchor}>
                              <Tooltip
                                content={t('Manipulation')}
                                position="bottom"
                              >
                                <ManipulationIcon />
                              </Tooltip>
                              {message.manipulation_index
                                ? message.manipulation_index.toFixed(2)
                                : '0'}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <div>
                          {message.isTranslation === true &&
                          !message.translated ? (
                            <div>
                              <LoaderSmall />
                            </div>
                          ) : (
                            <ExpandableText
                              length={100}
                              highlights={['KEYWORDS']}
                              text={
                                message.isTranslation
                                  ? message.translated
                                  : message.highlighted_text || message.text
                              }
                              textClassName={textClass}
                              showFullText={true}
                              onExpand={(expanded) => {
                                if (expanded) {
                                  ampli.track({
                                    event_type: 'Expand original source',
                                    event_properties: {
                                      user_id: currentUser?.id,
                                      workspace_id: currentUser?.workspace_id,
                                      narrative_id: narrative.id,
                                    },
                                  });
                                }
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="message-controlls">
                      <div className={messageStyles.translationWrapper}>
                        <span
                          className={
                            message?.isTranslation ? '' : messageStyles.active
                          }
                          onClick={() =>
                            handleTranslation(message.id, message.text, false)
                          }
                        >
                          <OriginalTextIcon />
                        </span>
                        <span
                          className={
                            message?.isTranslation ? messageStyles.active : ''
                          }
                          onClick={() =>
                            handleTranslation(message.id, message.text, true)
                          }
                        >
                          <TranslationIcon />
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        ''
      )}
    </>
  );
};
